// eslint-disable-next-line func-names
const EMPTY_FUNCTION = function () {
  // do nothing.
};

const llsRouter = {
  pathQuery(vm: any, path: string, query: object) {
    const tenantPageCfg = JSON.parse((sessionStorage.getItem('tenantPageCfg') || '{}') as string);
    vm.$router.push({
      path: tenantPageCfg[path] || path,
      query,
    });
  },
};

// eslint-disable-next-line func-names
const deepClone = function (obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item));
  }

  const clone: any = {};

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key]);
    }
  });

  return clone;
};

const checkRules = {
  money: {
    //  后端支持的金额是12位整数 1位小数点 2位小数
    regExp: /^[0-9]{1,12}(.[0-9]{1,2})?$/,
  },
  number: {
    regExp: /^\d{1,}$/, // 至少一位数字
  },
  zipcode: {
    regExp: /^\d{6}$/, // 只能是6位数字
  },
  invonum: {
    regExp: /^\d{8}$/, // 只能是8位数字
  },
  tenpayAcco: {
    regExp: /^([0-9]){10,10}$/, // 只能是10位数字
  },
  socialUnifiedCode: {
    regExp: /^[a-zA-Z\d]{18}$/, // 18位数字字母
  },
  telphone: {
    regExp:
      /^(((13[0-9])|(14[579])|(15([0-3]|[5-9]))|(16[6])|(17[0135678])|(18[0-9])|(19[89]))\d{8})$/, // 与后端相同的手机号码正则
  },
  idcode: {
    regExp:
      /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    // 15-18位身份证号码校验
  },
  passport: {
    regExp: /^.{1,30}$/, // 证件号码最多支持30位
  },
  gangaoCard: {
    regExp: /^.{1,30}$/,
  },
  taiwanCard: {
    regExp: /^.{1,30}$/,
  },
  acctcode: {
    regExp: /^([0-9]|-){1,30}$/, // 1位到30位银行账户校验，可以带-（日本银行。。。）
  },
  email: {
    regExp: /^([0-9A-Za-z\-_\\.]+)@([0-9A-Za-z\-_]+\.[a-z]{2,3}(\.[a-z]{2})?)$/,
    // 开始必须是一个或者多个单词字符或者是-，加上@，然后又是一个或者多个单词字符或者是-。然后是点“.”和单词字符和-的组合，可以有一个或者多个组合。
    JSRule() {
      if (String.length <= 60) {
        return true;
      }
      return false;
    },
  },
  rate: {
    regExp: /^[0-9]+(.[0-9]{1,3})?$/,
  }, // 有1~3位小数的正实数
  time: {
    regExp: /^(\d{4})\\-(\d{2})\\-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/, // yyyy-mm-dd hh:mm:ss格式的时间
  },
  date: {
    regExp: /^(\d{4})\\-(\d{2})\\-(\d{2})$/, // yyyy-mm-dd格式的日期
  },
  busiLicenseNo: {
    // 营业执照
    regExp: /^[0-9a-zA-Z]{15}$/,
  },
  orgCode: {
    // 组织机构代码
    regExp: /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]$/,
  },
  cashRate: {
    // 融资费率
    JSRule(checkVal: any) {
      if (Number(checkVal) > 0 && Number(checkVal) < 50) {
        return true;
      }
      return false;
    },
  },
  fileName: {
    // 后台最长支持200位,10位预留给后缀名
    regExp: /^[_a-z0-9\u4E00-\u9FFF]{1,190}$/i,
  },
};
const getLlsCookie = (key:any, prefix:any) => {
  const keyItem = prefix + key;
  return sessionStorage.getItem(keyItem) || '';
};
export {
  EMPTY_FUNCTION,
  deepClone,
  llsRouter,
  checkRules,
  getLlsCookie,
};
