import Vue from 'vue';
import llsTableHeaderDynamic from './index.vue';
/* eslint-disable */
let instance;

const LlsTableHeaderDynamic = Vue.extend(llsTableHeaderDynamic);

const initInstance = (options) => {
  // 单例模式
  if (!instance) {
    instance = new LlsTableHeaderDynamic({
      el: document.createElement('div'),
      propsData: {
        headerOptions: options.headerOptions,
        headerOriginOptions: options.headerOriginOptions,
        showOptions: options.showOptions,
        showOriginOptions: options.showOriginOptions,
      },
    });
    document.body.appendChild(instance.$el);
  } else {
    // 实例存在的情况下，更新propsData，手动调用init方法
    instance.$options.propsData = { 
      headerOptions: options.headerOptions,
      headerOriginOptions: options.headerOriginOptions,
      showOptions: options.showOptions,
      showOriginOptions: options.showOriginOptions,
    };
    instance.init();
  }
  instance.show = true;
};

const destroyed = () => {
  instance.show = false;
  setTimeout(() => {
    document.body.removeChild(instance.$el);
    instance = null;
  }, 500);
};

const $llsTableHeaderDynamic = (options = {}) => {
  // 初始化
  initInstance(options);
  return new Promise((resolve, reject) => {
    const success = instance.onSuccess;
    // 重写onSuccess和onCancel
    instance.onSuccess = () => {
      success();
      resolve({
        result: instance.result,
        headerOptions: instance.headerOptionsList,
        isRecover: instance.isRecover,
      });
      destroyed();
    };
    instance.onCancel = () => {
      reject();
      destroyed();
    };
  });
};

export default {
  install(Vue) {
    Vue.component('lls-table-header-dynamic', llsTableHeaderDynamic);
    Vue.prototype.$llsTableHeaderDynamic = $llsTableHeaderDynamic;
  },
};
