/* eslint-disable no-param-reassign */
import Vue from 'vue';

Vue.filter('filterDic', (JSONStr: string | null | undefined) => {
  if (JSONStr == null) {
    return JSONStr;
  }
  if (typeof JSONStr === 'string') {
    if (JSONStr.indexOf('{') > -1 && JSONStr.indexOf('dictParam') > -1) {
      return JSON.parse(JSONStr).dictParam;
    }
  }
  return JSONStr;
});

Vue.filter('filterDate', (value: string) => {
  value.substring(0, 10);
});

Vue.filter('money', (number: number | string, places: number, symbol: string | undefined, thousand: string, decimal: string) => {
  number = number || 0;
  // eslint-disable-next-line no-cond-assign, no-restricted-globals
  places = !isNaN((places = Math.abs(places))) ? places : 2;
  symbol = symbol !== undefined ? symbol : '';
  thousand = thousand || ',';
  decimal = decimal || '.';
  const negative = (number as number) < 0 ? '-' : '';
  const i = `${parseInt((number = Math.abs(+number || 0).toFixed(places)), 10)}`;
  // eslint-disable-next-line vars-on-top, no-var, no-use-before-define, no-cond-assign
  var j: number = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol
    + negative
    + (j ? i.substr(0, j) + thousand : '')
    + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousand}`)
    + (places
      ? decimal
        + Math.abs((number as unknown as number) - (i as unknown as number))
          .toFixed(places)
          .slice(2)
      : '')
  );
});
