<template>
  <!-- eslint-disable -->
  <div
    :class="[
      type === 'textarea' ? 'lls-textarea' : 'lls-input',
      inputSize ? 'lls-input--' + inputSize : '',
      {
        'is-disabled': inputDisabled,
        'is-readonly': readonlyInput,
        'is-short': short,
        'is-exceed': inputExceed,
        'lls-input-group': $slots.prepend || $slots.append,
        'lls-input-group--append': $slots.append,
        'lls-input-group--prepend': $slots.prepend,
        'lls-input--prefix': $slots.prefix || prefixIcon,
        'lls-input--suffix':
          $slots.suffix || suffixIcon || clearable || showPassword,
        'lls-input--prefix-text': prefixText,
      },
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <template v-if="type !== 'textarea'">
      <!-- 前置元素 -->
      <div class="lls-input-group__prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <input
        :tabindex="tabindex"
        v-if="type !== 'textarea'"
        class="lls-input__inner"
        v-bind="$attrs"
        v-model="myCurValue"
        :type="showPassword ? (passwordVisible ? 'text' : 'password') : type"
        :disabled="inputDisabled"
        :readonly="readonlyInput || readonly"
        :autocomplete="autoComplete || autocomplete"
        ref="input"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        :aria-label="label"
        :style="{ paddingLeft: prefixText && `${prefixTextWidth}px` }"
      />
      <!-- 前置内容 -->
      <span
        v-if="prefixText"
        class="lls-input__prefix-text"
        :style="{ width: `${prefixTextWidth}px` }"
        >{{ prefixText }}</span
      >
      <span class="lls-input__prefix" v-if="$slots.prefix || prefixIcon">
        <slot name="prefix"></slot>
        <i class="lls-input__icon" v-if="prefixIcon" :class="prefixIcon"> </i>
      </span>
      <!-- 后置内容 -->
      <span class="lls-input__suffix" v-if="getSuffixVisible()">
        <span class="lls-input__suffix-inner">
          <i
            v-if="showClear"
            class="lls-input__icon lls-icon-error2 lls-input__clear"
            @mousedown.prevent
            @click="clear"
          ></i>
          <i
            v-if="showPwdVisible"
            class="lls-input__icon lls-input__clear"
            :class="passwordVisible ? 'lls-icon-view' : 'lls-icon-hide'"
            @click="handlePasswordVisible"
          ></i>
          <span v-if="isWordLimitVisible" class="lls-input__count">
            <span class="lls-input__count-inner">
              {{ textLength }}/{{ upperLimit }}
            </span>
          </span>
          <template v-if="!showClear || !showPwdVisible || !isWordLimitVisible">
            <slot name="suffix"></slot>
            <i class="lls-input__icon" v-if="suffixIcon" :class="suffixIcon">
            </i>
          </template>
        </span>
        <i
          class="lls-input__icon"
          v-if="validateState"
          :class="['lls-input__validateIcon', validateIcon]"
        >
        </i>
      </span>
      <!-- 后置元素 -->
      <div class="lls-input-group__append" v-if="$slots.append">
        <slot name="append"></slot>
      </div>
    </template>
    <textarea
      v-else
      :tabindex="tabindex"
      class="lls-textarea__inner"
      @compositionstart="handleCompositionStart"
      @compositionupdate="handleCompositionUpdate"
      @compositionend="handleCompositionEnd"
      @input="handleInput"
      ref="textarea"
      v-bind="$attrs"
      :disabled="inputDisabled"
      :readonly="readonlyInput || readonly"
      :autocomplete="autoComplete || autocomplete"
      :style="textareaStyle"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
      :aria-label="label"
    >
    </textarea>
    <span
      v-if="isWordLimitVisible && type === 'textarea'"
      class="lls-input__count"
      >{{ textLength }}/{{ upperLimit }}</span
    >
  </div>
</template>
<script>
/* eslint-disable */
import emitter from 'link-ui-web/src/mixins/emitter';
import Migrating from 'link-ui-web/src/mixins/migrating';
import calcTextareaHeight from './calcTextareaHeight';
import merge from 'link-ui-web/src/utils/merge';
import { isKorean } from 'link-ui-web/src/utils/shared';

export default {
  name: 'llsInput',

  componentName: 'llsInput',

  mixins: [emitter, Migrating],

  inheritAttrs: false,

  inject: {
    llsForm: {
      default: '',
    },
    llsFormItem: {
      default: '',
    },
  },

  data() {
    return {
      textareaCalcStyle: {},
      hovering: false,
      focused: false,
      isComposing: false,
      passwordVisible: false,
      curValue: '',
    };
  },

  props: {
    value: [String, Number],
    size: String,
    limit: String,
    isAmount: Boolean,
    isRate: Boolean,
    pointNum: Number,
    resize: String,
    form: String,
    disabled: Boolean,
    short: Boolean,
    readonly: Boolean,
    readonlyInput: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    /** @Deprecated in next major version */
    autoComplete: {
      type: String,
      validator(val) {
        process.env.NODE_ENV !== 'production' &&
          console.warn(
            "[LinkUI Warn][Input]'auto-complete' property will be deprecated in next major version. please use 'autocomplete' instead."
          );
        return true;
      },
    },
    validateEvent: {
      type: Boolean,
      default: true,
    },
    suffixIcon: String,
    prefixIcon: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false,
    },
    showPassword: {
      type: Boolean,
      default: false,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    tabindex: String,
    prefixText: String,
    prefixTextWidth: {
      type: Number,
      default: 40,
    },
  },

  computed: {
    myCurValue: {
      get() {
        if (!this.focused && this.isAmount) {
          // console.log('myCurValue：get', this.curValue, this.numberToStr(this.curValue));
          return this.numberToStr(this.curValue);
        } else if (this.isRate && !this.isFocus) {
          return !!this.curValue
            ? Number(this.curValue).toFixed(2)
            : this.curValue;
        } else {
          return this.curValue;
        }
      },
      set(newVal, oldVal) {
        // console.log('myCurValue:set', newVal);
        this.curValue = newVal;
      },
    },
    _llsFormItemSize() {
      return (this.llsFormItem || {}).llsFormItemSize;
    },
    validateState() {
      return this.llsFormItem ? this.llsFormItem.validateState : '';
    },
    needStatusIcon() {
      return this.llsForm ? this.llsForm.statusIcon : false;
    },
    validateIcon() {
      return {
        validating: 'lls-icon-loading',
        success: 'lls-icon-circle-check',
        error: 'lls-icon-circle-close',
      }[this.validateState];
    },
    textareaStyle() {
      return merge({}, this.textareaCalcStyle, { resize: this.resize });
    },
    inputSize() {
      return this.size || this._llsFormItemSize || (this.$LinkUI || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.llsForm || {}).disabled;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined
        ? ''
        : String(this.value);
    },
    showClear() {
      return (
        this.clearable &&
        !this.inputDisabled &&
        !this.readonly &&
        this.nativeInputValue &&
        (this.focused || this.hovering)
      );
    },
    showPwdVisible() {
      return (
        this.showPassword &&
        !this.inputDisabled &&
        !this.readonly &&
        (!!this.nativeInputValue || this.focused)
      );
    },
    isWordLimitVisible() {
      return (
        this.showWordLimit &&
        this.$attrs.maxlength &&
        (this.type === 'text' || this.type === 'textarea') &&
        !this.inputDisabled &&
        !this.readonly &&
        !this.showPassword
      );
    },
    upperLimit() {
      return this.$attrs.maxlength;
    },
    textLength() {
      if (typeof this.value === 'number') {
        return String(this.value).length;
      }

      return (this.value || '').length;
    },
    inputExceed() {
      // show exceed style if length of initial value greater then maxlength
      return this.isWordLimitVisible && this.textLength > this.upperLimit;
    },
  },

  watch: {
    curValue: function (newVal, oldVal) {
      // 判断当前输入框是否限制为纯数字
      if (newVal != '' && this.limit == 'number') {
        var reg = /^[0-9]*$/;
        if (!reg.test(newVal)) {
          //不是纯数字的话，仍用之前的值
          this.curValue = oldVal;
          return;
        }
      }
      //判断当前输入框是否限制利率
      if (newVal != '' && this.limit == 'rate') {
        let reg = this.rateReg
          ? this.rateReg
          : /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?|0(\.\d{1,2})?|^[0-9]*[.]+(\[0-9]{1,2})?)$/;
        if (!reg.test(newVal)) {
          this.curValue = oldVal;
          return;
        }
      }
      //判断当前输入框是否限制为大小写字母
      // if (newVal != '' && this.limit == 'letter') {
      //   var reg = /^[A-Za-z]+$/;
      //   if (!reg.test(newVal)) {
      //     //不是大小写字母的话，仍用之前的值
      //     this.curValue = oldVal;
      //     return;
      //   }
      // }
      //判断当前输入框是否限制为货币
      if (newVal != '' && this.limit == 'money') {
        var reg = /^[0-9.-]*$/;
        if (!reg.test(newVal)) {
          this.curValue = oldVal;
          return;
        }
        if (newVal > 999999999999.99) {
          this.curValue = 999999999999.99;
          return
        }
      }
      //判断当前输入框是否限制为货币
      if ((newVal != '' && this.limit == 'money') || this.pointNum) {
        console.log('newVal: ', newVal);
        var reg = /^[0-9,.-]*$/;
        var reg1 = /^[0-9]*$/; //数字
        var reg2 = /^[0-9]*[.]$/; //数字+.
        // var reg3 = /^(([1-9]\d*)(\.\d{1,4})?)$|(0\.0?([1-9]\d?))$/;
        var pointNum = 2;
        if (this.pointNum) {
          pointNum = Number(this.pointNum);
        }
        var reg3 = new RegExp(
          '^(([0-9]\\d*)(\\.\\d{1,' + pointNum.toString() + '})?)$',
          'g'
        );
        // var reg4 = /^(([1-9]\d*)|0)(\.\d{1,3})?$/;
        if (!reg1.test(newVal) && !reg2.test(newVal) && !reg3.test(newVal)) {
          //不是大小写字母的话，仍用之前的值
          this.curValue = oldVal;
          return;
        }
      }
      setTimeout(() => {
        this.$emit('input', this.curValue);
      }, 1000);
      // this.$emit('input',  this.curValue);
      // this.$emit('change', this.curValue);
    },
    value(val) {
      this.$nextTick(this.resizeTextarea);
      if (this.validateEvent) {
        this.dispatch('llsFormItem', 'el.form.change', [val]);
      }
      this.curValue = val;
    },
    // native input value is set explicitly
    // do not use v-model / :value in template
    // see: https://github.com/ElemeFE/element/issues/14521
    nativeInputValue() {
      this.setNativeInputValue();
    },
    // when change between <input> and <textarea>,
    // update DOM dependent value and styles
    // https://github.com/ElemeFE/element/issues/14857
    type() {
      this.$nextTick(() => {
        this.setNativeInputValue();
        this.resizeTextarea();
        this.updateIconOffset();
      });
    },
  },
  methods: {
    numberToStr(number, places, symbol, thousand, decimal) {
      if (number == undefined || number === '') {
        return '';
      }
      number = number || 0;
      places = !isNaN((places = Math.abs(places))) ? places : 2;
      symbol = symbol !== undefined ? symbol : '';
      thousand = thousand || ',';
      decimal = decimal || '.';
      var negative = number < 0 ? '-' : '';
      var i =
        parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '';
      var j = (j = i.length) > 3 ? j % 3 : 0;
      return (
        symbol +
        negative +
        (j ? i.substr(0, j) + thousand : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
        (places
          ? decimal +
            Math.abs(number - i)
              .toFixed(places)
              .slice(2)
          : '')
      );
    },
    focus() {
      this.getInput().focus();
    },
    blur() {
      this.getInput().blur();
    },
    getMigratingConfig() {
      return {
        props: {
          icon: 'icon is removed, use suffix-icon / prefix-icon instead.',
          'on-icon-click': 'on-icon-click is removed.',
        },
        events: {
          click: 'click is removed.',
        },
      };
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
      if (this.validateEvent) {
        this.dispatch('llsFormItem', 'el.form.blur', [this.value]);
      }
    },
    select() {
      this.getInput().select();
    },
    resizeTextarea() {
      if (this.$isServer) return;
      const { autosize, type } = this;
      if (type !== 'textarea') return;
      if (!autosize) {
        this.textareaCalcStyle = {
          minHeight: calcTextareaHeight(this.$refs.textarea).minHeight,
        };
        return;
      }
      const minRows = autosize.minRows;
      const maxRows = autosize.maxRows;

      this.textareaCalcStyle = calcTextareaHeight(
        this.$refs.textarea,
        minRows,
        maxRows
      );
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleCompositionStart() {
      this.isComposing = true;
    },
    handleCompositionUpdate(event) {
      const text = event.target.value;
      const lastCharacter = text[text.length - 1] || '';
      this.isComposing = !isKorean(lastCharacter);
    },
    handleCompositionEnd(event) {
      if (this.isComposing) {
        this.isComposing = false;
        this.handleInput(event);
      }
    },
    handleInput(event) {
      // should not emit input during composition
      // see: https://github.com/ElemeFE/element/issues/10516
      if (this.isComposing) return;

      // hack for https://github.com/ElemeFE/element/issues/8548
      // should remove the following line when we don't support IE
      if (event.target.value === this.nativeInputValue) return;

      this.curValue = event.target.value;

      this.$emit('input', event.target.value);

      // ensure native input value is controlled
      // see: https://github.com/ElemeFE/element/issues/12850
      this.$nextTick(this.setNativeInputValue);
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    calcIconOffset(place) {
      let elList = [].slice.call(
        this.$el.querySelectorAll(`.lls-input__${place}`) || []
      );
      if (!elList.length) return;
      let el = null;
      for (let i = 0; i < elList.length; i++) {
        if (elList[i].parentNode === this.$el) {
          el = elList[i];
          break;
        }
      }
      if (!el) return;
      const pendantMap = {
        suffix: 'append',
        prefix: 'prepend',
      };

      const pendant = pendantMap[place];
      if (this.$slots[pendant]) {
        el.style.transform = `translateX(${place === 'suffix' ? '-' : ''}${
          this.$el.querySelector(`.lls-input-group__${pendant}`).offsetWidth
        }px)`;
      } else {
        el.removeAttribute('style');
      }
    },
    updateIconOffset() {
      this.calcIconOffset('prefix');
      this.calcIconOffset('suffix');
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    },
    handlePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
      this.focus();
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    getSuffixVisible() {
      return (
        this.$slots.suffix ||
        this.suffixIcon ||
        this.showClear ||
        this.showPassword ||
        this.isWordLimitVisible ||
        (this.validateState && this.needStatusIcon)
      );
    },
  },

  created() {
    this.$on('inputSelect', this.select);
  },

  mounted() {
    this.curValue = this.value;
    this.setNativeInputValue();
    this.resizeTextarea();
    this.updateIconOffset();
  },

  updated() {
    this.$nextTick(this.updateIconOffset);
  },
};
</script>
