
// import { mapState } from 'vuex';
// import { LoginInfoStoreModule } from '@/store/modules/user_info';
import topHeader from './components/top_header.vue';
import leftSide from './components/left_side.vue';

export default {
  name: 'homeView',
  components: {
    topHeader,
    leftSide,
  },
  data() {
    return {
      isShowLeftSide: false,
      isShowLeftSideSec: true,
      homePageClass: '',
      // noPaddingClass: false,
    };
  },
  computed: {
    noPaddingClass() {
      return ['/Home', '/home', '/selectCompany'].includes(this.$route.path);
    },
  },
  created() {
    // this.listPageByTenantId();
    console.log(this.$route.path);
    // this.homePageClass = (this.$route.path == '/home' || this.$route.path == '/Home') ? 'homePageClass' : '';
    this.isShowLeftSide = this.$route.path !== '/selectCompany'
    && this.$route.path !== '/companyInfo/stepOne'
    && this.$route.path !== '/companyInfo/naturalBuild'
    && this.$route.path !== '/companyInfo/stepTwo'
    && this.$route.path !== '/companyInfo/stepThree'
    && this.$route.path !== '/companyInfo/stepFour'
    && this.$route.path !== '/companyInfo/stepFive'
    && this.$route.path !== '/companyInfo/stepSix'
    && this.$route.path !== '/signature';
    // this.listPageByTenantId();
    console.log(this.isShowLeftSide, '===this.isShowLeftSide===');
    this.$EventBus.$on('changeBigHomeBg', () => {
      this.homePageClass = '';
    });
    this.$EventBus.$on('enterNeddMenuPage', () => {
      this.isShowLeftSide = true;
      // this.isShowLeftSideSec = true;
    });
    this.$EventBus.$on('quickyEnterDoorPath', (res) => {
      this.isShowLeftSide = true;
      // this.isShowLeftSideSec = true;
    });
    this.$EventBus.$on('enterNoNeddMenuPage', () => {
      // 不需要左侧菜单栏
      this.isShowLeftSide = false;
    });
    this.$EventBus.$on('todoEnterPage', () => {
      this.isShowLeftSideSec = true;
    });
    this.$EventBus.$on('noShowHeadMenus', () => {
      // 切换角色需要
      this.isShowLeftSide = false;
      this.$forceUpdate();
    });
    this.$EventBus.$on('showHeadMenus', () => {
      // 切换角色需要
      this.isShowLeftSide = true;
      this.$forceUpdate();
    });
    console.log(this.isShowLeftSide, 'this.isShowLeftSide');
  },
  methods: {
    // /saas/menu/getMenu?tenantId=1
    listPageByTenantId() {
      this.$http
        .post(
          `/tenantPageCfg/listPageByTenantId?tenantId=${
            JSON.parse(sessionStorage.getItem('objUser') as string).tenantId
          }`,
        )
        .then((res) => {
          const { data } = res;
          const tenantPageCfg = {};
          data.forEach((item) => {
            tenantPageCfg[item.pageUrl] = item.newUrl;
          });
          console.log(tenantPageCfg);
          sessionStorage.setItem(
            'tenantPageCfg',
            JSON.stringify(tenantPageCfg),
          );
        });
    },
    // 字典存储在session
    setSessionStorage() {
      const objUser = JSON.parse((sessionStorage.getItem('objUser') || '{}') as string) || {};
      // this.$store.commit('setUserInfo', objUser);
      // LoginInfoStoreModule.setUserInfo(objUser);
      this.$http
        .post('/spyPc-web/sys/dict/sysDict/listAllDict')
        .then((res: any) => {
          res.data.forEach((i: any) => {
            sessionStorage.setItem(i.dictCode, JSON.stringify(i.subDictList));
          });
        });
    },
    // 存储当前角色
    setCurrentRole() {
      this.$http.post('/spyPc-web/sysRole/roleList').then((res: any) => {
        const roleList = res.data.roleList || [];
        this.$store.commit('setRoleList', roleList);
        sessionStorage.setItem('currentRole', JSON.stringify(roleList));
      });
    },
  },
};
