/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import Vue from 'vue';
import { deepClone, checkRules } from './utils';

Vue.prototype.filterDic = function (importObj: any) {
  let Obj = deepClone(importObj);
  if (Obj === null) {
    return Obj;
  }
  if (typeof Obj === 'object') {
    // 引用类型
    Object.keys(Obj).forEach((key) => {
      if (typeof Obj[key] === 'object') {
        Obj[key] = this.filterDic(Obj[key]);
      } else {
        Obj[key] = this.filterDicStr(Obj[key]);
      }
    });
  } else {
    // 简单类型
    Obj = this.filterDicStr(Obj);
  }
  return Obj;
};

Vue.prototype.filterDicStr = function (JSONStr: string | string[] | null) {
  if (JSONStr == null) {
    return JSONStr;
  }
  if (typeof JSONStr === 'string') {
    if (JSONStr.indexOf('{') > -1 && JSONStr.indexOf('dictParam') > -1) {
      return JSON.parse(JSONStr).dictParam;
    }
  }
  return JSONStr;
};

Vue.prototype.formatDate = function (value: string) {
  if (value) {
    return value.substring(0, 10);
  }
};
/**
 * 格式化给定的数字为货币形式。
 *
 * @param {any} _row - 行参数
 * @param {any} column - 列参数
 * @param {any} number - 要格式化的数字
 * @param {any} index - 索引参数
 * @param {any} places - 小数点位数
 * @param {any} symbol - 货币符号
 * @param {any} thousand - 千位分隔符
 * @param {any} decimal - 小数点分隔符
 * @return {string} 格式化后的货币字符串
 */
/* eslint-disable */
Vue.prototype.formatMoney = function (
  row: any,
  column: any,
  number: string | number | undefined,
  index: any,
  places: number | undefined,
  symbol: string | undefined,
  thousand: string,
  decimal: string
) {
  if (number == undefined || number === '') {
    return '';
  }
  number = number || 0;
  places = !isNaN((places = Math.abs(places as number))) ? places : 2;
  symbol = symbol !== undefined ? symbol : '';
  thousand = thousand || ',';
  decimal = decimal || '.';
  var negative = (number as number) < 0 ? '-' : '';
  var i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '';
  var j: any = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (places
      ? decimal +
        Math.abs((number as any) - (i as any))
          .toFixed(places)
          .slice(2)
      : '')
  );
};

// Vue.prototype.formatMoney = function (
//   row: any,
//   column: any,
//   number: string | number | undefined,
//   index: any,
//   places: number | undefined,
//   symbol: string | undefined,
//   thousand: string,
//   decimal: string
// ) {
//   if (number == undefined || number === '') {
//     return '';
//   }
//   number = number || 0;
//   places = !isNaN((places = Math.abs(places as number))) ? places : 2;
//   symbol = symbol !== undefined ? symbol : '';
//   thousand = thousand || ',';
//   decimal = decimal || '.';
//   var negative = (number as number) < 0 ? '-' : '';
//   var i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '';
//   var j = (i.length) > 3 ? i.length % 3 : 0;
//   return (
//     symbol +
//     negative +
//     (j ? i.substr(0, j) + thousand : '') +
//     i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
//     (places
//       ? decimal +
//         Math.abs((number as unknown as number) - (i as unknown as number))
//           .toFixed(places)
//           .slice(2)
//       : '')
//   );
// };

Vue.prototype.$checkRule = (checkType: string | number, checkVal: any) => {
  let passFlag = true;
  if (checkRules[checkType].regExp && !checkRules[checkType].regExp.test(checkVal)) {
    passFlag = false;
  }

  if (checkRules[checkType].JSRule && !checkRules[checkType].JSRule(checkVal)) {
    passFlag = false;
  }
  return passFlag;
};
Vue.prototype.$validate = (
  rules: { [s: string]: unknown } | ArrayLike<unknown>,
  datas: { [x: string]: string }
) => {
  const regRules = function (rule: { type: string }, data: any) {
    const reg = checkRules[`${rule.type}Reg`];
    return reg.test(data);
  };
  return new Promise((resolve, reject) => {
    try {
      let valid: string | boolean | any = false;
      Object.entries(rules).forEach(([key, rule]) => {
        let value = datas[key];
        if (Object.prototype.toString.call(datas[key]) === '[object String]') {
          value = datas[key].replace(/(^\s*)|(\s*$)/g, '');
        }
        // 该字段的校验规则为对象
        if (Object.prototype.toString.call(rule) === '[object Object]') {
          // console.log(this.$refs[key])
          let rowEl = (this as unknown as Vue).$refs[key];
          if (rowEl) {
            rowEl = (rowEl as Vue & { $el: HTMLElement }).$el;
          }
          if (Object.prototype.toString.call(rowEl) === '[object Array]') {
            // eslint-disable-next-line prefer-destructuring
            rowEl = rowEl && rowEl[0];
          }
          if (rowEl) {
            const el = (rowEl as HTMLElement).getElementsByTagName('text')[0];
            (rowEl as HTMLElement).classList.remove('error');
            // eslint-disable-next-line no-unused-expressions
            el && (rowEl as HTMLElement).removeChild(el);
            const hiddenInput = (rowEl as HTMLElement).getElementsByClassName('hiddenInput')[0];
            // eslint-disable-next-line no-unused-expressions
            hiddenInput && (rowEl as HTMLElement).removeChild(hiddenInput);
          }
          // 当前字段是否为空
          const isEmpty = value === '' || value == null;
          // console.log(key, isEmpty)
          // 是否必填
          const disRequiredAbled = (rule as any).required === true && isEmpty;
          // 数据类型校验
          const disTypeAbled = !isEmpty && (rule as any).type && !regRules(rule as any, value);
          // 数值范围校验
          let disInRange = false;
          if (
            !isEmpty &&
            ((rule as any).type === 'number' || (rule as any).type === 'numberPoint')
          ) {
            if (
              ((rule as any).max && value > (rule as any).max) ||
              ((rule as any).min && value < (rule as any as any).min)
            ) {
              disInRange = true;
            }
          }
          // 自定义规则是否通过
          let disCustomRuleAbled = false;
          if ((rule as any).validator) {
            (rule as any).validator(rule, value, (message: any) => {
              if (message) {
                disCustomRuleAbled = true;
                rule = { message };
              }
            });
          }
          if (disRequiredAbled || disTypeAbled || disInRange || disCustomRuleAbled) {
            if (!valid) {
              valid = rule;
            }
            if (rowEl) {
              const el = document.createElement('text');
              const elHidden = document.createElement('input');
              elHidden.classList.add('hiddenInput');
              el.innerText = (rule as any).message;
              (rowEl as HTMLElement).classList.add('error');
              (rowEl as HTMLElement).appendChild(el);
              (rowEl as HTMLElement).appendChild(elHidden);
              // 定位元素
              // eslint-disable-next-line no-use-before-define
              throttle(handleFocus, 500, rowEl);
            }
          }
        }
      });
      resolve(valid);
    } catch (e) {
      reject(e);
    }
  });
};

function handleFocus(el: { getElementsByClassName: (arg0: string) => { focus: () => void }[] }) {
  el.getElementsByClassName('hiddenInput')[0].focus();
}
let timer: NodeJS.Timeout | null = null;
function throttle(
  this: any,
  fn: { call: (arg0: any, arg1: any) => void },
  wait: number | undefined,
  el: any
) {
  if (!timer) {
    timer = setTimeout(() => {
      timer = null;
      fn.call(this, el);
    }, wait);
  }
}
