<!-- 
    名称：影像树
    描述：影像树及查看影像文件，支持查看图片，PDF，视频。
    开发者：chenrun
    时间：2019-04-18
 -->

<style scoped lang="scss">
.delete-button {
  position: absolute;
  right: 0;
  top: 0;
  color: #2664FD;
}
.download-button{
  position: absolute;
  right: 60px;
  top: -8px;
  &.right-0{
    right: 0;
  }
}
.file-flex {
  width: calc(100% - 48px);
}

::v-deep .lls-checkbox {
  // position: absolute;
  // top: 7px;
  // right: 0;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}
.med-out {
  padding: 0px;
  height: 100%;
}
.med-in {
}
/* 以下为影像树的 */
.med-tree {
}
.med-main-last {
  position: relative;
  left: -1px;
  background-color: #fff;
}
.last-line {
  width: 1px;
  height: 15px;
  border-left: 1px solid #ddd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.folder-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 30px;
  position: relative;
}
.folder-row:hover > .file-title {
  color: #000;
}
.folder-row .inv-num {
  color: #ff4548;
  font-size: 12px;
}
.file-root-line {
  width: 13px;
  height: 0px;
}
.item-icon {
  width: 10px;
  height: 10px;
  cursor: pointer;
  position: absolute;
  left: -5px; /*z-index: 9*/
}
.item-more {
  background: #fff url(assets/more.png) no-repeat center center;
}
.item-less {
  background: #fff url(assets/less.png) no-repeat center center;
}
.file-icon {
  width: 25px;
  height: 25px;
  background: url(assets/file.png) no-repeat center center;
  position: relative;
}
.file-status {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
.file-tocheck {
  background: url(assets/check-tocheck.png) no-repeat center center !important;
}

.file-root {
  background: url(assets/zip.png) no-repeat center center;
}
.file-jpg {
  background: url(assets/jpg.png) no-repeat center center;
}
.file-pdf {
  background: url(assets/pdf.png) no-repeat center center;
}
.file-video {
  background: url(assets/video.png) no-repeat center center;
}
.file-doc {
  background: url(assets/doc.png) no-repeat center center;
}
.file-excel {
  background: url(assets/excel.png) no-repeat center center;
}
.file-zip {
  background: url(assets/zip.png) no-repeat center center;
}
.file-todown {
  width: 25px;
  height: 25px;
  background: url(assets/todown.png) no-repeat center center;
  cursor: pointer;
  opacity: 0.5;
}
.file-toup {
  width: 25px;
  height: 25px;
  background: url(assets/toup.png) no-repeat center center;
  cursor: pointer;
  opacity: 0.6;
}
.file-todown:hover {
  opacity: 1;
}
.file-toup:hover {
  opacity: 1;
}

.file-pass {
  background: url(assets/check-pass.png) no-repeat center center;
}
.file-reject {
  background: url(assets/check-reject.png) no-repeat center center;
}
.file-no {
  background: url(assets/check-no.png) no-repeat center center;
}
.file-line {
  width: 15px;
  height: 1px;
  border-top: 1px solid #d1d1d1;
}
.file-line-last {
  width: 15px;
  height: 30px;
  border-top: 1px solid #d1d1d1;
  position: relative;
  top: 15px;
  left: -1px;
  background-color: #fff;
}
.file-title {
  margin-left: 5px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.folder-box {
  margin-left: 26px;
  border-left: 1px solid #d1d1d1;
}
.file-box {
  margin-left: 26px;
  border-left: 1px solid #d1d1d1;
  position: relative;
}
.media-norecord {
  background: url(assets/noaudit.png) no-repeat center center;
  width: 100%;
  height: 200px;
}
.med-loding {
  background: url(assets/loding.gif) no-repeat center center;
  width: 100%;
  height: 200px;
}
.med-loderr {
  background: url(assets/loaderr_b.png) no-repeat center center;
  width: 100%;
  height: 200px;
  text-align: center;
  padding-top: 140px;
  cursor: pointer;
  color: #00a0e4;
}
.file-title-must::before {
  content: "*";
  color: red;
  margin-right: 5px;
  font-weight: bold;
  font-size: 13px;
  vertical-align: sub;
}
.file-icon-red {
  width: 8px;
  height: 8px;
  background-color: #ff4548;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: -2px;
}
</style>

<template>
  <div>
    <!-- 影像有数据 -->
    <div
      v-for="(item, index) in mediaObj"
      :key="index"
      :class="{ 'med-main-last': index + 1 == mediaObj.length }"
    >
      <!-- 根目录文件夹行 -->
      <div class="folder-row" v-if="item.parentId == -1">
        <div class="file-root-line"></div>
        <!-- 文件类型图标 -->
        <div class="file-icon file-root"></div>
        <!-- 文件名 -->
        <div class="file-title file-flex" :style="{'position': 'relative','padding-left':isShowCheck(item)||isDownload(item)?'16px':'0','box-sizing': 'border-box'}">
          <lls-checkbox
            v-if="isShowCheck(item)||isDownload(item)"
            v-model="item.show"
            @change="$emit('checkFile', { fileItem: null, item })"
          ></lls-checkbox>
          <span style="margin-left:4px">{{ item.catgName }}</span>
          <span style="margin-left:4px">({{ item.fileCount }}张)</span>
          <span style="margin-left:4px">({{ item.fileSizeShow }}{{ item.fileUnit }})</span>
          <!-- 批量操作 只有在节点名称为融资资料审核及运营复审节点显示-->
          <div v-if="isShowBatch" class="delete-button">
            <llsButton
              type="text"
              v-if="!checkBoxShow && !readonly"
              @click="$emit('check', {show:true,type:'delete'})"
              >批量删除</llsButton
            >
          </div>
          <!-- 批量下载，针对所有节点开放 -->
          <div class="delete-button">
            <llsButton
              type="text"
              v-if="checkBoxShow"
              @click="$emit('check', {show:false})"
              >取消</llsButton
            >
            <llsButton
              type="text"
              v-if="checkBoxShow"
              @click="$emit('treeDelete', false)"
              >确定</llsButton
            >
          </div>
          <!-- 天健定制 -->
          <div class="delete-button" @click="$emit('downloadAll')">
            <i class="lls-icon-download"></i>
            <span>下载</span>
          </div>
        </div>
      </div>
      <!-- 文件夹行 -->
      <div class="folder-row" v-else>
        <!-- 隐藏或展开的图标 -->
        <div
          class="item-icon"
          :class="{
            'item-less':
              (item.children && item.children.length > 0) ||
              (item.fileList && item.fileList.length > 0),
          }"
          @click="mediaOpen($event)"
        ></div>
        <div class="file-line"></div>
        <!-- 文件类型图标 -->
        <div class="file-icon">
          <div :class="{ 'file-icon-red': isHasRed(item) }"></div>
        </div>
        <!-- 文件名 -->
        <div class="file-flex" :style="{'position': 'relative','padding-left':isShowCheck(item)||isDownload(item)?'16px':'0','box-sizing': 'border-box'}" >
          <lls-checkbox
            v-if="isShowCheck(item)||isDownload(item)"
            v-model="item.show"
            @change="$emit('checkFile', { fileItem: null, item } , $event)"
          ></lls-checkbox>
          <div
            class="file-title"
            :class="{ 'file-title-must': item.M }"
            @click="showFolder(item)"
          >
            {{ item.catgName }}
            <!-- <span class="inv-num" v-if="isInTotal(item.catgName)"
              >（{{item.fileCount}}张){{ item.fileSizeShow }}张）</span
            > -->
            <span style="margin-left:4px" v-if="item.fileCount">({{ item.fileCount }}张)</span>
            <span style="margin-left:4px" v-if="item.fileSizeShow">({{ item.fileSizeShow }}{{ item.fileUnit }})</span>
          </div>
          
        </div>
        <template v-if="moveFolder">
          <!-- 上下调序的图标 -->
          <div
            class="file-todown"
            v-if="index + 1 != mediaObj.length"
            @click="fileToMove(index, mediaObj, 'down')"
          ></div>
          <div
            class="file-toup"
            v-if="index != 0"
            @click="fileToMove(index, mediaObj, 'up')"
          ></div>
        </template>
      </div>
      <!-- 子文件夹 -->
      <div class="folder-box" v-if="item.children && item.children.length > 0">
        <llsMediaTree
          :mediaObj="item.children"
          @exchangeType="exchangeShowType"
          :totalList="totalList"
          :redList="redList"
          :handlerClick="handlerClick"
          :btnTypes="btnTypes"
          :moveFolder="moveFolder"
          :urlNoteMove="urlNoteMove"
          @toLoadTree="toLoadTreeSon"
          :checkBoxShow="checkBoxShow"
          :readonly="readonly"
          @check="(val) => $emit('check', val)"
          @checkFile="val => $emit('checkFile', val)"
        ></llsMediaTree>
      </div>
      <!-- 影像文件 -->
      <div class="file-box" v-if="item.fileList && item.fileList.length > 0">
        <!-- 文件行 -->
        <div
          class="folder-row"
          :key="fileIndex"
          v-for="(fileItem, fileIndex) in item.fileList"
        >
          <div style="display: flex; align-items: center">

          <div
            class="file-line"
            :class="{ 'file-line-last': fileIndex + 1 == item.fileList.length }"
          ></div>
            <!-- 文件标记图标  只在一下四个文件下展示-->
            <div 
              class="file-status" 
              :class="{
                'file-tocheck': JSON.parse(fileItem.mediaCheckStatus).dictParam == 'TOCHECK',
                'file-pass': JSON.parse(fileItem.mediaCheckStatus).dictParam == 'PASS',
                'file-reject': JSON.parse(fileItem.mediaCheckStatus).dictParam == 'REJECT',
              }"
            >
            </div>
            <!-- 文件类型图标 -->
            <div
              class="file-icon"
              :class="{
                'file-jpg': fileItem.fileType == 'I',
                'file-pdf': fileItem.fileType == 'P',
                'file-video': fileItem.fileType == 'V',
                'file-doc': fileItem.fileType == 'W',
                'file-excel': fileItem.fileType == 'E',
                'file-zip': fileItem.fileType == 'Z',
                'file-pass': fileItem.imgCheckStatus == 'PASS',
                'file-reject': fileItem.imgCheckStatus == 'REJECT',
                'file-no': fileItem.imgCheckStatus == 'TOCHECK',
              }"
            ></div>
          </div>
          <!-- 文件名 -->
          <div class="file-flex" :style="{'position': 'relative','padding-left':isShowCheck(item)||isDownload(item)?'16px':'0','box-sizing': 'border-box'}">
            <!-- 复选框 -->
            <lls-checkbox
              v-if="isShowCheck(item)||isDownload(item)"
              v-model="fileItem.show"
              @change="$emit('checkFile', { fileItem, item } , $event)"
            ></lls-checkbox>
            <div class="file-title" @click="showFile(fileItem)">
              {{ fileItem.fileRename }}
            </div>
          </div>
        </div>
      </div>
      <!-- 最后一个补线 -->
      <div :class="{ 'last-line': index + 1 == mediaObj.length }"></div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "link-ui-web";
export default {
  name: "llsMediaTree",
  props: {
    mediaObj: Array, //影像数据（递归组件用到）
    totalList: "",
    redList: "",
    moveFolder: "", //是否需要对影像文件调序
    urlNoteMove: "", //影像文件夹调序接口
    checkBoxShow: "",
    nodeId: String, // 当前节点名称
    readonly: "",   // 是否禁用
    isShowBatch: false,
    btnTypes:"",
    handlerClick(){}
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted: function () {},
  methods: {
    // 是否显示复选框
    isShowCheck(item) {
      // 同时满足以下条件显示: 1.点击批量删除; 2.子文件列表不为空; 3.发票、合同、销货清单、其他补充资料; 4.文件夹本身配备了删除权限
      const files = ['贸易合同', '发票', '销贷清单', '其他补充资料']
      const isHasDeletePer = Array.isArray(item.permList) && item.permList.length > 0 && item.permList.find(key => key.permType && JSON.parse(key.permType)['dictParam'] === 'DELETE')
      return this.checkBoxShow && ((Array.isArray(item.permList) && item.fileList.length > 0 && files.includes(item.catgName) && isHasDeletePer)||this.isShowBatch)
    },
    isDownload(item){
      return this.checkBoxShow
    },
    //=========================================================================
    //=========================   影像树操作  =================================
    //=========================================================================
    //影像树文件夹展示或收起
    mediaOpen: function ($event) {
      var _this = $($event.currentTarget);
      if (_this.hasClass("item-more") || _this.hasClass("item-less")) {
        var display = _this.parent().next().css("display");
        if (display == "none") {
          _this.removeClass("item-more").addClass("item-less");
        } else {
          _this.removeClass("item-less").addClass("item-more");
        }
        _this.parent().next().slideToggle();
      }
    },
    //点击文件夹
    showFolder: function (folderInfo) {
      var _this = this;
      console.log(folderInfo);
      _this.$emit("exchangeType", folderInfo);
    },
    //点击文件名
    showFile: function (fileInfo) {
      var _this = this;
      const bol = _this.handlerClick ? _this.handlerClick(fileInfo) : true
      bol && _this.$emit("exchangeType", fileInfo);
    },
    //调整文件夹顺序
    fileToMove: function (index, fileList, flag) {
      let paramObj = [];
      paramObj.push({
        id: fileList[index].id,
        modelDefId: fileList[index].modelDefId,
        catgId: fileList[index].catgId,
      });
      if (flag == "up") {
        paramObj.push({
          id: fileList[index - 1].id,
          modelDefId: fileList[index - 1].modelDefId,
          catgId: fileList[index - 1].catgId,
        });
      } else {
        paramObj.push({
          id: fileList[index + 1].id,
          modelDefId: fileList[index + 1].modelDefId,
          catgId: fileList[index + 1].catgId,
        });
      }
      this.$http.post(this.urlNoteMove, paramObj).then(
        (data) => {
          // _this.isBatchDelIng = false;
          let code =
            data.code || data.httpCode || data.body.code || data.body.httpCode;
          if (code == 200) {
            this.$llsMessage({ text: "调整影像文件顺序成功" });
            this.$emit("toLoadTree");
          } else {
            this.$llsAlert({ text: data.body.message || data.body.msg });
          }
        },
        (response) => {
          this.$llsAlert({ text: response.message || response.msg });
        }
      );
    },
    exchangeShowType: function (mediaInfo) {
      var _this = this;
      _this.$emit("exchangeType", mediaInfo);
    },
    toLoadTreeSon: function () {
      this.$emit("toLoadTree");
    },
    //是否在汇总清单中
    isInTotal: function (name) {
      var flag = false;
      var totalList = this.totalList || [];
      for (var i = 0; i < totalList.length; i++) {
        if (totalList[i] == name) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    //该文件夹是否为“项目公司追加材料”，且有未审核的文件
    isHasRed: function (item) {
      let { catgId, fileList } = item;
      let redList = this.redList || [];
      if (redList.includes(catgId)) {
        let tocheckItem = fileList.find((fileItem) => {
          return fileItem.imgCheckStatus == "TOCHECK";
        });
        return tocheckItem;
      } else {
        return false;
      }
    },
  },
  components: { [Checkbox.name]: Checkbox },
};
</script>


