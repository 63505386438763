<!--
    名称：弹框类的表单元素
    描述：弹框类的Form表单信息
 -->

<style scoped>
.lls-form-row {
  /*height: 30px;*/
  margin: 5px 0px;
  width: 400px;
  float: left;
}
.lls-form-btnrow {
  margin: 20px -15px 0;
  clear: both;
  padding-top: 10px;
}
.lls-form-tarow {
  height: 68px;
}
.lls-form-lab {
  font-size: 12px;
  color: #333333;
  text-align: right;
  float: left;
  padding-right: 10px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lls-form-val {
  /*height: 30px;*/
  line-height: 30px;
  float: left;
  width: 250px;
  position: relative;
  font-size: 13px;
}
.lls-form-val-ta {
  height: 64px;
  float: left;
  width: 250px;
}

.lls-form-input {
  width: calc(100%);
  height: 28px;
  padding-left: 8px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  outline: none;
  font-size: 12px;
  line-height: 28px;
  box-sizing: border-box;
}
.lls-form-input:focus {
  border: 1px solid #00a0e4 !important;
}
.lls-form-input-short {
  width: calc(100% - 110px);
  height: 28px;
  padding-left: 8px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  outline: none;
  font-size: 12px;
  float: left;
  line-height: 28px;
  box-sizing: border-box;
  margin-right: 8px;
}
.lls-form-input-short:focus {
  border: 1px solid #00a0e4 !important;
}
.lls-form-input::-webkit-input-placeholder {
  color: #999;
  font-size: 12px;
}
.lls-form-input-short::-webkit-input-placeholder {
  color: #999;
  font-size: 12px;
}
.lls-form-input::-ms-clear {
  width: 0;
  height: 0;
}
.lls-form-unit {
  position: absolute;
  top: 0;
  right: 10px;
  color: #333;
  font-size: 12px;
}
.lls-finput-clear {
  position: absolute;
  top: 0;
  right: 0px;
  background: url(assets/clear.svg) no-repeat center center;
  width: 30px;
  height: 28px;
  cursor: pointer;
  display: none;
}
.lls-form-input:hover ~ .lls-finput-clear {
  display: block;
}
.lls-finput-clear:hover {
  background: url(assets/clear-act.svg) no-repeat center center;
  display: block;
}
.lls-form-send {
  background: #00a0e4;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: 1px solid #00a0e4;
  margin-left: 5px;
  font-size: 12px;
  float: right;
  transition: all 0.5s;
}
.lls-form-send:hover {
  background-color: #24bbfb;
  border: 1px solid #24bbfb;
}
.lls-sending {
  background-color: #ffffff;
  color: #00a0e4;
  cursor: not-allowed;
}
.lls-sending:hover {
  background-color: #ffffff;
  color: #00a0e4;
}
.lls-form-date {
  width: calc(100%);
}
.lls-form-select {
  width: calc(100%);
}
.lls-form-textarea {
  width: 100%;
  height: 100%;
  padding-left: 8px;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  outline: none;
  resize: none;
  font-family: '微软雅黑', 'FZLTHJW';
  font-size: 12px;
  padding-top: 5px;
  box-sizing: border-box;
}
.lls-form-textarea:focus {
  border: 1px solid #00a0e4 !important;
}
.lls-form-textarea::-webkit-input-placeholder {
  color: #999;
  padding-top: 3px;
  font-size: 12px;
  padding-top: 0px;
}
.lls-filename {
  padding-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}
.lls-filename-def {
  color: #999;
}
.lls-fileicon {
  background: url(assets/upload.svg) no-repeat center center;
  height: 28px;
  width: 30px;
  position: absolute;
  top: 0px;
  right: 1px;
}
.lls-form {
  position: absolute;
  top: 0px;
  left: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  width: calc(100%);
  height: 28px;
  font-size: 12px;
  line-height: 28px;
}
.lls-form-file {
  width: calc(100% - 12px);
  height: 29px;
  padding-left: 10px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.lls-form-must:before {
  content: '*';
  color: red;
  margin-right: 5px;
  font-weight: bold;
  font-size: 13px;
  vertical-align: sub;
}
.lls-formdis-dis {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.lls-formdis-dis:hover ~ .lls-finput-clear {
  display: none;
}
.lls-form-unitpad {
  padding-right: 50px;
}

.lls-dia-foot {
  width: 100%;
  height: 40px;
  border-top: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-left {
  justify-content: flex-start;
}
.b-center {
  justify-content: center;
}
.b-right {
  justify-content: flex-end;
}
.dia-foot-big {
  height: 48px;
  line-height: 48px;
}
.lls-dia-msg {
  font-size: 12px;
  padding: 0 15px;
  color: red;
  margin-bottom: 5px;
  white-space: normal;
  margin-left: 115px; /*min-height: 20px;*/
}

.lls-form-textmsg {
  color: #999;
  font-size: 12px;
  background-color: #f5f5f5;
  align-items: center;
  display: flex;
  padding: 8px 15px;
  justify-content: space-between;
}
.lls-form-text {
  width: calc(100% - 80px);
  white-space: normal;
}
.lls-form-texta {
  color: #00a0e4;
  cursor: pointer;
  display: flex;
}
.lls-form-texta div {
  margin-left: 10px;
  background: url(assets/down.svg) no-repeat left center;
  padding-left: 16px;
}

/* 注解 */
.lls-formdia-note {
  cursor: pointer;
  margin: 5px 0px 0px 5px;
  float: left;
}
.lls-input-main {
  height: 28px !important;
}

/* 语音短信 */
.login-yy-row {
  color: #666;
  height: 30px;
  font-size: 12px;
  clear: both;
  line-height: 30px;
  padding-left: 115px;
}
.login-yy-row span {
  color: #00a0e4;
  cursor: pointer;
}

/* 密码显示 */
.lls-pwd-eye {
  background: url(assets/eye-act.svg) no-repeat center center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
}
.lls-pwd-eyeclose {
  background: url(assets/eyeclose-def.svg) no-repeat center center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
}
</style>

<template>
  <!-- eslint-disable -->
  <div
    class=""
    :class="{
      'lls-form-row': type != 'button',
      'lls-form-btnrow': type == 'button',
      'lls-form-tarow': type == 'textarea',
    }"
  >
    <template v-if="type == 'file'">
      <div class="lls-form-lab" :class="{ 'lls-form-must': must }" title="上传文件">上传文件</div>
      <div class="lls-form-val">
        <!-- <div class="lls-filename" :class="{'lls-filename-def':(fileName.indexOf('请选择')!=-1||fileName.indexOf('Choose')!=-1)}">{{fileName}}</div> -->
        <div class="lls-filename" :class="{ 'lls-filename-def': !fileName }">
          {{ fileName ? fileName : placeholder ? placeholder : '请上传文件' }}
        </div>
        <div class="lls-fileicon"></div>
        <form class="lls-form" ref="llsFormOut">
          <input
            type="file"
            class="lls-form-file"
            :name="uploadname"
            @change="showFileName($event)"
            multiple="true"
          />
        </form>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
Vue.use(VueDOMPurifyHTML);
export default {
  name: 'llsFormV1',
  props: {
    type: '', //【必传】类型，可选值【input|password|select|date|textarea|file|button|sms】
    label: '', //【必传】标题
    value: '', //【必传】当前form表单的值
    must: '', //【非必传】是否必填，可选值【true|false】，对应【必填|非必填】，默认为false
    disabled: '', //【非必传】是否可编辑，可选值【true|false】，对应【不可编辑|可编辑】，默认为false
    // sending: "",//【非必传】是否发送短信中，可选值【true|false】，对应【发送中|没发送】，默认为false,仅针对type为sms有效
    times: '', //【非必传】短信读秒数，默认为60，仅针对type为sms有效
    options: {}, //【非必传】下拉框选项，仅针对type为select有效，默认为空
    name: '', //【非必传】上传文件的类型，仅针对type为file有效，默认值为file
    fileType: '', //【非必传】上传文件接收的类型，仅针对type为file有效，默认为所有
    place: '', //【非必传】按钮位置，可选值【left|center|right】,对应【左对齐|居中|右对齐】，默认居中，仅针对type为button有效
    placeholder: '', //【非必传】占位信息，有placeholder时显示为placeholder，没有则显示label
    unit: '', //【非必传】单位信息，显示在输入框内，靠后显示，默认为空
    range: '', //【非必传】级联范围，可选值【province | city | area 】分别表示【省份 | 城市 | 区县】，默认为area，仅针对type为city有效
    multiple: '', //【非必传】是否多选，可选值【true | false】分别表示【多选 ｜ 单选】,默认单选
    errMsg: '', //错误提示信息
    operationDate: '', //【非必传】操作选择的日期，需配合operationType参数，若operationType未传，默认为禁止选择的
    operationType: '', //【非必传】操作类型，可选值【disabled | editable】，分别表示【禁用|可用】，默认为禁用
    disabeldBefore: '', //【非必传】该日期之前的日期禁止选择
    disabeldAfter: '', //【非必传】该日期之后的日期禁止选择
    maxlength: '', //【非必传】输入框最大字符限制
    note: '', //【非必传】注释内容，默认为空
    limit: '', //【非必传】,内容限制类型，默认为不限制
    citySource: '', //【非必传】，省市数据
    sendState: '', //【非必传】，短信发送状态，可选值【sendSuc | sendFail】
    sizeLimit: '', //【非必传】，上传文件的大小限制，单位为kb
    voice: '', //【非必传】是否需要语音发送验证码功能，可选值【true | false】，默认没有
    hideList: [], //需要隐藏的省市编码
    protect: '', //【非必传】是否保护数据信息，可搜索情况下，没有输入信息时，全部信息不可见
    initFileName: '', //【非必传】文件上传的回显文件名（用于修改表单的场景，需要回显文件名）
    sendBtnText: '', //【非必传】，发送验证码按钮文字
  },
  data() {
    return {
      llsLang: {},
      selectObj: this.options || {}, //下拉框的值
      curValue: this.value, //给表单赋初始值
      // fileName: (this.llsLang["comToSelect"]),//上传文件框
      fileName: this.initFileName || '', //上传文件框
      uploadname: this.name || 'file', //上传文件的类型，上传接口会用到
      showErrMsg: this.errMsg || '',
      curTimes: this.times || 60, //短信读秒
      sending: false, //短信是否发送中
      isSend: false, //短信是否请求中
      gloabText: this.$parent.text || '',
      diaPlace: '', //弹框位置
      showNote: false, //是否展示气泡显示注释
      isDis: this.disabled,
      curVoice: true,
      isShowPwd: false, //类型为密码框时，是事显示明文
    };
  },
  watch: {
    //监听错误信息
    errMsg: function (newVal, oldVal) {
      this.showErrMsg = newVal;
    },
    //监听值的改变
    value: function (newVal, oldVal) {
      // console.log("我是子组件，我监听到form表单值有变化，新值为："+newVal);
      this.curValue = newVal;
      if (!newVal && this.type == 'file') {
        // this.fileName = this.llsLang["comToSelect"];
        this.fileName = '';
        this.$refs.llsFormOut.reset();
      }
    },
    //当子组件中的表单值改变时，派发事件给父组件，让父组件接收到最新的值
    curValue: function (newVal, oldVal) {
      if (this.type == 'input') {
        //判断当前输入框是否限制为纯数字
        if (newVal != '' && this.limit == 'number') {
          var reg = /^[0-9]*$/;
          if (!reg.test(newVal)) {
            //不是纯数字的话，仍用之前的值
            this.curValue = oldVal;
            return;
          }
        }
        //判断当前输入框是否限制为大小写字母
        if (newVal != '' && this.limit == 'letter') {
          var reg = /^[A-Za-z]+$/;
          if (!reg.test(newVal)) {
            //不是大小写字母的话，仍用之前的值
            this.curValue = oldVal;
            return;
          }
        }
        //判断当前输入框是否限制为货币
        if (newVal != '' && this.limit == 'money') {
          var reg = /^[0-9,.-]*$/;
          if (!reg.test(newVal)) {
            console.log(newVal, oldVal);
            //不是大小写字母的话，仍用之前的值
            // this.curValue = oldVal;
            this.curValue = newVal.substring(0, newVal.length - 1);
            return;
          }
        }
      }
      this.$emit('update:value', newVal);
      this.$emit('input', newVal);
      // this.$emit('change',val);
    },
    options: function (newVal, oldVal) {
      this.selectObj = newVal;
    },
    sendState: function (newVal, oldVal) {
      var _this = this;
      if (newVal == 'sendSuc') {
        //请求结束
        _this.isSend = false;
        //短信发送中
        _this.sending = true;
        //开始倒计是
        var times = Number(_this.curTimes);
        //国际化
        var msg = _this.LANG == 'EN' ? 'Re-send in ' + times + 's' : times + 's后重发';
        _this.sendText = msg;
        var sendInt = setInterval(function () {
          --times;
          if (times == 0) {
            //清除短信倒计时
            clearInterval(sendInt);
            _this.sending = false;
            _this.sendText = _this.sendBtnText || _this.llsLang['comSend'];
          } else {
            var nowmsg = _this.LANG == 'EN' ? 'Re-send in ' + times + 's' : times + 's后重发';
            _this.sendText = nowmsg;
          }
        }, 1000);
      } else if (newVal == 'sendFail') {
        this.isSend = false;
      }
    },
    disabled: function (newVal, oldVal) {
      this.isDis = newVal;
    },
    initFileName: function (newVal, oldVal) {
      this.fileName = newVal;
    },
  },
  methods: {
    //设置日期值
    getCurValue: function (val) {
      var _this = this;
      _this.curValue = val;
      // _this.$parent.curValue = val;
      //派发事件给父组件
      _this.$emit('input', val);
      _this.$emit('change', val);
    },
    //上传文件
    showFileName: function (event) {
      var _this = this;
      let { sizeLimit } = this;
      var curObj = event.target || event.srcElement;
      var curParentObj = curObj.parentNode;
      var fileName = curParentObj[0].files[0].name; //当前上传的文件名
      var fileSize = curParentObj[0].files[0].size; //当前上传的文件大小
      var fileArr = fileName.split('.');
      var fileSuff = fileArr[fileArr.length - 1]; //文件后缀
      var fileType = _this.fileType || '';
      var fileTypeArr = fileType.split(',');
      //判断当前文件类型是否为允许的
      if (fileType) {
        var isPass = false;
        for (let i = 0; i < fileTypeArr.length; i++) {
          if (fileTypeArr[i] == fileSuff) {
            isPass = true;
            break;
          }
        }
        //判断大小限制
        if (sizeLimit && fileSize > sizeLimit * 1024) {
          var limitText = sizeLimit < 1024 ? sizeLimit + 'KB' : sizeLimit / 1024 + 'M';
          this.$message({ type: 'warning', message: '上传文件不能超过' + limitText + '，请检查' });
          curParentObj.reset();
          return;
        }
        if (isPass) {
          _this.fileName = curParentObj[0].files[0].name;
          this.$emit('update:value', curObj.files[0]);
          _this.$emit('input', curObj.files[0]);
        } else {
          //国际化
          var msg =
            _this.LANG == 'EN'
              ? 'Only the following file types are supported: '
              : '仅支持以下文件类型：';
          this.$message({ type: 'warning', message: msg + fileType });
          curParentObj.reset();
        }
      } else {
        if (sizeLimit && fileSize > sizeLimit * 1024) {
          var limitText = sizeLimit < 1024 ? sizeLimit + 'KB' : sizeLimit / 1024 + 'M';
          this.$message({ type: 'warning', message: '上传文件不能超过' + limitText + '，请检查' });
          curParentObj.reset();
          return;
        }
        _this.fileName = curParentObj[0].files[0].name;
        this.$emit('update:value', curObj.files[0]);
        _this.$emit('input', curObj.files[0]);
        _this.$emit('showFileName',_this.fileName)
      }
    },
    // //发送短信
    // sendClick: function(){
    //     var _this = this;
    //     if(!_this.sending) {
    //         _this.sending = true;
    //         //开始倒计是
    //         var times = Number(_this.curTimes);
    //         //国际化
    //         var msg = ( _this.LANG == "EN" ? ("Re-send in "+times+"s") : (times+"s后重发") );
    //         _this.sendText = msg;
    //         var sendInt = setInterval(function(){
    //             --times;
    //             if(times == 0) {
    //                 //清除短信倒计时
    //                 clearInterval(sendInt);
    //                 _this.sending = false;
    //                 _this.sendText = _this.llsLang["comSend"];
    //             } else {
    //                 var nowmsg = ( _this.LANG == "EN" ? ("Re-send in "+times+"s") : (times+"s后重发") );
    //                 _this.sendText = nowmsg;
    //             }
    //         },1000)
    //         //派发事件给父组件
    //         _this.$emit('onclick');
    //     }
    // },
    //发送短信
    sendClick: function () {
      var _this = this;
      //请求中或数秒中，都不能继续
      if (_this.isSend || _this.sending) {
        return;
      }
      //有定义sendState时
      if (typeof _this.sendState != 'undefined') {
        //开始接口请求
        _this.isSend = true;
        //派发事件给父组件
        _this.$emit('onclick');
      } else {
        _this.sending = true;
        //开始倒计是
        var times = Number(_this.curTimes);
        //国际化
        var msg = _this.LANG == 'EN' ? 'Re-send in ' + times + 's' : times + 's后重发';
        _this.sendText = msg;
        var sendInt = setInterval(function () {
          --times;
          if (times == 0) {
            //清除短信倒计时
            clearInterval(sendInt);
            _this.sending = false;
            _this.sendText = _this.sendBtnText || _this.llsLang['comSend'];
          } else {
            var nowmsg = _this.LANG == 'EN' ? 'Re-send in ' + times + 's' : times + 's后重发';
            _this.sendText = nowmsg;
          }
        }, 1000);
        //派发事件给父组件
        _this.$emit('onclick');
      }
    },
    //发送语音验证码
    sendYY: function () {
      var _this = this;
      //请求中或数秒中，都不能继续
      if (_this.isSend || _this.sending) {
        if (_this.sending) {
          this.$message({ type: 'warning', message: '操作过于频繁，请60s后再尝试' });
        }
        return;
      }
      //开始接口请求
      _this.isSend = true;
      //派发事件给父组件
      _this.$emit('sendVoice');
    },
    //失去焦点时触发
    llsBlur: function (e) {
      //派发事件给父组件
      this.$emit('blur', e);
    },
    //获得焦点时触发
    llsFocus: function (e) {
      this.$emit('update:errMsg', '');
      //派发事件给父组件
      this.$emit('focus', e);
    },
    //键盘按下时触发
    llsKeydown: function (e) {
      this.$emit('keydown', e);
    },
    //键盘松开时触发
    llsKeyup: function (e) {
      this.$emit('keyup', e);
    },
    //点击事件
    llsClick: function (e) {
      this.$emit('click', e);
    },
    //是否有滚动条
    isHasScroll: function () {
      var diaH = this.$parent.diaH;
      var maxH = this.$parent.maxH;
      this.diaPlace = diaH < maxH ? '' : 'bottom';
    },
    llsSearch: function (obj = '') {
      this.$emit('search', obj);
    },
    //查看密码明文
    showPwd: function () {
      this.isShowPwd = !this.isShowPwd;
    },
  },
  mounted() {
    var _this = this;
    var initVoice = this.voice;
    if (typeof initVoice == 'boolean') {
      this.curVoice = initVoice;
    } else {
      this.curVoice = initVoice == 'true' ? true : false;
    }
    //判断是否有滚动条
    setTimeout(function () {
      _this.isHasScroll();
    }, 150);
  },
};
</script>
