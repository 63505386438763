<!--
    名称：影像树
    描述：影像树及查看影像文件，支持查看图片，PDF，视频。
    开发者：chenrun
    时间：2019-04-18
 -->

<style scoped>
.med-out {
  padding: 0px;
  height: 100%;
  padding-bottom: 24px;
}
.med-in {
}
.table-warp-tab{min-height: 0px;margin-top: 10px;padding: 0;overflow: unset;}
.table-wrap {
    width: 100%;
    overflow-x: auto;
    position: relative;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.table-wrap table {
    width: 100%;
    white-space: nowrap;
    overflow-y: auto;
    text-align: center;
}
.table-wrap table thead th {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #f1f2f3;
    color: #333;
    padding: 0 5px;
    font-weight: bolder;
    font-size: 12px;
    height: 38px;
}
.table-norecord-wkfl {
    width: 100%;
    margin: 0 auto 0;
    clear: both;
    text-align: center;
    font-size: 12px;
    color: #444;
    padding: 20px 0;
}
.media-norecord {
  background: url(assets/noaudit.png) no-repeat center center;
  width: 100%;
  height: 200px;
}
.med-loding {
  width: 100%;
  height: 200px;
  text-align: center;
}
.med-circular-fixed {
  width: 60px;
  height: 60px;
  stroke: #00a0e4;
  margin-top: 100px;
}
.med-path-fixed {
  stroke-width: 3;
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
.med-loderr {
  background: url(assets/loaderr_b.png) no-repeat center center;
  width: 100%;
  height: 200px;
  text-align: center;
  padding-top: 140px;
  cursor: pointer;
  color: #00a0e4;
}
/* 以下为文件列表的 */
.med-filelist {
  position: relative;
  height: 100%;
}
.table-video {
  background: url(assets/tableVideo.png) no-repeat center center;
  width: 100%;
  height: 70px;
  cursor: pointer;
}
.table-pdf {
  background: url(assets/tablePdf.png) no-repeat center center;
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-other {
  background: url(assets/tableOther.png) no-repeat center center;
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-word {
  background: url(assets/tableWord.png) no-repeat center center;
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-zip {
  background: url(assets/tableZip.png) no-repeat center center;
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-excel {
  background: url(assets/tableExcel.png) no-repeat center center;
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-med {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.table-del {
  background: url(assets/del-def.png) no-repeat left center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  position: relative;
}
.table-del:hover {
  background: url(assets/del-act.png) no-repeat left center;
}
.table-del:hover > .jpg-tips {
  display: block;
}
.table-btn-row {
  position: absolute;
  right: 0px;
  display: flex;
  top: 6px;
}
.table-btn-row div {
  margin-left: 10px;
}
.table-oper {
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  width: 50px;
  background-color: #00a0e4;
  margin-left: 10px;
}
.table-oper:hover {
  background-color: #078fd6;
}

/* 以下为展示图片的 */
.med-jpg {
  height: 100%;
}
.med-head {
  border-bottom: 1px solid #e2e2e2;
  height: 40px;
  display: flex;
  align-items: center;
  color: #666;
  padding-left: 5px;
  font-size: 14px;
}
.med-head span {
  color: #333;
}
.med-head .med-oldname {
  overflow: hidden;
  text-overflow: ellipsis;
}
.med-head input {
  height: 28px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  color: #666;
  font-size: 14px;
  width: 200px;
}
.jpg-rename {
  background: url(assets/rename-def.png) no-repeat center center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
}
.jpg-oper {
  margin-top: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  position: relative;
}
.jpg-power-row {
  display: flex;
  align-items: center;
}
.jpg-icon {
  margin-bottom: 8px;
  width: 40px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.jpg-icon2 {
  margin-bottom: 8px;
  width: 40px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.jpg-pass {
  background: url(assets/check-pass.png) no-repeat left center;
}
.jpg-reject {
  background: url(assets/check-reject.png) no-repeat left center;
}
.jpg-tocheck {
  background: url(assets/check-tocheck.png) no-repeat left center;
}
.jpg-prev {
  background: url(assets/prev-def.png) no-repeat left center;
}
.jpg-prevdis {
  background: url(assets/prev-dis.png) no-repeat left center;
  cursor: default;
}
.jpg-next {
  background: url(assets/next-def.png) no-repeat left center;
}
.jpg-nextdis {
  background: url(assets/next-dis.png) no-repeat left center;
  cursor: default;
}
.jpg-tomax {
  background: url(assets/tomax-def.png) no-repeat left center;
}
.jpg-tomin {
  background: url(assets/tomin-def.png) no-repeat left center;
}
.jpg-tomindis {
  background: url(assets/tomin-def.png) no-repeat left center;
  opacity: 0.5;
  cursor: default;
}
.jpg-rotate {
  background: url(assets/rotate-def.png) no-repeat left center;
}
.jpg-save {
  background: url(assets/save-def.png) no-repeat left center;
}
.jpg-savedis {
  background: url(assets/save-dis.png) no-repeat left center;
  cursor: default;
}
.jpg-all {
  background: url(assets/all-def.png) no-repeat left center;
}
.jpg-exchange {
  background: url(assets/exchange-def.png) no-repeat left center;
}
.jpg-del {
  background: url(assets/del-def.png) no-repeat left center;
}
.jpg-back {
  background: url(assets/back-def.png) no-repeat left center;
}
.jpg-feed {
  background: url(assets/feed-def.svg) no-repeat left center;
}
.jpg-pdfai {
  background: url(assets/ai-def.svg) no-repeat left center;
  position: absolute;
  right: 10px;
  top: 10px;
}
.jpg-rename:hover {
  background: url(assets/rename-act.png) no-repeat center center;
}
.jpg-prev:hover {
  background: url(assets/prev-act.png) no-repeat left center;
}
.jpg-next:hover {
  background: url(assets/next-act.png) no-repeat left center;
}
.jpg-tomax:hover {
  background: url(assets/tomax-act.png) no-repeat left center;
}
.jpg-tomin:hover {
  background: url(assets/tomin-act.png) no-repeat left center;
}
.jpg-rotate:hover {
  background: url(assets/rotate-act.png) no-repeat left center;
}
.jpg-save:hover {
  background: url(assets/save-act.png) no-repeat left center;
}
.jpg-all:hover {
  background: url(assets/all-act.png) no-repeat left center;
}
.jpg-exchange:hover {
  background: url(assets/exchange-act.png) no-repeat left center;
}
.jpg-del:hover {
  background: url(assets/del-act.png) no-repeat left center;
}
.jpg-back:hover {
  background: url(assets/back-act.png) no-repeat left center;
}
.jpg-feed:hover {
  background: url(assets/feed-act.svg) no-repeat left center;
}
.jpg-pdfai:hover {
  background: url(assets/ai-act.svg) no-repeat left center;
}
.jpg-line {
  width: 1px;
  height: 15px;
  border-left: 1px solid #dadada;
  margin-right: 15px;
}
.jpg-icon:hover > .jpg-tips {
  display: block;
}
.jpg-prev:hover > .jpg-tips {
  display: block;
}
.jpg-next:hover > .jpg-tips {
  display: block;
}
.jpg-save:hover > .jpg-tips {
  display: block;
}
.jpg-tomin:hover > .jpg-tips {
  display: block;
}
.jpg-rename:hover > .jpg-tips-rn {
  display: block;
}
.jpg-tips-rn {
  position: absolute;
  width: 59px;
  height: 36px;
  display: none;
  line-height: 36px;
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  top: 0px;
  left: 50px;
}
.jpg-tip-three-rn {
  position: absolute;
  border-right: 10px solid #444;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  width: 0;
  height: 0;
  bottom: 8px;
  left: -20px;
  border-bottom: 10px solid transparent;
}
.jpg-tips {
  position: absolute;
  width: 59px;
  height: 36px;
  display: none;
  line-height: 36px;
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  top: -45px;
  left: -19px;
}
.jpg-tip-three {
  position: absolute;
  border-right: 10px solid transparent;
  border-top: 10px solid #444;
  border-left: 10px solid transparent;
  width: 0;
  height: 0;
  bottom: -7px;
  left: calc(50% - 10px);
}
.jpg-box {
  width: 100%;
  height: calc(100% - 80px); /*min-height: 500px;*/
  overflow: auto;
  position: relative;
}
.jpg-num-tips {
  width: 120px;
  height: 30px;
  position: absolute;
  top: calc(50%);
  left: calc(50% - 60px);
  background-color: #000;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  color: #fff;
  opacity: 0;
}
.show-tips {
  animation: showTips 2s ease-in-out forwards;
}
@keyframes showTips {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.jpg-in {
  width: calc(100% - 80px);
  height: 100%;
  margin: auto;
  background-size: 100% 100% !important;
  transform-origin: 0 0;
  position: relative;
}
.jpg-redbox {
  position: absolute;
  border-bottom: 2px solid red;
}

/* 以下为展示视频的 */
.med-video {
  position: relative;
  height: calc(100% - 25px);
  text-align: center;
}
.med-close {
  width: 25px;
  height: 30px;
  background: url(assets/back-def.png) no-repeat center 5px;
  cursor: pointer;
  position: relative;
}
.med-close:hover {
  background: url(assets/back-act.png) no-repeat center 5px;
}
.med-close:hover > .jpg-tips {
  display: block;
}
.med-video-box {
  width: calc(100% - 50px);
  height: calc(100% - 40px);
}

/* 以下为展示PDF的 */
.med-pdf {
  position: relative;
  height: 100%;
  &::before{
    content:' ';
    width:20px;
    display:block;
    background:transparent;
    position:absolute;
    height:100%;
  }
}
.med-pdf-box {
  width: 100%;
  height: calc(100% - 85px);
}
.med-pdf-box .pdf-def {
  position: absolute;
  top: 120px;
  left: 20px;
  line-height: 25px;
}
.med-pdf-vuebox {
  width: calc(100% - 10px);
  height: calc(100% - 85px);
  overflow: auto;
  position: relative;
}
.med-pdf-vueboxai {
  width: 628px;
  height: calc(100% - 85px);
  overflow: auto;
  position: relative;
}
.med-pdf-vueinbox {
  width: 100%;
  height: 100%;
  position: relative;
}

/* 新增影像 */
.addmed-info-row {
  height: 30px;
  line-height: 30px;
  padding-top: 60px;
}
.addmed-info-row label {
  float: left;
  width: 60px;
}
.addmed-form-div {
  float: left;
  width: calc(100% - 100px);
  height: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  position: relative;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.addmed-file-out {
}
.addmed-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.addmed-must {
  float: left;
  color: red;
  width: 20px;
}
.addmed-err {
  font-size: 12px;
  color: red;
  clear: both;
  text-align: left;
  padding-top: 10px;
  padding-left: 60px;
  white-space: normal;
}

/*弹出框影像预览*/
.alone-box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 60%;
  height: 600px;
  background-color: #f1f0f0;
  z-index: 999;
  display: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
}
.alone-resize {
  width: 100%;
  height: 100%;
  padding: 0px 2px;
  cursor: n-resize;
  background-color: #f1f0f0;
}
.alone-resize-x {
  width: 4px;
  height: calc(100% - 20px);
  float: left;
  cursor: w-resize;
  background-color: #f1f0f0;
  margin-top: 10px;
}
.alone-work {
  width: calc(100% - 8px);
  height: 100%;
  float: left;
}
.alone-resize-y {
  width: 100%;
  height: 4px;
  cursor: n-resize;
  background-color: #f1f0f0;
}
.left-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  cursor: nw-resize;
  background-color: #f1f0f0;
  border-radius: 4px;
}
.left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: sw-resize;
  border-radius: 4px;
  border-left: 4px solid #f1f0f0;
  border-bottom: 4px solid #f1f0f0;
  box-sizing: border-box;
}
.right-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  cursor: sw-resize;
  background-color: #f1f0f0;
  border-radius: 4px;
}
.right-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: nw-resize;
  border-radius: 4px;
  border-right: 4px solid #f1f0f0;
  border-bottom: 4px solid #f1f0f0;
  box-sizing: border-box;
}
.alone-head-box {
  height: 35px;
  cursor: move;
  background-color: #f1f0f0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 4px;
}
.alone-image-box {
  height: calc(100% - 45px);
  position: relative;
  background-color: #fff;
}
.alone-out-box {
  width: calc(100%);
  height: 100%;
  overflow: auto;
  position: relative;
}
.alone-in-box {
  background-size: 100% 100% !important;
  height: 600px;
}
/*.alone-oper-box{cursor: move;height: 50px;border-top: 1px solid #fff;text-align: center;background-color: #f1f0f0}*/
.alone-oper-row {
  display: flex;
  align-items: center;
}
.alone-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
}
.alone-toall {
  cursor: pointer;
  background: url(assets/toAll.png) no-repeat center center;
}
.alone-tomid {
  cursor: pointer;
  background: url(assets/toMid.png) no-repeat center center;
}
.alone-close {
  cursor: pointer;
  background: url(assets/alone-close.png) no-repeat center center;
}
.alone-back {
  background: url(assets/alone-prev.png) no-repeat center center;
}
.alone-next {
  background: url(assets/alone-next.png) no-repeat center center;
}
.alone-big {
  background: url(assets/alone-max.png) no-repeat center center;
}
.alone-small {
  background: url(assets/alone-min.png) no-repeat center center;
}
.alone-rotate {
  background: url(assets/alone-rotate.png) no-repeat center center;
}
.alone-out {
  background: url(assets/alone-out.png) no-repeat center center;
}
.alone-dis {
  cursor: default;
  opacity: 0.5;
}
.alone-line {
  width: 1px;
  height: 15px;
  border-left: 1px solid #dadada;
  margin-left: 12px;
  margin-right: 2px;
}
.alone-title {
  padding-left: 10px;
  width: calc(100% - 280px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-size: 12px;
}
.alone-msg {
  background: #999;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 50px);
  display: none;
}
.rotate-scale0-0 {
  transform-origin: 0 0;
  transform: rotate(0deg);
}
.rotate-scale90-0 {
  transform-origin: 0 0;
  transform: rotate(90deg);
}
.rotate-scale180-0 {
  transform-origin: 0 0;
  transform: rotate(180deg);
}
.rotate-scale270-0 {
  transform-origin: 0 0;
  transform: rotate(270deg);
}

/* 上传影像 */
.med-upload-box {
}
.med-upload-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #666666;
  flex-direction: column;
  position: relative;
  height: 178px;
  border: 1px dotted #e2e2e2;
  border-radius: 4px;
  background-color: #fafafb;
}
.med-upload-text {
  margin-bottom: 8px;
}
.med-upload-btn {
  color: #00a0e4;
  cursor: pointer;
  margin-top: 20px;
}
.med-upload-form {
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100%);
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
}
.med-upload-file {
  width: calc(100%);
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.med-upload-nodata {
  height: 144px;
  position: relative;
  border: 1px dotted #e2e2e2;
  border-radius: 4px;
  background-color: #fafafb;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}
.med-upload-add {
  width: 52px;
  height: 52px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  color: #bbbbbb;
  background-color: #fff;
  margin: 6px;
  position: relative;
}
.med-upload-div {
  width: 52px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  margin: 6px;
  position: relative;
}
.med-upload-img {
  height: 52px;
  width: 52px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background-color: #fff;
  background: url(assets/ipng.png) no-repeat center center;
}
.med-upload-pdf {
  background: #fff url(assets/ipdf.png) no-repeat center center;
}
.med-upload-xls {
  background: #fff url(assets/ixls.png) no-repeat center center;
}
.med-upload-word {
  background: #fff url(assets/idoc.png) no-repeat center center;
}
.med-upload-zip {
  background: #fff url(assets/izip.png) no-repeat center center;
}
.med-upload-del {
  display: none;
  position: absolute;
  cursor: pointer;
  width: 52px;
  height: 52px;
  opacity: 0.8;
  background-color: #000;
}
.med-upload-img:hover > div {
  display: block;
}
.med-upload-imgtit {
  color: #333333;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 52px;
  margin-top: 4px;
  height: 15px;
}
.med-upload-num {
  color: #666;
  font-size: 14px;
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.med-upload-bar {
  height: 8px;
  width: 300px;
  background-color: #ebeef5;
  border-radius: 4px;
  margin-left: 10px;
  overflow: hidden;
}
.med-upload-barsuc {
  height: 8px;
  background-color: #34c46b;
  border-radius: 4px;
  transition: all 0.5s ease;
}
/* 滚动条美化 */
::-webkit-scrollbar-track-piece {
  /*滚动条凹槽的颜色，还可以设置边框属性*/
  background-color: #ffffff;
}
::-webkit-scrollbar {
  /* 滚动条宽度 */
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  /*外层轨道*/
  /*border-left: 1px solid #d9d9d9;*/
  /*width: 20px;*/
}
::-webkit-scrollbar-thumb {
  /*滚动条的设置*/
  background-color: #bbbbbb;
  /*background-clip:padding-box;*/
  border-radius: 5px;
  min-height: 28px;
}
::-webkit-scrollbar-button {
}
::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.single {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.doubledef {
  background: url(assets/doubledef.png) no-repeat center center;
}
.doubleact {
  background: url(assets/doubleact.png) no-repeat center center;
}
.doubledis {
  background: url(assets/doubledef.png) no-repeat center center;
  opacity: 0.3;
  cursor: default;
}

.lm-pdf-ai {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 220px;
  right: 22px;
  z-index: 1;
  background: url(assets/pdfai.svg) no-repeat center center;
  cursor: pointer;
  opacity: 0.2;
  background-size: 100%;
}
.lm-pdf-ai:hover {
  opacity: 1;
}
.llstips-box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  white-space: normal;
  max-width: 250px;
  line-height: normal;
  opacity: 0;
  transition: all 0.3s;
  right: 80px;
  top: 6px;
  width: 116px;
}
.llstips-box-acttop {
  opacity: 1;
  right: 68px !important;
}
.llstips-arrowright {
  border-top: 10px solid transparent;
  border-left: 10px solid #444;
  border-bottom: 10px solid transparent;
  right: -10px;
  top: calc(50% - 10px);
  position: absolute;
}
.table-media {
  overflow-x: auto !important;
}
/* pdf全屏展示 */
.alone-box-pdf {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 42%;
    height: 100%;
    background-color: #f1f0f0;
    z-index: 999;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.28);
    border-radius: 4px;
}
.med-pdf-box-big {
    width: 100%;
    height: calc(100% - 45px);
}
</style>

<template>
    <div class="med-out" ref="medOut" v-bLoading="layerLoading">
        <!-- 影像树 -->
        <div class="med-tree" v-if="showType=='TREE'">
            <!-- 影像查询中 -->
            <div v-if="treeProgress==-2" class="med-loding">
                <svg viewBox="25 25 50 50" class="med-circular-fixed"><circle cx="50" cy="50" r="20" fill="none" class="med-path-fixed"></circle></svg>
            </div>
            <!-- 影像查询异常 -->
            <div v-if="treeProgress==-1" class="med-loderr" @click="listModelTree()" :title="errMsg">重新加载</div>
            <!-- 影像有数据 -->
            <LlsMediaTree
              v-if="treeProgress>0"
              :checkBoxShow="checkBoxShow"
              :isShowBatch="isShowBatch"
              :mediaObj="mediaTreeObj"
              :btnTypes="btnType"
              @exchangeType="exchangeShowType"
              @check="check"
              @treeDelete="treeDelete"
              @checkFile="hanlderCheckFile"
              :totalList="totalList"
              :readonly="readonly"
              @downloadAll="downloadAll"
              :nodeId="nodeId">
            </LlsMediaTree>
            <!-- 没有影像文件 -->
            <div v-if="treeProgress==0" class="media-norecord"></div>
        </div>
        <!-- 展示文件列表 -->
        <div class="med-filelist" v-else-if="showType=='LIST'">
            <div class="med-head">
                <div class="med-close" @click="goBack()" title="返回"></div>
                当前文件夹为：<span>{{mediaName}}</span>
                <div class="table-btn-row">
                    <llsButton type="drop" label="批量审核" v-if="!readonly && isNeedCheck && urlBatchCheckMedia" :loading="batchCheckIng">
                        <llsButtonItem @click="batchCheck('PASS')">审核通过</llsButtonItem>
                        <llsButtonItem @click="batchCheck('REJECT')">审核不通过</llsButtonItem>
                    </llsButton>
                    <llsButton @click="invOCR()" v-if="filesObj['OCR']&&!readonly" :loading="isOCRIng">OCR</llsButton>
                    <llsButton @click="batchDelete()" v-if="filesObj['BE']&&!readonly" :loading="isBatchDelIng">批量删除</llsButton>
                    <llsButton @click="batchAdjust()" v-if="filesObj['BA']&&!readonly">批量调整分类</llsButton>
                    <llsButton @click="addImage()" v-if="filesObj['U']&&!readonly">新增</llsButton>
                    <!-- <llsButton @click="downImage()" v-if="filesObj['D']&&!readonly">下载</llsButton> -->
                    <template v-if="filesObj['D']">
                        <!-- AMS特有的下载指定影像 -->
                        <llsButton @click="downSpecifyImage()" v-if="urlDownSpecifyMedia" :loading="isDownIng">下载</llsButton>
                        <!-- 通用的下载影像文件 -->
                        <llsButton @click="downImage()" v-else>下载</llsButton>
                    </template>
                    <form method="post" :action="urlDownMedia" id="downImageForm" v-show="false">
                        <input name="modelCode" :value="downModelCode">
                        <input name="catgId" :value="downCatgId">
                        <input name="busiKey" :value="downBusiKey">
                        <button class="media-btn med-down" type="submit"></button>
                    </form>
                </div>
            </div>

            <div class="table-wrap table-warp-tab table-media" style="padding: 0px;margin:10px 0 0 0;">
                <table cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th v-if="canChoose(filesObj)" @click="chooseAll()">
                                <div class="doubledef single" :class="{'doubleact':allFlag}"></div>
                            </th>
                            <th>序号</th>
                            <th>文件</th>
                            <th>标题</th>
                            <th>上传时间</th>
                            <th>维护人员</th>
                            <th style="text-align:left" v-if="!readonly">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(re,index) in filesList" :key="index">
                            <th v-if="canChoose(filesObj)" @click="chooseTr(re.id,re.imgCheckListStatus)">
                                <!-- <div class="doubledef single" :class="{'doubleact':fileIdInList(re.id),'doubledis':re.imgCheckListStatus=='PASS'}"></div> -->
                                <div class="doubledef single" :class="{'doubleact':fileIdInList(re.id)}"></div>
                            </th>
                            <th>{{index+1}}</th>
                            <th v-show="re.fileType=='I'" @click="showFileFromList('I',re.path,re.id)"><img :src="urlHead+''+re.spath" class="table-med"/></th>
                            <th v-show="re.fileType=='V'" @click="showFileFromList('V',re.path,re.id)"><div class="table-video"></div></th>
                            <th v-show="re.fileType=='P'" @click="showFileFromList('P',re.path,re.id)"><div class="table-pdf"></div></th>
                            <th v-show="re.fileType=='W'" @click="showFileFromList('W',re.path,re.id)"><div class="table-word"></div></th>
                            <th v-show="re.fileType=='Z'" @click="showFileFromList('Z',re.path,re.id)"><div class="table-zip"></div></th>
                            <th v-show="re.fileType=='E'" @click="showFileFromList('E',re.path,re.id)"><div class="table-excel"></div></th>

                            <th>{{re.fileRename}}</th>
                            <th>{{re.createTime}}</th>
                            <th>{{re.maintainName}}</th>
                            <th v-if="!readonly">
                                <div v-if="re.imgCheckListStatus=='PASS'" style="text-align:left">已审核通过，不可删除</div>
                                <div class="table-del" v-else-if="re.E" @click="deleteImage(index,re.id,re.modelCode||re.modeCode)" ><div class="jpg-tips">删除<div class="jpg-tip-three"></div></div></div>
                                <div v-else>无权限</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <!--表格无数据-->
                <div class="table-norecord-wkfl" v-if="filesList.length==0">暂无数据~</div>
            </div>
        </div>
        <!-- 展示图片 -->
        <div class="med-jpg" v-else-if="showType=='JPG'">
            <div class="med-head" style="padding-left:20px">
                当前文件为：
                <span>{{mediaPath}}&nbsp;/&nbsp;</span>
                <span v-show="!imageIsRename" class="med-oldname">{{mediaName}}</span>
                <input v-show="imageIsRename" id="renameInput" v-model="mediaNewName">
                <template v-if="!imageIsRename">
                    <div class="jpg-rename" @click="renameImage()" v-if="!readonly && imagePower['N'] && fileCheckStatus!='PASS'"><div class="jpg-tips-rn">重命名<div class="jpg-tip-three-rn"></div></div></div>
                </template>
                <llsButton @click="setName" v-else style="margin-left:10px;" height="26px" :loading="renameIng">保存</llsButton>
            </div>
            <div class="jpg-oper">
                <div v-if="!readonly && isNeedCheck" class="jpg-power-row">
                  sdasdasdasd
                  <div  class="jpg-icon jpg-pass" @click="checkImage('PASS','I')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                  <div  class="jpg-icon jpg-reject" @click="checkImage('REJECT','I')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                   <!-- <template v-if="isShowStatus('TOCHECK')">
                       <div  class="jpg-icon jpg-pass" @click="checkImage('PASS','I')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                       <div  class="jpg-icon jpg-reject" @click="checkImage('REJECT','I')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                   </template> -->
                    <!-- <div  class="jpg-icon jpg-tocheck"><div class="jpg-tips">待标记<div class="jpg-tip-three"></div></div></div> -->
                    <div class="jpg-line"></div>
                </div>

                <div class="jpg-icon2" @click="leftImg()" :class="(tipIndex==0 ? 'jpg-prevdis' : 'jpg-prev')"><div class="jpg-tips">上一张<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon2" @click="rightImg()" :class="(tipIndex==imgAndPdfArr.length-1 ? 'jpg-nextdis' : 'jpg-next')"><div class="jpg-tips">下一张<div class="jpg-tip-three"></div></div></div>

                <div class="jpg-icon jpg-tomax" @click="plusImage()"><div class="jpg-tips">放大<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon2" @click="minusImage()" :class="(isMin ? 'jpg-tomindis' : 'jpg-tomin')"><div class="jpg-tips">缩小<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon jpg-rotate" @click="repeatImage()"><div class="jpg-tips">旋转<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon2" @click="saveImage()" :class="(imageSaveActive ? 'jpg-save' : 'jpg-savedis')"><div class="jpg-tips">保存<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon jpg-all" @click="aloneImage()"><div class="jpg-tips">全屏<div class="jpg-tip-three"></div></div></div>
                <div v-if="!readonly && fileCheckStatus!='PASS'" class="jpg-power-row">
                    <div class="jpg-icon jpg-exchange" @click="changeUnionCode('I')" v-if="imagePower['A']"><div class="jpg-tips">调整<div class="jpg-tip-three"></div></div></div>
                    <div class="jpg-icon jpg-del" @click="delMedModal('I')" v-if="imagePower['E']"><div class="jpg-tips">删除<div class="jpg-tip-three"></div></div></div>
                </div>
                <div class="jpg-line"></div>
                <div class="jpg-icon jpg-feed" @click="mediaFeedback()" v-if="urlFeedbackList"><div class="jpg-tips">影像反馈<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon jpg-back" @click="goBack()"><div class="jpg-tips">返回<div class="jpg-tip-three"></div></div></div>
            </div>
            <div class="jpg-box" :id="'out_img_box'+inittime" ref="imgBox">
                <div class="jpg-in" :id="'in_img_box'+inittime" :style="'transform:rotate(' + rotateDeg + 'deg);'" ref="in_img_box">
                    <!-- <div class="jpg-redbox" :style="{'left':redBox['x'],'top':redBox['y'],'width':redBox['w'],'height':redBox['h']}" v-if="redBox['w']!=0"></div> -->
                    <div class="jpg-redbox" :style="{'left':ritem['x'],'top':ritem['y'],'width':ritem['w'],'height':ritem['h']}" v-if="ritem['w']!=0" :key="index" v-for="ritem,index in redBox"></div>
                </div>
                <llsOcr dom="imgBox" :imgUrl="imageUrl" :width="ocrWidth" :height="ocrHeight" :rotate="rotateDeg" :disabled="ocrDis" v-if="ocr && imageCatgId" :roleKey="roleKey" :catgId="imageCatgId" :fileId="ocrFileId" :sysValue="ocrSysValue" :readonly="readonly" :timestamp="ocrTimestamp" ocrType="image" :ocrImgWidth="ocrImgWidth" :ocrImgHeight="ocrImgHeight"></llsOcr>
                <div class="jpg-num-tips" :class="{'show-tips':showImageTips}">{{tipIndex==0 ? "已是第一张了" : (tipIndex==imgAndPdfArr.length-1 ? "已经最后一张了" : "")}}</div>
            </div>
        </div>
        <!-- 展示视频 -->
        <div class="med-video" v-else-if="showType=='VIDEO'">
            <div class="med-head">
                <div v-if="!readonly && isNeedCheck" class="jpg-power-row" style="padding-left:15px;">
                  <div class="jpg-icon jpg-pass" @click="checkImage('PASS','V')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                  <div class="jpg-icon jpg-reject" @click="checkImage('REJECT','V')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                  <!-- <template v-if="isShowStatus('TOCHECK')">
                       <div v-if="isShowStatus('PASS')" class="jpg-icon jpg-pass" @click="checkImage('PASS','V')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                       <div v-if="isShowStatus('REJECT')" class="jpg-icon jpg-reject" @click="checkImage('REJECT','V')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                   </template> -->
                    <!-- <div v-if="isShowStatus('PASS')" class="jpg-icon jpg-pass" @click="checkImage('PASS','V')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                    <div v-if="isShowStatus('REJECT')" class="jpg-icon jpg-reject" @click="checkImage('REJECT','V')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                    <div v-if="isShowStatus('TOCHECK')" class="jpg-icon jpg-tocheck"><div class="jpg-tips">待标记<div class="jpg-tip-three"></div></div></div> -->
                    <div class="jpg-line"></div>
                </div>
                <div v-if="!readonly && fileCheckStatus!='PASS'" class="jpg-power-row">
                    <div class="jpg-icon jpg-exchange" @click="changeUnionCode('V')" v-if="imagePower['A']" title="调整"></div>
                    <div class="jpg-icon jpg-del" @click="delMedModal('V')" v-if="imagePower['E']" title="删除"></div>
                    <div class="jpg-line"></div>
                </div>
                <div class="med-close" @click="goBack()" title="返回"></div>
                当前文件为：
                <span>{{mediaPath}}&nbsp;/&nbsp;</span>
                <span v-show="!imageIsRename" class="med-oldname">{{mediaName}}</span>
                <input v-show="imageIsRename" id="renameInput" v-model="mediaNewName">
                <template v-if="!imageIsRename">
                    <div class="jpg-rename" @click="renameImage()" v-if="!readonly && imagePower['N'] && fileCheckStatus!='PASS'"><div class="jpg-tips-rn">重命名<div class="jpg-tip-three-rn"></div></div></div>
                </template>
                <llsButton @click="setName" v-else style="margin-left:10px;" height="26px" :loading="renameIng">保存</llsButton>
            </div>
            <video :src="pdfOrVideoUrl" controls="controls" class="med-video-box" autoplay>
                您的浏览器不支持 video 标签。
            </video>
        </div>
        <!-- 展示PDF -->
        <div class="med-pdf" v-else-if="showType=='PDF'">
            <div class="med-head" style="padding-left:20px">
                当前文件为：
                <span>{{mediaPath}}&nbsp;/&nbsp;</span>
                <span v-show="!imageIsRename" class="med-oldname">{{mediaName}}</span>
                <input v-show="imageIsRename" id="renameInput" v-model="mediaNewName">
                <template v-if="!imageIsRename">
                    <div class="jpg-rename" @click="renameImage()" v-if="!readonly && imagePower['N'] && fileCheckStatus!='PASS'"><div class="jpg-tips-rn">重命名<div class="jpg-tip-three-rn"></div></div></div>
                </template>
                <llsButton @click="setName" v-else style="margin-left:10px;" height="26px" :loading="renameIng">保存</llsButton>
            </div>
            <div class="jpg-oper">
                <div v-if="!readonly && isNeedCheck" class="jpg-power-row">
                  <div class="jpg-icon jpg-pass" @click="checkImage('PASS','P')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                  <div class="jpg-icon jpg-reject" @click="checkImage('REJECT','P')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                  <!-- <template v-if="isShowStatus('TOCHECK')">
                        <div class="jpg-icon jpg-pass" @click="checkImage('PASS','P')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                        <div class="jpg-icon jpg-reject" @click="checkImage('REJECT','P')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                   </template> -->
                    <!-- <div v-if="isShowStatus('PASS')" class="jpg-icon jpg-pass" @click="checkImage('PASS','P')"><div class="jpg-tips">审核通过<div class="jpg-tip-three"></div></div></div>
                    <div v-if="isShowStatus('REJECT')" class="jpg-icon jpg-reject" @click="checkImage('REJECT','P')"><div class="jpg-tips">审核拒绝<div class="jpg-tip-three"></div></div></div>
                    <div v-if="isShowStatus('TOCHECK')" class="jpg-icon jpg-tocheck"><div class="jpg-tips">待标记<div class="jpg-tip-three"></div></div></div> -->
                    <div class="jpg-line"></div>
                </div>

                <div class="jpg-icon2" @click="leftImg()" :class="(tipIndex==0 ? 'jpg-prevdis' : 'jpg-prev')"><div class="jpg-tips">上一张<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon2" @click="rightImg()" :class="(tipIndex==imgAndPdfArr.length-1 ? 'jpg-nextdis' : 'jpg-next')"><div class="jpg-tips">下一张<div class="jpg-tip-three"></div></div></div>

                <template v-if="(ocr && inOcrCatg) || (pdfPage&&aiFileBox&&!fromTree)">
                    <div class="jpg-icon jpg-tomax" @click="plusPdf()"><div class="jpg-tips">放大<div class="jpg-tip-three"></div></div></div>
                    <div class="jpg-icon2" @click="minusPdf()" :class="(isMin ? 'jpg-tomindis' : 'jpg-tomin')"><div class="jpg-tips">缩小<div class="jpg-tip-three"></div></div></div>
                </template>

                <div v-if="!readonly && fileCheckStatus!='PASS'" class="jpg-power-row">
                    <div class="jpg-icon jpg-exchange" @click="changeUnionCode('P')" v-if="imagePower['A']"><div class="jpg-tips">调整<div class="jpg-tip-three"></div></div></div>
                    <div class="jpg-icon jpg-del" @click="delMedModal('P')" v-if="imagePower['E']"><div class="jpg-tips">删除<div class="jpg-tip-three"></div></div></div>
                </div>
                <div class="jpg-icon jpg-all" @click="showPDFscreen()">
                    <div class="jpg-tips">全屏
                        <div class="jpg-tip-three"></div>
                    </div>
                </div>
                <div class="jpg-line"></div>
                <div class="jpg-icon jpg-feed" @click="mediaFeedback()" v-if="urlFeedbackList"><div class="jpg-tips">影像反馈<div class="jpg-tip-three"></div></div></div>
                <div class="jpg-icon jpg-back" @click="goBack()"><div class="jpg-tips">返回<div class="jpg-tip-three"></div></div></div>
                <!-- <div class="jpg-icon jpg-pdfai" @click="getContList()" v-show="pdfAi&&showType=='PDF'"><div class="jpg-tips">合同目录<div class="jpg-tip-three"></div></div></div> -->
            </div>
            <!-- 插件展示pdf-用于OCR -->
            <div class="med-pdf-vuebox" v-if="ocr && inOcrCatg" ref="pdfBox">
                <div class="med-pdf-vueinbox" :style="{'width':pdfWidth+'%'}" ref="medPdfBox" :key="ocrTimestamp">
                    <pdf
                        v-show="false"
                        :src="pdfOrVideoUrl"
                        :page="currentPage"
                        @num-pages="pageCount=$event"
                        @page-loaded="currentPage=$event"
                        @loaded="loadPdfHandler">
                    </pdf>
                    <pdf
                        v-for="(item,index) in pdfList"
                        :src="pdfOrVideoUrl"
                        :key="pdfscale"
                        :page="index+1"
                        @loaded="loadPdfHandlerSon(index)">
                    </pdf>
                </div>
                <llsOcr dom="pdfBox" :imgUrl="pdfOrVideoUrl" :width="ocrWidth" :height="ocrHeight" :rotate="rotateDeg" :disabled="ocrDis" v-if="pageCount" :roleKey="roleKey" :catgId="imageCatgId" :fileId="ocrFileId" :sysValue="ocrSysValue" :readonly="readonly" :timestamp="ocrTimestamp" ocrType="pdf" :pageCount="pageCount"></llsOcr>
            </div>
            <template v-else>
                <!-- 开启了pdf的ai功能的 -->
                <div class="med-pdf-vueboxai" v-show="pdfPage&&aiFileBox&&!fromTree" ref="pdfBox">
                    <div class="med-pdf-vueinbox" :style="{'width':pdfWidth+'%'}" ref="medPdfBox" :key="ocrTimestamp">
                        <pdf
                            :src="pdfOrVideoUrl"
                            :page="currentPage"
                            @num-pages="pageCount=$event"
                            @page-loaded="currentPage=$event"
                            @loaded="loadPdfHandler">
                        </pdf>
                        <div class="jpg-redbox" :style="{'left':ritem['x'],'top':ritem['y'],'width':ritem['w'],'height':ritem['h']}" v-if="ritem['w']!=0" :key="index" v-for="ritem, index in redBox"></div>
                    </div>
                </div>
                <!-- 原生展示pdf -->
                <object :data="pdfOrVideoUrl" type="application/pdf" class="med-pdf-box" v-show="!pdfPage||!aiFileBox||fromTree">
                    <embed type="application/pdf" wmode="opaque" :src="pdfOrVideoUrl" class="med-pdf-box">
                        <div class="pdf-def">
                            不支持预览，可尝试以下方法：<br>
                            1、请<a href="https://acrobat.adobe.com/us/en/acrobat/pdf-reader.html" target="_blank" style="color:#00a0e4">点击下载</a>或自行安装Adobe Reader播放器<br>
                            2、切换至其他浏览器（推荐Chrome）
                        </div>
                    </embed>
                </object>
            </template>
            <div class="jpg-num-tips" :class="{'show-tips':showImageTips}">{{tipIndex==0 ? "已是第一张了" : (tipIndex==imgAndPdfArr.length-1 ? "已经最后一张了" : "")}}</div>
        </div>
        <!-- 新增影像-支持批量 -->
        <lls-dialog title="新增影像" :show.sync="isShowData" size="small">
            <div class="med-upload-box">
                <!-- 未上传文件时 -->
                <div v-if="uploadFileList.length == 0" class="med-upload-data">
                    <div class="med-upload-text">拖拽文件到此处</div>
                    <div class="med-upload-text" v-if="getUploadFileMultiple">支持多文件同时上传 {{ restrictedText ? `,${restrictedText}` : ''}}</div>
                    <div class="med-upload-btn">点击选择文件</div>
                    <form class="med-upload-form" ref="medUpload">
                        <input type="file" class="med-upload-file" :multiple="getUploadFileMultiple" @change="showFileName($event)">
                    </form>
                </div>
                <!-- 有上传文件时 -->
                <template v-else>
                    <div class="med-upload-nodata">
                        <form class="med-upload-form" ref="medUpload">
                            <input type="file" class="med-upload-file" :multiple="getUploadFileMultiple" @change="showFileName($event)" style="cursor:default" @click="stopUploadOut($event)">
                        </form>
                        <div class="med-upload-add">+
                            <form class="med-upload-form" ref="medUpload">
                                <input type="file" class="med-upload-file" :multiple="getUploadFileMultiple" @change="showFileName($event)">
                            </form>
                        </div>
                        <div v-for="(item,index) in uploadFileList" :key="index" class="med-upload-div" v-if="item['state']!='PASS'">
                            <div class="med-upload-img" :class="{'med-upload-pdf':item.type.indexOf('pdf')!=-1,'med-upload-word':item.type.indexOf('word')!=-1,'med-upload-xls':item.type.indexOf('spreadsheetml')!=-1,'med-upload-zip':item.type.indexOf('zip')!=-1}">
                                <div class="lls-delete-fff med-upload-del" @click="removeMedia(index)"></div>
                            </div>
                            <div class="med-upload-imgtit" :title="item.name">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="med-upload-num">
                        <template v-if="uploadErrMsg">
                            {{uploadErrMsg}}
                        </template>
                        <template v-else>
                            待上传文件数：{{uploadSuc}}/{{uploadFileNum || uploadFileList.length}}
                            <div class="med-upload-bar" v-if="isAddLoading"><div class="med-upload-barsuc" :style="{'width':getSucLen}"></div></div>
                        </template>
                    </div>
                </template>
            </div>
            <!-- 错误信息 -->
            <div v-if="errList.length > 0">
                <div class="table-wrap" style="padding:0;max-height:300px">
                    <table cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>文件名</th>
                                <th>原因</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(re,index) in errList" :key="index">
                                <th>{{index+1}}</th>
                                <th>{{re.fileName}}</th>
                                <th>{{re.remark}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <llsForm type="button" place="center" :errMsg="addFileErr">
                <llsFormButton value="取消" level="2" @onclick="colseAddMedia"></llsFormButton>
                <llsFormButton value="上传" @onclick="sureAddMediaBatch" :loading="isAddLoading"></llsFormButton>
            </llsForm> -->
            <span slot="footer" class="dialog-footer">
                <lls-button type="primary" plain level="2" @onclick="colseAddMedia">取 消</lls-button>
                <lls-button type="primary" @onclick="sureAddMediaBatch" :loading="isAddLoading">上传</lls-button>
            </span>
        </lls-dialog>

        <!-- 调整分类 -->
        <lls-dialog title="调整影像分类" :show.sync="isShowData" size="small">
            <llsPrompt :dataObj="curChangeObj"></llsPrompt>
            <llsForm type="select" :value.sync="relationCatgId" label="目标分类" :must="true" :options="relationCatgObj"></llsForm>
            <!-- <llsForm type="button" place="center" :errMsg="changeMsg">
                <llsFormButton value="取消" level="2" @onclick="toCloseChange"></llsFormButton>
                <llsFormButton value="确定" @onclick="toSureChange" :loading="isChanging"></llsFormButton>
            </llsForm> -->
            <span slot="footer" class="dialog-footer">
                <lls-button type="primary" plain level="2" @onclick="toCloseChange">取 消</lls-button>
                <lls-button type="primary" @onclick="toSureChange" :loading="isChanging">上传</lls-button>
            </span>
        </lls-dialog>
        <!-- 影像反馈 -->
        <lls-dialog title="影像反馈" :show.sync="isShowFeedback" size="small" mask="false">
            <llsForm type="checkbox" v-model="feedList" label="问题类型" :must="true" :options="feedbackObj"></llsForm>
            <llsForm type="textarea" v-model="feedremark" label="问题描述"></llsForm>
            <!-- <llsForm type="button" place="center">
                <llsFormButton value="取消" level="2" @onclick="toCloseFeedback"></llsFormButton>
                <llsFormButton value="提交" @onclick="toSureFeedback" :loading="isFeedbacking"></llsFormButton>
            </llsForm> -->
             <span slot="footer" class="dialog-footer">
                <lls-button type="primary" plain level="2" @onclick="toCloseFeedback">取 消</lls-button>
                <lls-button type="primary" @onclick="toSureFeedback" :loading="isFeedbacking">上传</lls-button>
            </span>
        </lls-dialog>
        <!-- 全屏预览影像 -->
        <div class='alone-box' id="alone_box">
            <div class="alone-resize-x" name='resizeLeft'></div>
            <div class="alone-work">
                <div class="alone-resize-y" name='resizeTop'></div>
                <!-- 头部 -->
                <div class='alone-head-box' name="moveBlock">
                    <div class='alone-title' name='aloneTitle'></div>
                    <div class="alone-oper-row">
                        <div class='alone-back alone-icon' title='上一张' id='to_back' name='toBack'></div>
                        <div class='alone-next alone-icon' title='下一张' id='to_next' name='toNext'></div>
                        <div class='alone-line'></div>
                        <div class='alone-big alone-icon' title='放大' id='alone_big'></div>
                        <div class='alone-small alone-icon' title='缩小' id='alone_small'></div>
                        <div class='alone-rotate alone-icon' title='旋转' id='alone_rotate'></div>
                        <div class='alone-out alone-icon' title='外部打开' id='alone_out'></div>
                        <div class='alone-line'></div>
                        <div class='alone-close alone-icon' title='关闭' id='alone_close'></div>
                    </div>
                </div>
                <!-- 图片区 -->
                <div class='alone-image-box'>
                    <div class='alone-out-box' name='aloneOutBox'>
                        <div class='alone-in-box' name='aloneInBox' data-rotate='0' data-index='0'></div>
                        <div class='alone-msg' name='toMsg'></div>
                    </div>
                </div>
                <div class="alone-resize-y" name='resizeBottom'></div>
            </div>
            <div class="alone-resize-x" name='resizeRight'></div>
            <div class='left-top' name='leftTop'></div>
            <div class='left-bottom' name='leftBottom'></div>
            <div class='right-top' name='rightTop'></div>
            <div class='right-bottom' name='rightBottom'></div>
        </div>
        <!-- 弹框预览 -->
        <llsPreview v-if="previewFlag" :urls="previewUrl" @close="previewFlag=false"/>

        <div class="alone-box-pdf" id="alone_box_pdf" :key="random" v-if="isShowBoxPdf" :style="style">

            <!-- 头部 -->
            <div class='alone-head-box' name="moveBlock">
                <div class='alone-title'>{{mediaNamePdf}}</div>
                <div class="alone-oper-row">
                    <div class='alone-out alone-icon' title='外部打开' id="alone_box_pdf_open" @click="handleClickOpen"></div>
                    <div class='alone-line'></div>
                    <div class='alone-close alone-icon' title='关闭' id="alone_box_pdf_close" @click="handleClickClose"></div>
                </div>
            </div>
            <object :data="pdfUrl" type="application/pdf" class="med-pdf-box-big">
                <embed type="application/pdf" wmode="opaque" :src="pdfUrl" class="med-pdf-box-big">
                    <div class="pdf-def">
                        不支持预览，可尝试以下方法：<br>
                        1、请<a href="https://acrobat.adobe.com/us/en/acrobat/pdf-reader.html" target="_blank"
                              style="color:#00a0e4">点击下载</a>或自行安装Adobe Reader播放器<br>
                        2、切换至其他浏览器（推荐Chrome）
                    </div>
                </embed>
            </object>
        </div>
        <!-- PDF AI处理 -->
        <!-- <div class="lm-pdf-ai" @mouseover="conTip=1" @mouseleave="conTip=-1" ref="pdfFile" @click="getContList" v-show="pdfAi&&showType=='PDF'">
            <div class="llstips-box" :class="{'llstips-box-acttop':conTip==1}">
                <span>点击打开合同目录</span>
                <div class="llstips-arrowright"></div>
            </div>
        </div> -->
    </div>
</template>

<script>
import LlsMediaTree from "./llsMediaTree.vue";
import methods from "@/common/mediaTreeMixinMethods"
// import llsOcr from '@/components/ocr/llsOcrNew.vue'
import llsOcr from "../ocr/llsOcrNewg.vue";
import Vue from "vue";
export default {
  mixins: [methods],
  name: "llsMedia",
  components: { LlsMediaTree, llsOcr },
  props: {
    nodeId: String, // 当前节点名称 批量删除时会用到
    mediaObj: Array, //影像数据（递归组件用到）
    sonTreeProgress: String, //影像查询状态（递归组件用到）
    modelType: String, //影像树类型：可选值[ATOM|GROUP]，表示[原子树|组合树]
    modelCode: String, //影像树编码
    busiKey: "", //查询影像树的key
    userBusiKey: {
      type: String,
      default: "",
    }, //查询影像树的次级key
    busiName: "", //【非必传】影像树展示在顶级的名称
    isNeedCheck: {
      type: Boolean,
      default: false,
    },//是否需要影像审核功能,可选值[true|false]，表示[需要|不需要]，默认为false不需要
    isShowCheckResult: Boolean, //是否展示影像文件的审核结果（影像树文件前的勾和叉）,可选值[true|false]，表示[展示|不展示]，默认为false不展示
    readonly: Boolean, //影像树是否只读,可选值[true|false]，表示[只读|可操作]，默认为false可操作
    reload: "", //重新加载标记
    url: "", //接口地址
    ocr: "", //是否需要OCR
    preview: "", //【非必传】预览方式，可选值【standard | screen】，分别表示标准模式和全屏弹框模式
    totalList: "", //【非必传】，需要展示总数的文件分类
    assetId: "", //【非必传】，发票OCR的资产ID
    roleKey: "", //【非必传】，OCR用到的岗位角色
    sysValue: "", //【非必传】,OCR用到的系统原始值
    invDialog: "", //【非必传】，是否展示发票弹框（AMS用到），默认为false
    aiFileId: "", //【非必传】，需要默认框选的文件id
    aiFileBox: "", //【非必传】，需要默认框选的坐标
    urlConfig: Object, //【非必传】，影像url配置
    pdfAi: "", //【非必传】，是否需要pdf的ai功能
    pdfPage: "", //【非必传】，跳转到的pdf目录页码
    fileTempMultipleConfig: { //【非必传】，上传文件时的一些校验 ex：{ multiple: false, fileLength: 1 }
      type: Object,
      default: () => ({}),
    },
    isShowBatch: false,  // 批量删除按钮展示
    restrictedText: ''
  },
  data() {
    let urlConf = this.urlConfig ? this.urlConfig : this.GLOBAL;
    return {
      isShowData: false,
      style: '',
      random: 0,
      isShowBoxPdf: false,
      checkBoxShow: false,
      layerLoading: false, //是否在全局loading
      mediaTreeObj: [], //影像树数据
      errMsg: "", //接口异常的错误提示
      treeProgress: "", //当前影像树查询状态：-2-查询中，-1-加载异常，0-无数据，其他-正常显示
      showType: "TREE", //展示类型：TREE-影像树，LIST-文件列表，JPG-图片，PDF-pdf文件，VIDEO-视频
      pdfOrVideoUrl: "", //媒体路径（待展示的视频或PDF文件路径）
      pdfUrl: "",
      imgArr: [], //保存所有照片，上一张/下一张要用到
      imgAndPdfArr: [], //保存所有照片和PDF，上一张/下一张用到
      pdfArr: [], //保存所有PDF文件信息
      videoArr: [], //保存所有视频文件信息
      mediaPath: "", //当前展示的照片/PDF/视频文件路径
      mediaName: "", //当前展示的照片/PDF/视频文件名
      mediaNamePdf: '',
      imageUrl: "", //当前展示的照片路径
      imageCatgId: "", //当前展示的照片分类
      ocrFileId: "", //OCR的文件ID
      imageIndex: 0, //当前展示的照片序号
      videoIndex: 0, //当前展示的视频序号
      pdfIndex: 0, //当前展示的pdf序号
      imagePower: {
        U: false,
        D: false,
        R: false,
        E: false,
        N: false,
        A: false,
        M: false,
      }, //当前展示的照片权限
      imageSaveActive: false, //当前展示的照片是否有旋转，需要保存
      imageIsRename: false, //当前展示的照片是否在重命名
      mediaNewName: "", //当前展示的照片/PDF/视频的新名字
      rotateDeg: 0, //当前展示的照片旋转的度数
      oldRotateDeg: 0,
      showImageTips: false, //是否提示当前照片是否为第一张或最后一张
      tipIndex: -1, //当前展示的文件序号
      filesList: [], //文件列表数据
      filesObj: {}, //当前展示的文件列表类型数据
      isAddMedia: false, //是否新增影像
      uploadFileData: "", //新增上传的文件
      uploadFileList: [], //新增批量上传的文件
      uploadFileNum: 0, //待上传的文件数
      uploadSuc: 0, //上传成功的文件数
      uploadNum: 0, //已上传的文件数（包括失败和成功的）
      doNum: 0, //接口调用次数
      errList: [], //上传失败原因
      uploadErrMsg: "", //上传失败提示
      isAddLoading: false, //是否上传中
      addFileErr: "", //上传错误的提示信息
      downModelCode: "", //下载影像件的模型编码
      downCatgId: "", //下载影像件的分类编码
      downBusiKey: "", //下载影像件的业务key
      urlHead: urlConf.mediaUrlHead || "", //URL前缀（用于测试的）//https://llsams.qhhrly.cn/
      fileCheckStatus: "", //当前预览影像文件的审核状态
      isMin: false, //是否已到最小，不能再缩小
      //调整影像分类用到的
      isShowChange: false, //是否调整分类
      relationCatgId: "", //调整的目标分类
      relationCatgObj: [], //目标分类集合
      relationType: "", //当前调整分类类别
      changeMsg: "", //调整分类弹框的错误信息
      isChanging: "", //是否在调整中
      curChangeObj: "", //当前调整分类信息
      //全屏预览用到的
      //以下为接口替换
      urlListAtomMedia: urlConf.listAtomMedia, //查询原子影像树接口
      urlListGroupMedia: urlConf.listGroupMedia, //查询组合影像树接口
      urlListOtherMedia: urlConf.listOtherMedia, //查询其他影像树接口
      urlListStandardMedia: this.url || urlConf.listStandardMedia, //查询通用影像树接口
      urlAddMedia: urlConf.addMedia, //添加影像树文件接口
      urlDeleteMedia: urlConf.deleteMedia, //删除影像树文件接口
      urlDownMedia: urlConf.downMedia, //下载影像树文件接口
      urlCheckMedia: urlConf.checkMedia, //审核影像树文件接口
      urlRenameMedia: urlConf.renameMedia, //重命名影像树文件名接口
      rotateSaveMedia: urlConf.rotateSaveMedia, //旋转保存影像树文件名接口
      urlChangeMedia: urlConf.changeMedia, //修改影像文件分类接口
      urlListChangeMedia: urlConf.listChangeMedia, //查询影像文件分类接口
      urlBatchDeleteMedia: urlConf.batchDeleteMedia, //批量删除影像件
      urlBatchChangeCatgs: urlConf.batchChangeCatgs, //批量调整影像件分类
      urlBatchCheckMedia: urlConf.batchCheckMedia, //批量审核影像件分类
      urlDownSpecifyMedia: urlConf.downSpecifyMedia, //下载指定影像文件
      urlInvOCR: urlConf.invOCR, //发票OCR接口
      urlFeedbackList: urlConf.feedbackList, //影像反馈-查询接口
      urlFeedbackSubmit: urlConf.feedbackSubmit, //影像反馈-提交接口
      inittime: "",
      ocrWidth: "", //OCR识别宽度
      ocrHeight: "", //OCR识别高度
      ocrDis: false, //OCR识别是否禁用
      ocrSysValue: this.sysValue, //OCR系统原值
      ocrTimestamp: "",
      ocrImgWidth: "", //OCR原图宽度
      ocrImgHeight: "", //OCR原图高宽
      //弹框预览
      previewFlag: false,
      previewUrl: [],
      treeScroll: 0, //记录影像树滚动值
      fileIdsList: [], //文件列表勾选的文件ID集合
      allFlag: false, //是否勾选全选
      isBatchDelIng: false, //是否批量删除中
      changeFlag: 0, //分类调整标志，1-单个，2-批量
      isOCRIng: false, //OCR中
      renameIng: false, //是否重命名中
      //批量审核
      batchCheckIng: false, //是否批量审核中
      //下载指定影像文件
      isDownIng: false,
      //影像反馈的
      isShowFeedback: false, //是否显示弹框
      feedList: [], //问题类型
      feedremark: "", //问题描述
      feedbackObj: [], //问题类型数据
      isFeedbacking: false, //是否提交反馈中
      feedUrl: "", //影像反馈的文件路径
      //发票弹框(AMS)
      isShowInvDia: "", //是否显示发票弹框（AMS）
      catgidFileList: {}, //影像分类对应的文件list
      //PDF预览插件
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      pdfList: [],
      pdfWidth: 100, //VUE插件展示的pdf宽度
      pdfscale: 1, //PDF缩放比例

      oldImageW: 0, //原图宽度
      redBox: [{ x: 0, y: 0, w: 0, h: 0 }], //红框位置
      redScale: 1, //图片缩放比例

      conTip: -1,
      fromTree: true,
      showRedbox: false,
      mediaCheckStatus: '', // 文件标记状态
      btnType:"",//判断是批量删除还是批量下载
      fileIds:[]
    };
  },
  watch: {
    modelCode: function (newVal, oldVal) {
      this.listModelTree();
      this.showType = "TREE";
      this.$emit("changePanel", { type: "TREE", fileId: "" });
    },
    reload: function (newVal, oldVal) {
      if (newVal) {
        //mediaObj没有传值表示是从最外层业务页面调用的
        var mediaObj = this.mediaObj;
        var sonTreeProgress = this.sonTreeProgress;
        if (!mediaObj) {
          this.listModelTree();
        } else {
          this.mediaTreeObj = mediaObj;
          this.treeProgress = sonTreeProgress;
        }
      }
    },
    fileIdsList: function (newVal, oldVal) {
      if (newVal.length == 0) {
        this.allFlag = false;
      }
    },
    sysValue: {
      handler(newVal, oldVal) {
        this.ocrSysValue = newVal;
      },
      deep: true,
    },
    invDialog: function (newVal, oldVal) {
      //是否弹出显示
      var initInvDialog = newVal;
      if (typeof initInvDialog == "boolean") {
        this.isShowInvDia = initInvDialog;
      } else {
        this.isShowInvDia = initInvDialog == "true" ? true : false;
      }
    },
    pageCount: function (newVal, oldVal) {
      if (newVal) {
        var _this = this;
        var num = newVal;
        var index = 1;
        this.pdfList = [];
        while (index <= num) {
          this.pdfList.push(index);
          ++index;
        }
        // setTimeout(function(){
        //     var domMedPdfBox = _this.$refs.medPdfBox;
        //     var canPdfs = domMedPdfBox.getElementsByTagName("canvas");
        //     var totalH = 0;
        //     var totalW = 0;
        //     for(var i = 1;i<canPdfs.length;i++) {
        //         totalW = canPdfs[i]["clientWidth"];
        //         var curH = canPdfs[i]["clientHeight"];
        //         totalH += curH;
        //     }
        //     _this.ocrHeight = totalH;
        //     _this.ocrWidth = totalW;
        // },2000)
      }
    },
    aiFileId: function (newVal, oldVal) {
      if (oldVal == -1) {
        return;
      }
      var _this = this;
      _this.showRedbox = true;
      var curFileInfo = "";
      var imgAndPdfArr = this.imgAndPdfArr || [];
      for (let i = 0; i < imgAndPdfArr.length; i++) {
        if (imgAndPdfArr[i]["id"] == newVal) {
          curFileInfo = imgAndPdfArr[i];
        }
      }

      if (curFileInfo) {
        var fileType = curFileInfo["fileType"];

        //展示文件类型判断
        var showFileType =
          fileType == "I"
            ? "JPG"
            : fileType == "P"
            ? "PDF"
            : fileType == "V"
            ? "VIDEO"
            : "LIST";
        this.showType = showFileType;
        if (showFileType == "JPG") {
          //获取当前照片序号
          for (let index in _this.imgArr) {
            if (_this.imgArr[index].id === curFileInfo.id) {
              _this.imageIndex = Number(index) + 1;
              break;
            }
          }
          _this.getPageFalg(false);
          //展示照片
          _this.showJPG(_this.imageIndex);
        } else if (showFileType == "PDF") {
          //获取当前pdf序号
          for (let index in _this.pdfArr) {
            if (_this.pdfArr[index].id === curFileInfo.id) {
              _this.pdfIndex = Number(index) + 1;
              break;
            }
          }
          _this.getPageFalg(false);
          //展示PDF
          _this.showPDF(_this.pdfIndex, "TREE", false);
          setTimeout(function () {
            _this.currentPage = Number(_this.pdfPage);
            _this.setRedBox();
          }, 100);
        }
      }
    },
    pdfPage: function (newVal, oldVal) {
      // this.currentPage = Number(newVal);
      var _this = this;
      _this.showRedbox = true;
      _this.fromTree = false;
      setTimeout(function () {
        _this.currentPage = Number(newVal);
      }, 100);
    },
    aiFileBox: function (newVal, oldVal) {
      this.showRedbox = true;
      this.fromTree = false;
      this.setRedBox();
    },
  },
  computed: {
    getUploadFileMultiple() {
      const catgId = this.filesObj["catgId"];
      console.log("catgId", catgId)
      if (!catgId) return true
      // return !!this.fileTempMultipleConfig[catgId]?.multiple;
      return this.fileTempMultipleConfig[catgId]?.multiple == false ? false : true;
    },
    getSucLen() {
      var sucNum = this.uploadSuc;
      // var allNum = this.uploadFileList.length;
      var allNum = this.uploadFileNum;
      var sucWidth = 300 * (sucNum / allNum);
      return sucWidth + "px";
    },
    //判断当前打开的影像是否需要OCR，需要OCR的才用canvas渲染
    inOcrCatg() {
      if (this.ocr) {
        //需要OCR的影像分类
        // var catgidList = ["B0031","B0032","B0033","B0034","B0035","B0036","B0037","B0015","B0011","B0004","BA0009"];
        //撤销PDF ocr功能
        var catgidList = [];
        var curCatgId = this.imageCatgId;
        var catgidStr = catgidList.join(",");
        if (catgidStr.indexOf(curCatgId) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 获取相关文件是否退回
    getFileItem() {
      let check = [];
      let tFn = (arr) => {
        arr.forEach((x) => {
          if (x.children.length > 0 && x.fileList) tFn(x.children);
          x.fileList && x.fileList.map((file) => {
            file.parent = x
            if (file.show) check.push(file);
          });
        });
      };
      tFn(this.mediaTreeObj)
      return check
    },

    // 判断文件是否具有删除权限
    isHasDeletePre() {
      // 在融资资料审核节点，不支持删除已标记的文件，其余节点如常
      if (this.nodeId === 'usr_ts_compliance_check') {
        return this.fileCheckStatus == 'TOCHECK'
      } else {
        return this.fileCheckStatus != 'PASS'
      }
    }
  },
  mounted: function () {
    //mediaObj没有传值表示是从最外层业务页面调用的
    var mediaObj = this.mediaObj;
    var sonTreeProgress = this.sonTreeProgress;
    if (!mediaObj) {
      this.listModelTree();
    } else {
      this.mediaTreeObj = mediaObj;
      this.treeProgress = sonTreeProgress;
    }
    this.inittime = new Date().getTime();
    //是否弹出显示
    var initInvDialog = this.invDialog;
    if (typeof initInvDialog == "boolean") {
      this.isShowInvDia = initInvDialog;
    } else {
      this.isShowInvDia = initInvDialog == "true" ? true : false;
    }
  },
  methods: {
    downloadAll(){
        this.getFileIds(this.mediaTreeObj);
        if(!this.fileIds.length){
            return this.$llsMessage({text:"影像树下未上传任何文件，请先上传。",type:"info"})
        }else{
            window.location.href = `/spyPc-web/media/sync/do/downloadFilesZip?modelCode=${this.modelCode}&busiKey=${this.busiKey}`
        }
    },
    getFileIds(arr){
        arr.forEach(item=>{
            if(item.children.length){//遍历文件夹
                this.getFileIds(item.children)
            }else{
                item.fileList.forEach(ele=>{
                    this.fileIds.push(ele.id)
                })
            }
        })
    },
    check(val){
      this.btnType = val.type;
      this.checkBoxShow = val.show
    },
    //=========================================================================
    //=========================     初始化    =================================
    //=========================================================================
    //预览模型影像树
    listModelTree: function (fileId) {
      var _this = this;
      var nodeType = _this.modelType || "ATOM";
      var modCode = _this.modelCode || "";
      var busiKey = _this.busiKey || "";
      var busiName = _this.busiName || "";
      var userBusiKey = _this.userBusiKey || "";
      //组合树需要将busiKey转为JSON对象
      if (
        nodeType == "GROUP" ||
        nodeType == "OTHER" ||
        nodeType == "STANDARD"
      ) {
        try {
          if (typeof JSON.parse(busiKey) == "object") {
            //是字符串
            busiKey = JSON.parse(busiKey);
            _this.busiKey = busiKey;
          }
        } catch (e) {}
        try {
          if (typeof JSON.parse(busiName) == "object") {
            //是字符串
            busiName = JSON.parse(busiName);
          }
        } catch (e) {}
      }
      _this.treeProgress = -2; //加载中
      var mediaUrl = "";
      var mediaBody = {};
      if (nodeType == "ATOM") {
        mediaUrl =
          _this.urlListAtomMedia +
          "?modelCode=" +
          modCode +
          "&busiKey=" +
          busiKey +
          "&userBusiKey=" +
          userBusiKey +
          "&busiName=" +
          busiName;
      } else if (nodeType == "GROUP") {
        mediaUrl = _this.urlListGroupMedia;
        mediaBody = {
          atomModelBusiNames: busiName || {},
          atomModelBusis: busiKey,
          modelCode: modCode,
        };
      } else if (nodeType == "OTHER") {
        var urlParam = "";
        var i = 0;
        for (var key in busiKey) {
          urlParam +=
            i == 0
              ? "?" + key + "=" + busiKey[key]
              : "&" + key + "=" + busiKey[key];
          i++;
        }
        mediaUrl = _this.urlListOtherMedia + urlParam;
      } else if (nodeType == "STANDARD") {
        var urlParam = "";
        var i = 0;
        for (var key in busiKey) {
          urlParam +=
            i == 0
              ? "?" + key + "=" + busiKey[key]
              : "&" + key + "=" + busiKey[key];
          i++;
        }
        mediaUrl = _this.urlListStandardMedia + urlParam;
      }
      _this.$http.post(mediaUrl, mediaBody).then(
        function (data) {
          let code =
            data.code || data.httpCode || data.body.code || data.body.httpCode;
          if (code == "200") {
            let imgArr = [];
            let catgidFileList = {};
            let pdfArr = [];
            let videoArr = [];
            let imgAndPdfArr = [];
            var doData = function (oldData) {
              for (let i = 0; i < oldData.length; i++) {
                if (!oldData[i]["show"]) {
                  oldData[i]["show"] = false;
                }
                if (!oldData[i]["children"]) {
                  oldData[i]["children"] = [];
                }
                if (!oldData[i]["fileList"]) {
                  oldData[i]["fileList"] = [];
                }
                oldData[i]["nodeName"] = oldData[i]["catgName"];
                // oldData[i]["modelCode"] = (nodeType == "ATOM" ? modCode : oldData[i]['remark']);
                //AMS标准树特殊做法-2020-05-14
                var code = ""; //code为影像树编码
                if (nodeType == "STANDARD") {
                  code = oldData[i]["modelDef"]
                    ? oldData[i]["modelDef"]["code"]
                    : "";
                }
                oldData[i]["modelCode"] =
                  nodeType == "ATOM"
                    ? modCode
                    : nodeType == "STANDARD"
                    ? oldData[i]["modeCode"] || code
                    : oldData[i]["remark"];
                oldData[i]["fileType"] = "F";
                //对权限的处理
                var permList = oldData[i]["permList"] || [];
                for (var p = 0; p < permList.length; p++) {
                  var permType = permList[p]["permType"]
                    ? JSON.parse(permList[p]["permType"])["dictParam"]
                    : "";
                  if (permType == "ADD") {
                    oldData[i]["U"] = true;
                  }
                  if (permType == "DOWNLOAD") {
                    oldData[i]["D"] = true;
                  }
                  if (permType == "REPLACE") {
                    oldData[i]["R"] = true;
                  }
                  if (permType == "DELETE") {
                    oldData[i]["E"] = true;
                  }
                  if (permType == "RENAME") {
                    oldData[i]["N"] = true;
                  }
                  if (permType == "ADJUSTCATG") {
                    oldData[i]["A"] = true;
                  }
                  if (permType == "REQUIRED") {
                    oldData[i]["M"] = true;
                  }
                  if (permType == "UPLOAD") {
                    oldData[i]["UPLOAD"] = true;
                  }
                  //旋转后保存
                  if (permType == "ROTATE_SAVE") {
                    oldData[i]["RS"] = true;
                  }
                  //批量删除
                  if (permType == "BATCH_DELETE") {
                    oldData[i]["BE"] = true;
                  }
                  //批量调整分类
                  if (permType == "BATCH_ADJUST_CATG") {
                    oldData[i]["BA"] = true;
                  }
                  //发票OCR
                  if (permType == "OCR") {
                    oldData[i]["OCR"] = true;
                  }
                }
                //对文件列表的处理
                var fileList = oldData[i]["fileList"] || [];
                for (let p = 0; p < fileList.length; p++) {
                  var fileType = JSON.parse(fileList[p]["fileType"])[
                    "dictParam"
                  ];
                  if (nodeType == "STANDARD") {
                    fileList[p]["modeCode"] = oldData[i]["modeCode"] || code;
                  }
                  if (fileType == "PDF") {
                    fileList[p]["fileType"] = "P";
                    pdfArr.push(fileList[p]);
                    imgAndPdfArr.push(fileList[p]);
                  } else if (fileType == "MP4" || fileType == "MOV") {
                    fileList[p]["fileType"] = "V";
                    videoArr.push(fileList[p]);
                  } else if (
                    fileType == "DOC" ||
                    fileType == "doc" ||
                    fileType == "DOCX" ||
                    fileType == "docx"
                  ) {
                    fileList[p]["fileType"] = "W";
                  } else if (
                    fileType == "ZIP" ||
                    fileType == "zip" ||
                    fileType == "RAR" ||
                    fileType == "rar" ||
                    fileType == "QZ" ||
                    fileType == "qz"
                  ) {
                    fileList[p]["fileType"] = "Z";
                  } else if (
                    fileType == "XLSX" ||
                    fileType == "xlsx" ||
                    fileType == "XLS" ||
                    fileType == "xls"
                  ) {
                    fileList[p]["fileType"] = "E";
                  } else {
                    fileList[p]["fileType"] = "I";
                    imgArr.push(fileList[p]);
                    imgAndPdfArr.push(fileList[p]);
                  }

                  //文件列表权限处理
                  oldData[i]["U"] ? (fileList[p]["U"] = oldData[i]["U"]) : "";
                  oldData[i]["D"] ? (fileList[p]["D"] = oldData[i]["D"]) : "";
                  oldData[i]["R"] ? (fileList[p]["R"] = oldData[i]["R"]) : "";
                  oldData[i]["E"] ? (fileList[p]["E"] = oldData[i]["E"]) : "";
                  oldData[i]["N"] ? (fileList[p]["N"] = oldData[i]["N"]) : "";
                  oldData[i]["A"] ? (fileList[p]["A"] = oldData[i]["A"]) : "";
                  oldData[i]["UPLOAD"]
                    ? (fileList[p]["UPLOAD"] = oldData[i]["UPLOAD"])
                    : "";
                  oldData[i]["RS"]
                    ? (fileList[p]["RS"] = oldData[i]["RS"])
                    : "";
                  oldData[i]["BE"]
                    ? (fileList[p]["BE"] = oldData[i]["BE"])
                    : "";
                  oldData[i]["BA"]
                    ? (fileList[p]["BA"] = oldData[i]["BA"])
                    : "";
                  oldData[i]["OCR"]
                    ? (fileList[p]["OCR"] = oldData[i]["OCR"])
                    : "";
                  //根据父组件的传参判断是否需要展示影像文件审核结果
                  if (_this.isShowCheckResult) {
                    //文件审核状态
                    fileList[p].imgCheckStatus = JSON.parse(
                      fileList[p].mediaCheckStatus
                    ).dictParam;
                    if (_this.modelType == "STANDARD") {
                      var status = JSON.parse(fileList[p].fileStatus).dictParam;
                      fileList[p].imgCheckStatus =
                        status == "AUDITED"
                          ? "PASS"
                          : status == "AUDITING"
                          ? "REJECT"
                          : "INIT";
                    }
                  }
                  //当前图片归属的影像模型（组合树新增和旋转保存要用到）
                  fileList[p]["modelCode"] =
                    nodeType == "ATOM" ? modCode : oldData[i]["remark"];
                  //其他类型的情况，当前文件所属的影像树的modelcode和busikey由后台remark字段返回
                  if (_this.modelType == "OTHER") {
                    var remark = oldData[i]["remark"];
                    var remarkArr = remark.split("|");
                    fileList[p]["modelCode"] = remarkArr[0];
                    fileList[p]["busiKey"] = remarkArr[1];
                  }
                }
                catgidFileList[oldData[i]["catgId"]] = oldData[i]["fileList"];
                doData(oldData[i]["children"]);
              }
            };
            var oldData = data.data.data || [];
            _this.$emit('getData', oldData)
            if (nodeType == "OTHER") {
              oldData = [oldData];
            }
            doData(oldData);
            _this.imgArr = imgArr; //保存图片信息
            _this.pdfArr = pdfArr; //保存pdf信息
            _this.videoArr = videoArr; //保存视频信息
            _this.imgAndPdfArr = imgAndPdfArr; //保存图片和PDF信息
            _this.mediaTreeObj = oldData;
            _this.treeProgress = oldData.length;
            _this.catgidFileList = catgidFileList;
            //如果是重命名后再来查询的情况，需要重新获取当前影像的imageIndex，不然重命名后排序变了就有问题
            if (fileId) {
              var showType = _this.showType;
              if (showType == "JPG") {
                for (var i = 0; i < imgArr.length; i++) {
                  if (imgArr[i]["id"] == fileId) {
                    _this.imageIndex = i + 1;
                    break;
                  }
                }
              } else if (showType == "PDF") {
                for (var i = 0; i < pdfArr.length; i++) {
                  if (pdfArr[i]["id"] == fileId) {
                    _this.pdfIndex = i + 1;
                    break;
                  }
                }
              } else if (showType == "VIDEO") {
                for (var i = 0; i < videoArr.length; i++) {
                  if (videoArr[i]["id"] == fileId) {
                    _this.videoIndex = i + 1;
                    break;
                  }
                }
              }
            }
          } else {
            _this.treeProgress = -1; //加载异常
            _this.errMsg = data.body.message || data.body.msg;
          }
        },
        function (error) {
          _this.treeProgress = -1; //加载异常
          _this.errMsg = error.message;
        }
      );
    },
    //=========================================================================
    //=========================   影像树操作  =================================
    //=========================================================================
    exchangeShowType: function (mediaInfo) {
      console.log('mediaInfo', mediaInfo)
      if (mediaInfo.mediaCheckStatus) {
        this.mediaCheckStatus = JSON.parse(mediaInfo.mediaCheckStatus)
      }
      var _this = this;
      _this.showRedbox = false;
      //记录当前影像树的滚动值
      var medOut = _this.$refs.medOut || "";
      var medParScroll = medOut.parentNode.scrollTop || 0;
      _this.treeScroll = medParScroll;

      //展示文件类型判断
      var showFileType =
        mediaInfo.fileType == "I"
          ? "JPG"
          : mediaInfo.fileType == "P"
          ? "PDF"
          : mediaInfo.fileType == "V"
          ? "VIDEO"
          : mediaInfo.fileType == "W"
          ? "WORD"
          : mediaInfo.fileType == "E"
          ? "EXCEL"
          : mediaInfo.fileType == "Z"
          ? "ZIP"
          : "LIST";

      if (showFileType == "JPG") {
        _this.showType = showFileType;
        _this.$emit("changePanel", {
          type: showFileType,
          fileId: mediaInfo.id,
        });
        _this.$emit("exchangeType", mediaInfo);
        //获取当前照片序号
        for (let index in _this.imgArr) {
          if (_this.imgArr[index].id === mediaInfo.id) {
            _this.imageIndex = Number(index) + 1;
            break;
          }
        }
        _this.getPageFalg(false);
        //展示照片
        _this.showJPG(_this.imageIndex);
      } else if (showFileType == "PDF") {
        _this.showType = showFileType;
        _this.$emit("changePanel", {
          type: showFileType,
          fileId: mediaInfo.id,
        });
        _this.$emit("exchangeType", mediaInfo);
        //获取当前pdf序号
        for (let index in _this.pdfArr) {
          if (_this.pdfArr[index].id === mediaInfo.id) {
            _this.pdfIndex = Number(index) + 1;
            break;
          }
        }
        _this.getPageFalg(false);
        //展示PDF
        _this.showPDF(_this.pdfIndex, "TREE", true);
      } else if (showFileType == "VIDEO") {
        _this.showType = showFileType;
        _this.$emit("changePanel", {
          type: showFileType,
          fileId: mediaInfo.id,
        });
        _this.$emit("exchangeType", mediaInfo);
        //获取当前视频序号
        for (let index in _this.videoArr) {
          if (_this.videoArr[index].id === mediaInfo.id) {
            _this.videoIndex = Number(index) + 1;
            break;
          }
        }
        //展示视频
        _this.showVIDEO(_this.videoIndex);
      } else if (showFileType == "LIST") {
        _this.showType = showFileType;
        _this.$emit("changePanel", {
          type: showFileType,
          fileId: mediaInfo.id,
        });
        _this.$emit("exchangeType", mediaInfo);
        //判断是否为全屏弹框预览影像
        if (_this.preview == "screen") {
          _this.showType = "TREE";
          _this.$emit("changePanel", { type: "TREE", fileId: "" });
          return;
        }
        var otherBusiKey = "";
        _this.mediaName = mediaInfo.nodeName;
        for (let i = 0; i < mediaInfo.fileList.length; i++) {
          try {
            mediaInfo.fileList[i].imgCheckListStatus = JSON.parse(
              mediaInfo.fileList[i].mediaCheckStatus
            ).dictParam;
          } catch (e) {
            mediaInfo.fileList[i].imgCheckListStatus = "";
          }
        }
        _this.filesList = mediaInfo.fileList;
        if (_this.modelType == "OTHER") {
          var remark = mediaInfo["remark"];
          var remarkArr = remark.split("|");
          mediaInfo["modelCode"] = remarkArr[0];
          mediaInfo["busiKey"] = remarkArr[1];
          otherBusiKey = remarkArr[1];
        }
        _this.filesObj = mediaInfo;
        _this.downModelCode = mediaInfo["modelCode"]; //下载影像件的模型编码
        _this.downCatgId = mediaInfo["catgId"]; //下载影像件的分类编码
        //根据组合树或原子树获取busiKey
        var busiKey = _this.busiKey; //默认为原子树的
        if (_this.modelType == "GROUP") {
          // busiKey = JSON.parse(busiKey);
          busiKey = busiKey[mediaInfo["modelCode"]];
        } else if (_this.modelType == "OTHER") {
          busiKey = otherBusiKey;
        }
        _this.downBusiKey = busiKey; //下载影像件的业务key
        //清除已勾选项
        _this.fileIdsList = [];
        _this.allFlag = false;
      } else {
        _this.$llsMessage({ type: "warn", text: "该文件类型不支持预览哦" });
      }
    },
    //展示照片
    showJPG: function (imageIndex, isList) {
      var _this = this;
      this.$emit("closeContList");
      this.redBox = [{ x: 0, y: 0, w: 0, h: 0 }];
      var fileInfo = _this.imgArr[imageIndex - 1];
      console.log('fileInfo', fileInfo)
      //TODO，需替换
      // fileInfo.path = "static/2.png";
      // fileInfo.path = "http://p0.qhimg.com/t0176742db8cc53cfe0.jpg";
      // fileInfo.path = "https://llsams.llschain.com/uploadFiles/ZJWJ-LJBL/2020-0342/C0003/20200526104303820_0687.jpg?1591168931109";
      // fileInfo.path = "https://wecagw.qhhrly.cn/uploadFiles/6681137405153513472/WA0013/20201124111623870.jpg?timestamp=1606199918386";
      //判断是否为全屏弹框预览
      if (_this.preview == "screen") {
        _this.showType = "TREE";
        _this.$emit("changePanel", { type: "TREE", fileId: "" });
        _this.previewFlag = true;
        _this.previewUrl = [
          {
            type: "img",
            name: fileInfo.fileRename,
            src: _this.urlHead + fileInfo.path,
          },
        ];
        return;
      }
      //AMS需求，发票需要弹框显示，不用影像树自带的
      var catgId = fileInfo["catgId"];
      _this.$emit("clickFile", _this.catgidFileList[catgId], fileInfo);
      if (_this.isShowInvDia && fileInfo["userBusiKey"] && catgId == "B0004") {
        _this.showType = isList || "TREE";
        _this.$emit("changePanel", { type: isList || "TREE", fileId: "" });
        return;
      }
      //权限判断
      _this.imagePower = {
        U: fileInfo["U"] ? true : false,
        D: fileInfo["D"] ? true : false,
        R: fileInfo["R"] ? true : false,
        E: fileInfo["E"] ? true : false,
        N: fileInfo["N"] ? true : false,
        A: fileInfo["A"] ? true : false,
        RS: fileInfo["RS"] ? true : false,
        BE: fileInfo["BE"] ? true : false,
        BA: fileInfo["BA"] ? true : false,
      };
      //回显照片名称
      _this.mediaPath = fileInfo.catgName;
      _this.mediaName = fileInfo.fileRename;
      _this.mediaNewName = fileInfo.fileRename;
      //重置影像信息
      _this.isMin = false;
      _this.oldRotateDeg = 0;
      _this.rotateDeg = 0;
      _this.imageIsRename = false; //未重命名的状态
      _this.imageSaveActive = false; //是否可以保存旋转
      //回显照片，并设置一系列属性，如高，宽，旋转等
      var path = _this.urlHead + fileInfo.path;
      //影像反馈需要的文件路径
      this.feedUrl = path;
      var timestamp = new Date().getTime();
      $("#in_img_box" + _this.inittime).attr("data-rotate", 0);
      // $("#in_img_box").attr("data-rotate",0);
      // $("#in_img_box").css({"background":"url('"+path+ "?timestamp="+timestamp+"') no-repeat"});
      //设置图片高宽
      var img = new Image();
      img.src = path + "?" + timestamp;
      img.onload = function () {
        _this.ocrFileId = fileInfo.id;
        _this.imageCatgId = fileInfo.catgId;
        var height = img.height;
        var width = img.width;
        var outW = $("#out_img_box" + _this.inittime).width();
        outW = Math.round(outW); //四舍五入，避免出现小数点后精度丢失，照片回显不全
        // var outW = $("#out_img_box").width();
        //计算显示比例
        var outH = (outW / width) * height;
        outH = Math.round(outH); //四舍五入，避免出现小数点后精度丢失，照片回显不全
        $("#in_img_box" + _this.inittime).css({
          width: outW,
          height: outH,
          marginLeft: 0,
          marginTop: 0,
          background:
            "url('" + path + "?timestamp=" + timestamp + "') no-repeat",
        });
        // $("#in_img_box").css({width:outW,height:outH,marginLeft:0,marginTop:0,"background":"url('"+path+ "?timestamp="+timestamp+"') no-repeat"});
        _this.ocrWidth = outW;
        _this.ocrHeight = outH;
        _this.ocrImgWidth = width;
        _this.ocrImgHeight = height;
        _this.imageUrl = fileInfo.path + "?" + timestamp; //记录当前展示图片的路径
        _this.ocrDis = false;
        _this.ocrTimestamp = timestamp;
        //框选位置
        _this.oldImageW = width;
        _this.redScale = outW / width;
        _this.setRedBox();
      };
      _this.fileCheckStatus = fileInfo.mediaCheckStatus && JSON.parse(fileInfo.mediaCheckStatus).dictParam; //当前文件审核状态
    },
    //全屏显示pdf
    showPDFscreen() {
        var fileInfo = this.pdfArr[this.pdfIndex - 1];
        this.mediaNamePdf = fileInfo.fileRename;
        this.pdfUrl = this.urlHead + fileInfo.path;
        this.isShowBoxPdf = true;
        setTimeout(()=> {
          let draggableElement = document.getElementById("alone_box_pdf")
          console.log(draggableElement.style)
          draggableElement.addEventListener('mousedown', function(ev) {
          console.log('监听鼠标按下事件')
           ev.preventDefault()
           var oEvent = ev || event;       //判断浏览器兼容
            var clickX = oEvent.clientX;
            var clickY = oEvent.clientY;
            var nodeLeft = Number(draggableElement.style.left.split('px')[0]);
            var nodetTop = Number(draggableElement.style.top.split('px')[0]);
            var relX = clickX - nodeLeft;
            var relY = clickY - nodetTop;
            let webPageW = document.body.clientWidth;
            let webPageH = document.body.clientHeight;
            console.log(clickX,clickY,nodeLeft,nodetTop,relX,relY,webPageW,webPageH)
            document.onmousemove =  function (ev) {
                console.log('监听mousemove事件')
                ev.preventDefault();
                var oEvent = ev || event;       //判断浏览器兼容
                var moveX = oEvent.clientX;
                var moveY = oEvent.clientY;
                var showLeft = moveX - relX;
                var showTop = moveY - relY;
                console.log(oEvent,moveY,relX,relY,showLeft,showTop)
                showTop = showTop < 4 ? 4 : showTop;
                // showLeft = showLeft < 0 ? 0 : showLeft;

                showTop = (showTop > webPageH ? (webPageH - 10) : showTop);
                showLeft = (showLeft > webPageW ? (webPageW - 10) : showLeft);
                draggableElement.style.left = showLeft + 'px';
                draggableElement.style.top = showTop + 'px';
                draggableElement.style.transform = 'translate(0%,0%)';
             };
             document.onmouseup =function () {
                console.log('鼠标移除')
                document.onmousemove = null;
                document.onmouseup = null;
            }

        })
        },0)
    },
    handleClickOpen() {
      var fileInfo = this.pdfArr[this.pdfIndex - 1];
       window.open(this.urlHead + fileInfo.path, '_blank');
    },
    handleClickClose() {
       this.isShowBoxPdf = false;
    },
    //展示pdf
    showPDF: function (pdfIndex, isList, fromTree) {
      var _this = this;
      var fileInfo = _this.pdfArr[pdfIndex - 1];
      console.log(_this.pdfArr,pdfIndex,fileInfo, '====pdfIndex=== ')
      this.fromTree = fromTree;
      if (fromTree) {
        this.$emit("closeContList");
      }
      //TODO，需替换
      // fileInfo.path = "static/test.pdf";
      // fileInfo.path = "https://arxiv.org/pdf/2007.07156.pdf";
      //判断是否为全屏弹框预览
      if (_this.preview == "screen") {
        _this.showType = "TREE";
        _this.$emit("changePanel", { type: "TREE", fileId: "" });
        _this.previewFlag = true;
        _this.previewUrl = [
          {
            type: "pdf",
            name: fileInfo.fileRename,
            src: _this.urlHead + fileInfo.path,
          },
        ];
        return;
      }
      var catgId = fileInfo["catgId"];
      _this.$emit("clickFile", _this.catgidFileList[catgId], fileInfo);
      if (_this.isShowInvDia && fileInfo["userBusiKey"] && catgId == "B0004") {
        _this.showType = isList || "TREE";
        _this.$emit("changePanel", { type: isList || "TREE", fileId: "" });
        return;
      }
      _this.isMin = false;
      _this.pdfWidth = 100;
      //权限判断
      _this.imagePower = {
        U: fileInfo["U"] ? true : false,
        D: fileInfo["D"] ? true : false,
        R: fileInfo["R"] ? true : false,
        E: fileInfo["E"] ? true : false,
        N: fileInfo["N"] ? true : false,
        A: fileInfo["A"] ? true : false,
        RS: fileInfo["RS"] ? true : false,
        BE: fileInfo["BE"] ? true : false,
        BA: fileInfo["BA"] ? true : false,
      };
      //回显文件名称
      _this.mediaPath = fileInfo.catgName;
      _this.mediaName = fileInfo.fileRename;
      _this.mediaNewName = fileInfo.fileRename;
      _this.pdfOrVideoUrl = _this.urlHead + fileInfo.path;
      this.random = Math.random();
      if(this.isShowBoxPdf) {
          this.isShowBoxPdf = false;
          setTimeout(()=> {
            this.showPDFscreen();
          },0)
        }
      console.log(_this.mediaName,_this.pdfOrVideoUrl, '=== _this.pdfOrVideoUrlshowPDF===')
      // _this.fileCheckStatus = fileInfo.imgCheckStatus || ""; //当前文件审核状态
      _this.fileCheckStatus = fileInfo.mediaCheckStatus && JSON.parse(fileInfo.mediaCheckStatus).dictParam; //当前文件审核状态
      //影像反馈需要的文件路径
      this.feedUrl = fileInfo.path;
      _this.ocrFileId = fileInfo.id;
      _this.imageCatgId = fileInfo.catgId;
      _this.ocrDis = false;
      var timestamp = new Date().getTime();
      _this.ocrTimestamp = timestamp;
    },
    //展示视频
    showVIDEO: function (videoIndex, isList) {
      var _this = this;
      var fileInfo = _this.videoArr[videoIndex - 1];
      this.$emit("closeContList");
      //判断是否为全屏弹框预览
      if (_this.preview == "screen") {
        _this.showType = "TREE";
        _this.$emit("changePanel", { type: "TREE", fileId: "" });
        _this.previewFlag = true;
        _this.previewUrl = [
          {
            type: "video",
            name: fileInfo.fileRename,
            src: _this.urlHead + fileInfo.path,
          },
        ];
        return;
      }
      var catgId = fileInfo["catgId"];
      _this.$emit("clickFile", _this.catgidFileList[catgId], fileInfo);
      if (_this.isShowInvDia && fileInfo["userBusiKey"] && catgId == "B0004") {
        _this.showType = isList || "TREE";
        _this.$emit("changePanel", { type: isList || "TREE", fileId: "" });
        return;
      }
      //权限判断
      _this.imagePower = {
        U: fileInfo["U"] ? true : false,
        D: fileInfo["D"] ? true : false,
        R: fileInfo["R"] ? true : false,
        E: fileInfo["E"] ? true : false,
        N: fileInfo["N"] ? true : false,
        A: fileInfo["A"] ? true : false,
        RS: fileInfo["RS"] ? true : false,
        BE: fileInfo["BE"] ? true : false,
        BA: fileInfo["BA"] ? true : false,
      };
      //回显文件名称
      _this.mediaPath = fileInfo.catgName;
      _this.mediaName = fileInfo.fileRename;
      _this.mediaNewName = fileInfo.fileRename;
      _this.pdfOrVideoUrl = _this.urlHead + fileInfo.path;
      // _this.fileCheckStatus = fileInfo.imgCheckStatus || ""; //当前文件审核状态
      _this.fileCheckStatus = fileInfo.mediaCheckStatus && JSON.parse(fileInfo.mediaCheckStatus).dictParam; //当前文件审核状态
      //影像反馈需要的文件路径
      this.feedUrl = fileInfo.path;
    },
    //给文件框选位置
    setRedBox: function () {
      var _this = this;
      var imgScale = "";
      // var imgScale = _this.redScale;
      // var imgScale = (this.showType=='PDF' ? 1 : _this.redScale);
      if (this.showType == "PDF") {
        var domPdfBox = this.$refs.pdfBox;
        var pdfBoxWidth = domPdfBox.clientWidth;
        imgScale = pdfBoxWidth / 628;
        imgScale = _this.pdfscale;
      } else {
        imgScale = _this.redScale;
      }
      this.redBox = [{ x: 0, y: 0, w: 0, h: 0 }];
      if (_this.aiFileBox && _this.showRedbox) {
        var coord = _this.aiFileBox;

        var coordList = coord.split("---");
        var redBoxList = [];
        for (var i = 0; i < coordList.length; i++) {
          var coordBox = coordList[i].split("===");
          var dragImgX = coordBox[0];
          var dragImgY = coordBox[1];
          var dragImgW = coordBox[2];
          var dragImgH = coordBox[3];

          var newImgX = dragImgX * imgScale;
          var newImgY = dragImgY * imgScale;
          var newImgW = dragImgW * imgScale;
          var newImgH = dragImgH * imgScale;

          console.log("原始坐标1", dragImgX, dragImgY, dragImgW, dragImgH);
          console.log("定时坐标1", newImgX, newImgY, newImgW, newImgH);
          redBoxList.push({
            x: newImgX + "px",
            y: newImgY + "px",
            w: newImgW + "px",
            h: newImgH + "px",
          });
        }

        this.redBox = redBoxList;
      }
    },
    //=========================================================================
    //=========================   文件列表操作  ===============================
    //=========================================================================
    //删除
    deleteImage: function (index, fileId, modelCode) {
      var _this = this;
      _this.$llsConfirm({
        text: "确定要删除该文件吗？",
        title: "请确认",
        buttonSure: {
          click: function (callback) {
            _this.$http
              .post(
                _this.urlDeleteMedia +
                  "?modelCode=" +
                  modelCode +
                  "&fileId=" +
                  fileId
              )
              .then(
                (data) => {
                  callback("suc");
                  let code =
                    data.code ||
                    data.httpCode ||
                    data.body.code ||
                    data.body.httpCode;
                  if (code == 200) {
                    _this.$llsMessage({ text: "删除成功" });
                    //重新查询影像树
                    _this.listModelTree();
                    //把删除的影像从文件列表移除
                    _this.filesList.splice(index, 1);
                    _this.$emit("operate", "deleteSuc");
                  } else {
                    _this.$llsAlert({
                      text: data.body.message || data.body.msg,
                    });
                    _this.$emit("operate", "deleteFail");
                  }
                },
                (response) => {
                  callback("suc");
                  _this.$llsAlert({ text: response.message || response.msg });
                }
              );
          },
        },
      });
    },
    //从文件列表点击文件展示详情
    showFileFromList: function (type, path, id) {
      var _this = this;
      if (type == "I") {
        _this.showType = "JPG";
        _this.$emit("changePanel", { type: "JPG", fileId: id });
        //获取当前照片序号
        for (let index in _this.imgArr) {
          if (_this.imgArr[index].id == id) {
            _this.imageIndex = Number(index) + 1;
            break;
          }
        }
        _this.getPageFalg(false);
        //展示照片
        _this.showJPG(_this.imageIndex, "LIST");
      } else if (type == "P") {
        _this.showType = "PDF";
        _this.$emit("changePanel", { type: "PDF", fileId: id });
        //获取当前pdf序号
        for (let index in _this.pdfArr) {
          if (_this.pdfArr[index].id == id) {
            _this.pdfIndex = Number(index) + 1;
            break;
          }
        }
        _this.getPageFalg(false);
        //展示PDF
        _this.showPDF(_this.pdfIndex, "LIST", true);
      } else if (type == "V") {
        _this.showType = "VIDEO";
        _this.$emit("changePanel", { type: "VIDEO", fileId: id });
        //获取当前视频序号
        for (let index in _this.videoArr) {
          if (_this.videoArr[index].id == id) {
            _this.videoIndex = Number(index) + 1;
            break;
          }
        }
        //展示视频
        _this.showVIDEO(_this.videoIndex, "LIST");
      } else {
        _this.$llsMessage({ type: "warn", text: "该文件类型不支持预览哦" });
      }
    },
    //新增影像
    addImage: function () {
      var _this = this;
      _this.isAddMedia = true;
      _this.addFileErr = "";
      _this.uploadFileList = [];
      _this.uploadFileNum = 0;
      _this.uploadSuc = 0;
      _this.uploadNum = 0;
      _this.doNum = 0;
      _this.errList = [];
      _this.uploadErrMsg = "";
      _this.isAddLoading = false;
      //与llsUploadFile同页面使用时，新增影像拖拽文件无效
      document.ondragover = function (e) {
          return true;
      }
      document.ondrop=function (e) {
          return true;
      }
    },
    //取消新增影像
    colseAddMedia: function () {
      var _this = this;
      _this.isAddMedia = false;
      //与llsUploadFile同页面使用时，新增影像拖拽文件无效
      document.ondragover = function (e) {
          e.preventDefault()
          return false;
      }
      document.ondrop=function (e) {
          e.preventDefault()
          return false;
      }
    },
    //上传文件
    showFileName: function (event) {
      var _this = this;
      var curObj = event.target || event.srcElement;
      var curParentObj = curObj.parentNode;
      var fileName = curParentObj[0].files[0].name; //当前上传的文件名
      _this.uploadFileData = curObj.files[0];
      var fileList = _this.uploadFileList;
      var curList = curObj.files || [];
      for (var i = 0; i < curList.length; i++) {
        fileList.push(curList[i]);
      }
      _this.addFileErr = "";
    },
    //阻止点击事件
    stopUploadOut: function (e) {
      e.preventDefault();
    },
    //移除影像
    removeMedia: function (index) {
      this.uploadFileList.splice(index, 1);
      this.$llsMessage({ type: "success", text: "移除影像成功" });
    },
    //确定上传影像-批量
    sureAddMediaBatch: function () {
      var _this = this;
      _this.doNum = 0;
      _this.uploadSuc = 0;
      _this.uploadNum = 0;
      _this.errList = [];
      _this.uploadErrMsg = "";
      var fileList = [];
      var allFileList = _this.uploadFileList;
      for (let k = 0; k < allFileList.length; k++) {
        if (allFileList[k]["state"] != "PASS") {
          fileList.push(allFileList[k]);
        }
      }
      //记录当前有几笔要上传的
      this.uploadFileNum = fileList.length;
      if (fileList.length == 0) {
        _this.$llsMessage({ text: "请先选择需上传的文件", type: "warn" });
        return;
      }
      //获取当前文件信息
      var fileTemp = _this.filesObj;
      var modelCode = fileTemp["modelCode"];
      //根据组合树或原子树获取busiKey
      var busiKey = _this.busiKey; //默认为原子树的
      if (_this.modelType == "GROUP") {
        // busiKey = JSON.parse(busiKey);
        busiKey = busiKey[fileTemp["modelCode"]];
      }
      if (_this.modelType == "OTHER") {
        busiKey = fileTemp["busiKey"];
      }
      if (_this.modelType == "STANDARD") {
        //2020-05-07,AMS,取busiKey["busiKey"]
        busiKey =
          busiKey["busiKey"] || fileTemp["busiId"] || fileTemp["modelDefId"];
        var modeCode = fileTemp["modelDef"] ? fileTemp["modelDef"]["code"] : "";
        modelCode = fileTemp["modeCode"] || modeCode;
      }
      _this.isAddLoading = true;
      let formData = new FormData();
      formData.append("file", fileList[_this.doNum]);
      var fileName = fileList[_this.doNum]["name"];
      _this.uploadMedia(
        modelCode,
        fileTemp["catgId"],
        busiKey,
        formData,
        fileName,
        fileList.length
      );
      var intDo = setInterval(function () {
        _this.doNum++;
        if (_this.doNum == fileList.length) {
          clearInterval(intDo);
          return;
        }
        let formData = new FormData();
        formData.append("file", fileList[_this.doNum]);
        var fileName = fileList[_this.doNum]["name"];
        _this.uploadMedia(
          modelCode,
          fileTemp["catgId"],
          busiKey,
          formData,
          fileName,
          fileList.length
        );
      }, 100);
    },
    //调用接口上传影像
    uploadMedia: function (
      modelCode,
      catgId,
      busiKey,
      formData,
      fileName,
      fileNum
    ) {
      var _this = this;
      _this.$http
        .post(
          _this.urlAddMedia +
            "?modelCode=" +
            modelCode +
            "&catgId=" +
            catgId +
            "&busiKey=" +
            busiKey +
            "&userBusiKey=" +
            (_this.userBusiKey || ""),
          formData
        )
        .then(
          (data) => {
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            //批量上传请求数加1
            _this.uploadNum++;
            if (code == 200) {
              _this.uploadSuc++;
              //将上传成功的影像打个标记
              var allFileList = _this.uploadFileList;
              for (var i = 0; i < allFileList.length; i++) {
                if (allFileList[i]["name"] == fileName) {
                  _this.uploadFileList[i]["state"] = "PASS";
                }
              }
            } else {
              _this.errList.push({
                fileName: fileName,
                remark: data.body.message || data.body.msg,
              });
            }
            //批量上传接口都调完了，再关闭按钮的loading效果
            if (_this.uploadNum == fileNum) {
              _this.isAddLoading = false;
              //提示成功的笔数
              if (_this.uploadSuc == fileNum) {
                _this.isAddMedia = false;
                _this.$llsMessage({ text: "新增成功" });
                //重新查询影像树
                _this.listModelTree();
                _this.showType = "TREE";
                _this.$emit("operate", "addSuc");
                _this.$emit("changePanel", { type: "TREE", fileId: "" });
              } else {
                _this.uploadErrMsg =
                  "上传成功" +
                  _this.uploadSuc +
                  "笔，失败" +
                  (_this.uploadNum - _this.uploadSuc) +
                  "笔，原因如下，请检查";
                //重新查询影像树
                _this.listModelTree();
                _this.showType = "TREE";
                _this.$emit("operate", "addFail");
                _this.$emit("changePanel", { type: "TREE", fileId: "" });
              }
              //与llsUploadFile同页面使用时，新增影像拖拽文件无效
              document.ondragover = function (e) {
                  e.preventDefault()
                  return false;
              }
              document.ondrop=function (e) {
                  e.preventDefault()
                  return false;
              }
            }
          },
          (response) => {
            _this.uploadNum++;
            //批量上传接口都调完了，再关闭按钮的loading效果
            if (_this.uploadNum == fileNum) {
              _this.isAddLoading = false;
              //与llsUploadFile同页面使用时，新增影像拖拽文件无效
              document.ondragover = function (e) {
                  e.preventDefault()
                  return false;
              }
              document.ondrop=function (e) {
                  e.preventDefault()
                  return false;
              }
            }
            _this.errList.push({
              fileName: fileName,
              remark: response.message || response.msg,
            });
          }
        );
    },
    //确定上传影像
    sureAddMedia: function () {
      var _this = this;
      if (!_this.uploadFileData) {
        this.addFileErr = "请先上传文件";
        return;
      }
      let formData = new FormData();
      // formData.append('file',document.getElementById("imgInput").files[0]);
      formData.append("file", _this.uploadFileData);
      //获取当前文件信息
      var fileTemp = _this.filesObj;
      var modelCode = fileTemp["modelCode"];
      //根据组合树或原子树获取busiKey
      var busiKey = _this.busiKey; //默认为原子树的
      if (_this.modelType == "GROUP") {
        // busiKey = JSON.parse(busiKey);
        busiKey = busiKey[fileTemp["modelCode"]];
      }
      if (_this.modelType == "OTHER") {
        busiKey = fileTemp["busiKey"];
      }
      if (_this.modelType == "STANDARD") {
        busiKey = fileTemp["busiId"];
        modelCode = fileTemp["modeCode"];
      }
      _this.isAddLoading = true;
      _this.$http
        .post(
          _this.urlAddMedia +
            "?modelCode=" +
            modelCode +
            "&catgId=" +
            fileTemp["catgId"] +
            "&busiKey=" +
            busiKey +
            "&userBusiKey=" +
            (_this.userBusiKey || ""),
          formData
        )
        .then(
          (data) => {
            _this.isAddLoading = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.isAddMedia = false;
              _this.$llsMessage({ text: "新增成功" });
              //重新查询影像树
              _this.listModelTree();
              _this.showType = "TREE";
              //把新增的影像添加到文件列表
              // _this.filesList.splice(index,1);
              _this.$emit("operate", "addSuc");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            } else {
              _this.addFileErr = data.body.message || data.body.msg;
              _this.$emit("operate", "addFail");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            }
          },
          (response) => {
            _this.isAddLoading = false;
            _this.addFileErr = response.message || response.msg;
          }
        );
    },
    //下载影像
    downImage: function () {
      let filesList = this.filesList;
      if(filesList.length == 0) {
          this.$llsMessage({type: "warn", text: "文件夹为空，无需下载"});
      } else {
          $("#downImageForm").submit();
      }
    },
    //下载指定影像文件
    downSpecifyImage: function () {
      var _this = this;
      if (_this.fileIdsList.length == 0) {
        _this.$llsMessage({ type: "warn", text: "未勾选记录，请检查" });
        return;
      }
      var folderName = "";
      var idsList = _this.fileIdsList;
      var filesList = _this.filesList;
      var pathsList = [];
      for (var k = 0; k < idsList.length; k++) {
        for (var i = 0; i < filesList.length; i++) {
          var curId = filesList[i]["id"];
          folderName = filesList[i]["catgName"];
          if (curId == idsList[k]) {
            var curPath = filesList[i]["path"];
            pathsList.push(curPath);
            break;
          }
        }
      }
      _this.isDownIng = true;
      _this.$http
        .post(_this.urlDownSpecifyMedia + "?folderName=" + folderName, idsList)
        .then(
          (data) => {
            _this.isDownIng = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              var zipUrl = data.data.data;
              // window.open(zipUrl, '_blank');
              // if(zipUrl.startsWith("/")) {
              //     zipUrl = zipUrl.substring(1);
              // }
              if (zipUrl.indexOf("/") == 0) {
                zipUrl = zipUrl.substring(1);
              }
              window.location.href = _this.urlHead + "" + zipUrl;
              _this.$llsMessage({ text: "下载文件成功" });
              _this.$emit("operate", "downSpecifySuc");
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "downSpecifyFail");
            }
          },
          (response) => {
            _this.isDownIng = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //发票OCR
    invOCR: function () {
      var _this = this;
      if (_this.fileIdsList.length == 0) {
        _this.$llsMessage({ type: "warn", text: "未勾选记录，请检查" });
        return;
      }
      if (!_this.urlInvOCR) {
        _this.$llsMessage({ type: "warn", text: "未配置发票OCR接口，请检查" });
        return;
      }
      if (!_this.assetId) {
        _this.$llsMessage({
          type: "warn",
          text: "OCR参数assetId未配置，请检查",
        });
        return;
      }
      _this.isOCRIng = true;
      _this.$http
        .post(_this.urlInvOCR + "?assetId=" + _this.assetId, _this.fileIdsList)
        .then(
          (data) => {
            _this.isOCRIng = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.$llsMessage({ text: "发票OCR成功" });
              //重新查询影像树
              // _this.listModelTree();
              // _this.showType = "TREE";
              _this.$emit("operate", "ocrSuc");
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "ocrFail");
            }
          },
          (response) => {
            _this.isOCRIng = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //批量删除
    batchDelete: function () {
      var _this = this;
      if (_this.fileIdsList.length == 0) {
        _this.$llsMessage({ type: "warn", text: "未勾选记录，请检查" });
        return;
      }
      if (!_this.urlBatchDeleteMedia) {
        _this.$llsMessage({ type: "warn", text: "未配置批量删除接口，请检查" });
        return;
      }
      //勾选记录中是否有已审核通过的
      var filesList = _this.filesList;
      var idsList = _this.fileIdsList;
      var errFlag = false;
      for (var i = 0; i < idsList.length; i++) {
        for (var k = 0; k < filesList.length; k++) {
          var curFileId = filesList[k]["id"];
          var curState = filesList[k]["imgCheckListStatus"];
          if (curFileId == idsList[i] && curState == "PASS") {
            errFlag = true;
            break;
          }
        }
      }
      if (errFlag) {
        _this.$llsMessage({
          type: "warn",
          text: "不能选择已审核通过记录，请检查",
        });
        return;
      }
      _this.$llsConfirm({
        text: "确定要批量删除选中文件吗？",
        title: "请确认",
        buttonSure: {
          onclick: function () {
            _this.isBatchDelIng = true;
            _this.$http
              .post(_this.urlBatchDeleteMedia, {
                fileIds: _this.fileIdsList,
                modelCode: _this.modelCode,
              })
              .then(
                (data) => {
                  _this.isBatchDelIng = false;
                  let code =
                    data.code ||
                    data.httpCode ||
                    data.body.code ||
                    data.body.httpCode;
                  if (code == 200) {
                    _this.$llsMessage({ text: "批量删除成功" });
                    //重新查询影像树
                    _this.listModelTree();
                    //把删除的影像从文件列表移除
                    // _this.filesList.splice(index,1);
                    _this.showType = "TREE";
                    _this.$emit("operate", "batchDeleteSuc");
                  } else {
                    _this.$llsAlert({
                      text: data.body.message || data.body.msg,
                    });
                    _this.$emit("operate", "batchDeleteFail");
                  }
                },
                (response) => {
                  _this.isBatchDelIng = false;
                  _this.$llsAlert({ text: response.message || response.msg });
                }
              );
          },
        },
      });
    },
    //批量调整分类
    batchAdjust: function () {
      var _this = this;
      if (_this.fileIdsList.length == 0) {
        _this.$llsMessage({ type: "warn", text: "未勾选记录，请检查" });
        return;
      }
      if (!_this.urlBatchChangeCatgs) {
        _this.$llsMessage({
          type: "warn",
          text: "未配置批量调整分类接口，请检查",
        });
        return;
      }
      //勾选记录中是否有已审核通过的
      var filesList = _this.filesList;
      var idsList = _this.fileIdsList;
      var errFlag = false;
      for (var i = 0; i < idsList.length; i++) {
        for (var k = 0; k < filesList.length; k++) {
          var curFileId = filesList[k]["id"];
          var curState = filesList[k]["imgCheckListStatus"];
          if (curFileId == idsList[i] && curState == "PASS") {
            errFlag = true;
            break;
          }
        }
      }
      if (errFlag) {
        _this.$llsMessage({
          type: "warn",
          text: "不能选择已审核通过记录，请检查",
        });
        return;
      }
      this.isChanging = false;
      this.relationCatgId = "";
      this.changeMsg = "";
      this.relationCatgObj = [];
      var modelCode = "";
      var catgName = "";
      var filesList = _this.filesList;
      for (var i = 0; i < filesList.length; i++) {
        modelCode = filesList[i]["modelCode"];
        catgName = filesList[i]["catgName"];
      }
      this.curChangeObj = ["当前影像所属分类为：" + catgName];
      _this.$http
        .post(
          _this.urlListChangeMedia + "?modelCode=" + modelCode + "&catgId=-1"
        )
        .then(
          (data) => {
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              this.changeFlag = 2; //批量调整分类
              this.isShowChange = true;
              var resData =
                data.data.modelList ||
                (data.data.data ? data.data.data.modelList : []) ||
                [];
              resData = resData.length == 0 ? data.data.data || [] : resData;
              var relaArr = [];
              for (let i = 0; i < resData.length; i++) {
                relaArr.push({
                  dispalyName: resData[i]["catgName"],
                  dictParam: resData[i]["catgId"],
                });
              }
              this.relationCatgObj = relaArr;
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
            }
          },
          (response) => {
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //批量审核
    batchCheck: function (status) {
      var _this = this;
      if (_this.fileIdsList.length == 0) {
        _this.$llsMessage({ type: "warn", text: "未勾选记录，请检查" });
        return;
      }
      var filesList = _this.filesList;
      var relAstNo = "";
      for (var i = 0; i < filesList.length; i++) {
        relAstNo = filesList[i]["busiKey"];
      }
      _this.batchCheckIng = true;
      _this.$http
        .post(
          _this.urlBatchCheckMedia +
            "?relAstNo=" +
            relAstNo +
            "&mediaCheckStatus=" +
            status,
          _this.fileIdsList
        )
        .then(
          (data) => {
            _this.batchCheckIng = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.$llsMessage({ text: "批量审核成功" });
              //重新查询影像树
              审核通过
              _this.showType = "TREE";
              _this.$emit("operate", "batchCheckSuc");
              // if(status == "PASS") {
              //     //修改表格内审核状态
              //     for(let k = 0;k<filesList.length;k++) {
              //         var curFileId = filesList[k]["id"];
              //         var curState = filesList[k]["imgCheckListStatus"];
              //         if(_this.fileIdsList.includes(curFileId)) {
              //             filesList[k]["imgCheckListStatus"] = "PASS";
              //             Vue.set(_this.filesList[k],"imgCheckListStatus","PASS");
              //         }
              //     }
              // }
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "batchCheckFail");
            }
          },
          (response) => {
            _this.batchCheckIng = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //勾选所有
    chooseAll: function () {
      var _this = this;
      _this.allFlag = !_this.allFlag;
      _this.fileIdsList = [];
      if (_this.allFlag) {
        var filesList = _this.filesList;
        for (var i = 0; i < filesList.length; i++) {
          var id = filesList[i]["id"];
          // var state = filesList[i]["imgCheckListStatus"];
          // if(state != "PASS") {
          _this.fileIdsList.push(id);
          // }
        }
      }
    },
    //勾选单行
    chooseTr: function (fileId, state) {
      var _this = this;
      // if(state == "PASS") {
      //     return;
      // }
      var curIndex = -1;
      var fileIdsList = _this.fileIdsList;
      for (var i = 0; i < fileIdsList.length; i++) {
        if (fileIdsList[i] == fileId) {
          curIndex = i;
          break;
        }
      }
      if (curIndex != -1) {
        //已有记录，要剔除
        fileIdsList.splice(curIndex, 1);
      } else {
        //没有记录，要新增
        fileIdsList.push(fileId);
      }
      _this.fileIdsList = fileIdsList;
    },
    //当前行是否勾选
    fileIdInList: function (fileId) {
      var _this = this;
      var fileIdsList = _this.fileIdsList;
      var fileIdsStr = fileIdsList.join(",");
      var indexNum = fileIdsStr.indexOf(fileId);
      var flag = indexNum == -1 ? false : true;
      return flag;
    },
    //=========================================================================
    //==========================   图片操作  ==================================
    //=========================================================================
    //判断当前是否能上一张/下一张
    getPageFalg: function (flag) {
      var _this = this;
      //找出当前展示的文件项
      var showType = _this.showType;
      var curItem = "";
      if (showType == "JPG") {
        curItem = _this.imgArr[_this.imageIndex - 1];
      } else {
        curItem = _this.pdfArr[_this.pdfIndex - 1];
      }
      //找出当前展示的文件项在img,pdf集合中的序号
      var curIndex = 0;
      var imgPdf = _this.imgAndPdfArr;
      for (var i = 0; i < imgPdf.length; i++) {
        if (imgPdf[i]["id"] == curItem["id"]) {
          curIndex = i;
          break;
        }
      }
      if (curIndex == 0) {
        //当前为第一张
        _this.showImageTips = flag;
      } else if (curIndex == imgPdf.length - 1) {
        //当前为最后一张
        _this.showImageTips = flag;
      } else {
        _this.showImageTips = false;
      }
      _this.tipIndex = curIndex;
    },
    //上一张
    leftImg: function () {
      var _this = this;
      //当前展示的照片序号为1，表示第一张，不可再上一张
      if (_this.tipIndex == 0) {
        return;
      }
      //找出当前展示的文件项
      var showType = _this.showType;
      var curItem = "";
      var preItem = "" // 视图中展示的文件项
      if (showType == "JPG") {
        curItem = _this.imgArr[_this.imageIndex - 1];
        preItem = _this.imgArr[_this.imageIndex - 2];
      } else {
        curItem = _this.pdfArr[_this.pdfIndex - 1];
        preItem = _this.pdfArr[_this.pdfIndex - 2];
      }

      _this.mediaCheckStatus = preItem.mediaCheckStatus && JSON.parse(preItem.mediaCheckStatus)

      //找出当前展示的文件项在img,pdf集合中的序号
      var curIndex = 0;
      var imgPdf = _this.imgAndPdfArr;
      for (var i = 0; i < imgPdf.length; i++) {
        if (imgPdf[i]["id"] == curItem["id"]) {
          curIndex = i;
          break;
        }
      }
      //找出上一张的文件类型
      var nextItem = imgPdf[curIndex - 1];
      var nextFileType = nextItem["fileType"];
      if (nextFileType == "I") {
        var nextImgIndex = 0;
        var imgObj = _this.imgArr;
        for (var n = 0; n < imgObj.length; n++) {
          if (imgObj[n]["id"] == nextItem["id"]) {
            nextImgIndex = n;
            break;
          }
        }
        _this.imageIndex = nextImgIndex + 1;
        _this.showType = "JPG";
        _this.showJPG(_this.imageIndex);
      } else {
        var nextPdfIndex = 0;
        var pdfArr = _this.pdfArr;
        for (var n = 0; n < pdfArr.length; n++) {
          if (pdfArr[n]["id"] == nextItem["id"]) {
            nextPdfIndex = n;
            break;
          }
        }
        _this.pdfIndex = nextPdfIndex + 1;
        _this.showType = "PDF";
        _this.showPDF(_this.pdfIndex, "TREE", true);
      }
      //判断当前翻页标志
      _this.getPageFalg(true);
    },
    //下一张
    rightImg: function () {
      var _this = this;
      //当前展示的照片序号为照片数组长度，表示最后张，不可再下一张
      if (_this.tipIndex == _this.imgAndPdfArr.length - 1) {
        return;
      }
      //找出当前展示的文件项
      var showType = _this.showType;
      var curItem = "";
      if (showType == "JPG") {
        curItem = _this.imgArr[_this.imageIndex - 1];
      } else {
        curItem = _this.pdfArr[_this.pdfIndex - 1];
      }

      //找出当前展示的文件项在img,pdf集合中的序号
      var curIndex = 0;
      var imgPdf = _this.imgAndPdfArr;
      for (var i = 0; i < imgPdf.length; i++) {
        if (imgPdf[i]["id"] == curItem["id"]) {
          curIndex = i;
          break;
        }
      }
      //找出下一张的文件类型
      var nextItem = imgPdf[curIndex + 1];
      var nextFileType = nextItem["fileType"];

      //如果当前影像树配置的有审核功能，且当前文件状态为待审核，则下一张时，需默认审核通过
      if (showType == "JPG") {
        var viewItem = _this.imgArr[_this.imageIndex]
        _this.mediaCheckStatus = viewItem && JSON.parse(viewItem['mediaCheckStatus'])

        var mediaCheckStatus = _this.imgArr[_this.imageIndex - 1]['mediaCheckStatus'] && JSON.parse(_this.imgArr[_this.imageIndex - 1]['mediaCheckStatus'])
        var curFileCheckStatus = mediaCheckStatus.dictParam
        if (_this.isNeedCheck && curFileCheckStatus == "TOCHECK") {
          _this.checkImage("PASS", "I", true);
        }
      } else {
        var viewItem = _this.pdfArr[_this.pdfIndex]
        _this.mediaCheckStatus = viewItem && JSON.parse(viewItem['mediaCheckStatus'])

        var mediaCheckStatus = _this.pdfArr[_this.pdfIndex - 1]['mediaCheckStatus'] && JSON.parse(_this.pdfArr[_this.pdfIndex - 1]['mediaCheckStatus'])
        _this.mediaCheckStatus = mediaCheckStatus
        var curFileCheckStatus = mediaCheckStatus.dictParam
        if (_this.isNeedCheck && curFileCheckStatus == "TOCHECK") {
          _this.checkImage("PASS", "P", true);
        }
      }

      if (nextFileType == "I") {
        var nextImgIndex = 0;
        var imgObj = _this.imgArr;
        for (var n = 0; n < imgObj.length; n++) {
          if (imgObj[n]["id"] == nextItem["id"]) {
            nextImgIndex = n;
            break;
          }
        }
        _this.imageIndex = nextImgIndex + 1;
        _this.showType = "JPG";
        _this.showJPG(_this.imageIndex);
      } else {
        var nextPdfIndex = 0;
        var pdfArr = _this.pdfArr;
        for (var n = 0; n < pdfArr.length; n++) {
          if (pdfArr[n]["id"] == nextItem["id"]) {
            nextPdfIndex = n;
            break;
          }
        }
        _this.pdfIndex = nextPdfIndex + 1;
        _this.showType = "PDF";
        _this.showPDF(_this.pdfIndex, "TREE", true);
      }
      //判断当前翻页标志
      _this.getPageFalg(true);
    },
    //放大
    plusImage: function () {
      //先获取原始高宽
      let _this = this;
      let $Img = $("#in_img_box" + _this.inittime);
      // let $Img = $("#in_img_box");
      let oldW = $Img.width();
      let oldH = $Img.height();
      let showW = oldW + oldW * 0.15;
      let showH = oldH + oldH * 0.15;
      showW = Math.round(showW);
      showH = Math.round(showH);
      $Img.css({ width: showW, height: showH });
      //调整红框大小
      this.redScale = showW / this.oldImageW;
      this.setRedBox();
      //调整OCR区域大小
      _this.ocrWidth = showW;
      _this.ocrHeight = showH;
      _this.isMin = false;
      //旋转之后要判断位置
      if (_this.rotateDeg == 0) {
        $Img.css({ marginLeft: 0 });
        $Img.css({ marginTop: 0 });
      } else if (_this.rotateDeg == 90) {
        $Img.css({ marginLeft: showH });
        $Img.css({ marginTop: 0 });
      } else if (_this.rotateDeg == 180) {
        $Img.css({ marginLeft: showW });
        $Img.css({ marginTop: showH });
        $Img.css({ marginBottom: -showH });
      } else if (_this.rotateDeg == 270) {
        $Img.css({ marginLeft: 0 });
        $Img.css({ marginTop: showW });
      }
    },
    //缩小
    minusImage: function () {
      //先获取原始高宽
      let _this = this;
      let $Img = $("#in_img_box" + _this.inittime);
      // let $Img = $("#in_img_box");
      let oldW = $Img.width();
      let oldH = $Img.height();
      let showW = oldW - oldW * 0.15;
      let showH = oldH - oldH * 0.15;
      showW = Math.round(showW);
      showH = Math.round(showH);
      //高度到达200就不可再缩小
      if (showH > 200) {
        //调整红框大小
        this.redScale = showW / this.oldImageW;
        this.setRedBox();
        $Img.css({ width: showW, height: showH });
        //调整OCR区域大小
        _this.ocrWidth = showW;
        _this.ocrHeight = showH;
      }
      //判断是否还能再缩小
      let nextH = showH - showH * 0.15;
      _this.isMin = nextH > 200 ? false : true;
      //旋转之后要判断位置
      if (_this.rotateDeg == 0) {
        $Img.css({ marginLeft: 0 });
        $Img.css({ marginTop: 0 });
      } else if (_this.rotateDeg == 90) {
        $Img.css({ marginLeft: showH });
        $Img.css({ marginTop: 0 });
      } else if (_this.rotateDeg == 180) {
        $Img.css({ marginLeft: showW });
        $Img.css({ marginTop: showH });
        $Img.css({ marginBottom: -showH });
      } else if (_this.rotateDeg == 270) {
        $Img.css({ marginLeft: 0 });
        $Img.css({ marginTop: showW });
      }
    },
    //放大-PDF
    plusPdf: function () {
      var _this = this;
      this.isMin = false;
      var pdfWidth = this.pdfWidth;
      pdfWidth = pdfWidth + 4;
      this.pdfWidth = pdfWidth;
      this.pdfscale = pdfWidth / 100;
      setTimeout(function () {
        var domMedPdfBox = _this.$refs.medPdfBox;
        var canPdfs = domMedPdfBox.getElementsByTagName("canvas");
        var totalH = 0;
        var totalW = 0;
        for (var i = 1; i < canPdfs.length; i++) {
          totalW = canPdfs[i]["clientWidth"];
          var curH = canPdfs[i]["clientHeight"];
          totalH += curH;
        }
        _this.ocrWidth = totalW;
        _this.ocrHeight = totalH;
      }, 2000);
      _this.setRedBox();
    },
    //缩小-PDF
    minusPdf: function () {
      var _this = this;
      var pdfWidth = this.pdfWidth;
      if (pdfWidth < 50) {
        this.isMin = true;
        return;
      }
      pdfWidth = pdfWidth - 4;
      this.pdfWidth = pdfWidth;
      this.pdfscale = pdfWidth / 100;
      setTimeout(function () {
        var domMedPdfBox = _this.$refs.medPdfBox;
        var canPdfs = domMedPdfBox.getElementsByTagName("canvas");
        var totalH = 0;
        var totalW = 0;
        for (var i = 1; i < canPdfs.length; i++) {
          totalW = canPdfs[i]["clientWidth"];
          var curH = canPdfs[i]["clientHeight"];
          totalH += curH;
        }
        _this.ocrWidth = totalW;
        _this.ocrHeight = totalH;
      }, 2000);
      _this.setRedBox();
    },
    //旋转
    repeatImage: function () {
      var _this = this;
      let $Img = $("#in_img_box" + _this.inittime);
      // let $Img = $("#in_img_box");
      let ImgW = $Img.width();
      let ImgH = $Img.height();
      this.rotateDeg = (this.rotateDeg + 90) % 360;
      switch (this.rotateDeg) {
        case 0:
          $Img.css({ marginLeft: 0 });
          $Img.css({ marginTop: 0 });
          break;
        case 90:
          $Img.css({ marginLeft: ImgH });
          $Img.css({ marginTop: 0 });
          break;
        case 180:
          $Img.css({ marginLeft: ImgW });
          $Img.css({ marginTop: ImgH });
          $Img.css({ marginBottom: -ImgH });
          break;
        case 270:
          $Img.css({ marginLeft: 0 });
          $Img.css({ marginTop: ImgW });
          break;
      }
      if (this.rotateDeg !== this.oldRotateDeg) {
        this.imageSaveActive = true;
        //旋转之后禁用OCR
        this.ocrDis = true;
      } else {
        this.imageSaveActive = false;
        //恢复OCR
        this.ocrDis = false;
      }
    },
    //保存
    saveImage: function () {
      var _this = this;
      if (!_this.imageSaveActive) {
        return;
      }
      //获取当前文件信息
      var fileTemp = _this.imgArr[_this.imageIndex - 1];
      var rotateNum = _this.rotateDeg;
      _this.layerLoading = true;
      //兼容STANDARD模式
      var modelCode = fileTemp["modelCode"] || fileTemp["modeCode"];
      _this.$http
        .post(
          _this.rotateSaveMedia +
            "?modelCode=" +
            modelCode +
            "&fileId=" +
            fileTemp["id"] +
            "&rotate=" +
            rotateNum
        )
        .then(
          (data) => {
            _this.layerLoading = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.$llsMessage({ text: "保存成功" });
              //重新查询影像树
              _this.listModelTree();
              _this.$emit("operate", "saveSuc");
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "saveFail");
            }
          },
          (response) => {
            _this.layerLoading = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //全屏
    aloneImage: function () {
      this.isShowBoxPdf = false;
      var _this = this;
      var clientH = "";
      //IE，Firefox等用documentElement获取网页正文高度，Chrome用body
      var bodyH = document.body.clientHeight;
      var domH = document.documentElement.clientHeight;
      //如果授受的参数没值，则从父页面取
      var browser = {
        versions: (function () {
          var u = navigator.userAgent;
          return {
            //移动终端浏览器版本信息
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
          };
        })(),
      };
      if (browser.versions.webKit) {
        clientH = domH > bodyH ? domH : bodyH;
      } else {
        clientH = document.documentElement.clientHeight;
      }
      //克隆对象，并append到body上
      var node = document.getElementById("alone_box").cloneNode(true);
      document.body.appendChild(node);
      $(node).css("height", clientH);
      //单独弹框上的图片预览
      var _inbox = $(node).find("div[name='aloneInBox']");
      var _outbox = $(node).find("div[name='aloneOutBox']");
      var _aloneTit = $(node).find("div[name='aloneTitle']");
      var timestamp = new Date().getTime();
      var imageAll = this.imgArr;
      var nowImageUrl = imageAll[this.imageIndex - 1]["path"];
      // TODO
      // nowImageUrl = "https://wecagw-uat.qhhrly.cn/uploadFiles/WEC-20190611/172819966/20190611192550373.jpg?timestamp=1565164819668";
      _inbox.css(
        "background",
        "url('" +
          this.urlHead +
          "" +
          nowImageUrl +
          "?version=" +
          timestamp +
          "') no-repeat"
      );
      //设置图片高宽
      var img = new Image();
      img.src = this.urlHead + "" + nowImageUrl + "?version=" + timestamp;
      var aloneShowH = "";
      var aloneShowW = "";
      img.onload = function () {
        aloneShowH = img.height;
        aloneShowW = img.width;
        var outW = _outbox.width() - 20;
        //计算显示比例
        var outH = (outW / aloneShowW) * aloneShowH;
        aloneShowW = Math.round(outW);
        aloneShowH = Math.round(outH);
        _inbox.css({
          width: aloneShowW,
          height: aloneShowH,
          marginLeft: 0,
          marginTop: 0,
        });
      };
      //设置当前弹框上的图片是第几张
      var nowIndex = 0;
      for (let i = 0; i < imageAll.length; i++) {
        if (nowImageUrl == imageAll[i]["path"]) {
          _aloneTit.text(imageAll[i]["fileRename"]);
          nowIndex = i + 1;
          break;
        }
      }
      _inbox.attr("data-index", nowIndex);
      //设置上一张/下一张
      if (nowIndex == 1) {
        $(node).find("div[name='toBack']").addClass("alone-dis");
        $(node).find("div[name='toBack']").attr("title", "");
      } else {
        $(node).find("div[name='toBack']").removeClass("alone-dis");
        $(node).find("div[name='toBack']").attr("title", "上一张");
      }
      if (nowIndex == imageAll.length) {
        $(node).find("div[name='toNext']").addClass("alone-dis");
        $(node).find("div[name='toNext']").attr("title", "");
      } else {
        $(node).find("div[name='toNext']").removeClass("alone-dis");
        $(node).find("div[name='toNext']").attr("title", "下一张");
      }
      //显示弹框
      $(node).css({ display: "block" });
      //全屏/半屏
      $(node)
        .find("div[id='alone_size']")
        .click(function () {
          if ($(this).hasClass("alone-toall")) {
            $(this)
              .removeClass("alone-toall")
              .addClass("alone-tomid")
              .attr("title", "缩小");
            //全屏
            $(node).css({
              width: "calc(100%)",
              height: "calc(100%)",
              top: 0,
              left: 0,
            });
            _outbox.css({ width: "calc(100%)" });
          } else {
            $(this)
              .removeClass("alone-tomid")
              .addClass("alone-toall")
              .attr("title", "放大");
            //半屏
            $(node).css({ width: "60%", height: clientH });
            _outbox.css({ width: "100%" });
          }
        });
      //关闭
      $(node)
        .find("div[id='alone_close']")
        .click(function () {
          $(node).remove();
        });
      //上一张
      $(node)
        .find("div[id='to_back']")
        .click(function () {
          var nowNodeImgIndex = Number(_inbox.attr("data-index"));
          if (nowNodeImgIndex == 1) {
            //已经是第一张
            return;
          }
          //待显示的图片
          var toShowIndex = nowNodeImgIndex - 1;
          var toShowImg =
            _this.urlHead + "" + imageAll[toShowIndex - 1]["path"];
          _aloneTit.text(imageAll[toShowIndex - 1]["fileRename"]);
          _inbox.attr("data-index", toShowIndex); //设置当前图片序号
          var timestamp = new Date().getTime();
          _inbox.css(
            "background",
            "url('" + toShowImg + "?version=" + timestamp + "') no-repeat"
          );
          _inbox.attr("data-rotate", 0);
          _inbox
            .removeClass()
            .addClass("rotate-scale0-0")
            .addClass("alone-in-box");
          //设置图片高宽
          var img = new Image();
          img.src = toShowImg + "?version=" + timestamp;
          img.onload = function () {
            aloneShowH = img.height;
            aloneShowW = img.width;
            var outW = _outbox.width() - 20;
            //计算显示比例
            var outH = (outW / aloneShowW) * aloneShowH;
            aloneShowW = Math.round(outW);
            aloneShowH = Math.round(outH);
            _inbox.css({
              width: aloneShowW,
              height: aloneShowH,
              marginLeft: 0,
              marginTop: 0,
            });
          };
          _inbox.attr("title", imageAll[toShowIndex - 1]["fileRename"]); //设置title
          //判断是否可以上一张
          if (toShowIndex == 1) {
            $(this).addClass("alone-dis");
            $(this).attr("title", "");
            //第一张的提示
            $(node).find("div[name='toMsg']").text("已经是第一张").show();
            setTimeout(function () {
              $(node).find("div[name='toMsg']").hide();
            }, 2000);
          } else {
            $(this).removeClass("alone-dis");
            $(this).attr("title", "上一张");
          }
          //判断是否可以下一张
          if (toShowIndex == imageAll.length) {
            $(node).find("div[name='toNext']").addClass("alone-dis");
            $(node).find("div[name='toNext']").attr("title", "");
          } else {
            $(node).find("div[name='toNext']").removeClass("alone-dis");
            $(node).find("div[name='toNext']").attr("title", "下一张");
          }
        });
      //下一张
      $(node)
        .find("div[id='to_next']")
        .click(function () {
          var nowNodeImgIndex = Number(_inbox.attr("data-index"));
          if (nowNodeImgIndex == imageAll.length) {
            //已经是最后一张
            return;
          }
          //待显示的图片
          var toShowIndex = nowNodeImgIndex + 1;
          var toShowImg =
            _this.urlHead + "" + imageAll[toShowIndex - 1]["path"];
          _aloneTit.text(imageAll[toShowIndex - 1]["fileRename"]);
          _inbox.attr("data-index", toShowIndex); //设置当前图片序号
          var timestamp = new Date().getTime();
          _inbox.css(
            "background",
            "url('" + toShowImg + "?version=" + timestamp + "') no-repeat"
          );
          _inbox.attr("data-rotate", 0);
          _inbox
            .removeClass()
            .addClass("rotate-scale0-0")
            .addClass("alone-in-box");
          //设置图片高宽
          var img = new Image();
          img.src = toShowImg + "?version=" + timestamp;
          img.onload = function () {
            aloneShowH = img.height;
            aloneShowW = img.width;
            var outW = _outbox.width() - 20;
            //计算显示比例
            var outH = (outW / aloneShowW) * aloneShowH;
            aloneShowW = Math.round(outW);
            aloneShowH = Math.round(outH);
            _inbox.css({
              width: aloneShowW,
              height: aloneShowH,
              marginLeft: 0,
              marginTop: 0,
            });
          };
          _inbox.attr("title", imageAll[toShowIndex - 1]["fileRename"]); //设置title
          //判断是否可以上一张
          if (toShowIndex == 1) {
            $(node).find("div[name='toBack']").addClass("alone-dis");
            $(node).find("div[name='toBack']").attr("title", "");
          } else {
            $(node).find("div[name='toBack']").removeClass("alone-dis");
            $(node).find("div[name='toBack']").attr("title", "上一张");
          }
          //判断是否可以下一张
          if (toShowIndex == imageAll.length) {
            $(this).addClass("alone-dis");
            $(this).attr("title", "");
            //最后一张的提示
            $(node).find("div[name='toMsg']").text("最后一张了").show();
            setTimeout(function () {
              $(node).find("div[name='toMsg']").hide();
            }, 2000);
          } else {
            $(this).removeClass("alone-dis");
            $(this).attr("title", "下一张");
          }
        });
      //放大
      $(node)
        .find("div[id='alone_big']")
        .click(function () {
          //先获取原始高宽
          var oldW = _inbox.width();
          var oldH = _inbox.height();
          aloneShowW = oldW + oldW * 0.15;
          aloneShowW = Math.round(aloneShowW);
          aloneShowH = oldH + oldH * 0.15;
          aloneShowH = Math.round(aloneShowH);
          _inbox.css({ width: aloneShowW, height: aloneShowH });
          var cloneRepeatNum = _inbox.attr("data-rotate"); //旋转次数
          if (cloneRepeatNum == 0) {
            _inbox.css({ marginLeft: 0, marginTop: 0 });
          }
          if (cloneRepeatNum == 1) {
            let mb = -Number(aloneShowW);
            _inbox.css({
              marginLeft: aloneShowH,
              marginTop: 0,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 2) {
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: aloneShowW,
              marginTop: aloneShowH,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 3) {
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: 0,
              marginTop: aloneShowW,
              marginBottom: mb,
            });
          }
        });
      //缩小
      $(node)
        .find("div[id='alone_small']")
        .click(function () {
          //先获取原始高宽
          var oldW = _inbox.width();
          var oldH = _inbox.height();
          aloneShowW = oldW - oldW * 0.15;
          aloneShowW = Math.round(aloneShowW);
          aloneShowH = oldH - oldH * 0.15;
          aloneShowH = Math.round(aloneShowH);
          _inbox.css({ width: aloneShowW, height: aloneShowH });
          var cloneRepeatNum = _inbox.attr("data-rotate"); //旋转次数
          if (cloneRepeatNum == 0) {
            _inbox.css({ marginLeft: 0, marginTop: 0 });
          }
          if (cloneRepeatNum == 1) {
            let mb = -Number(aloneShowW);
            _inbox.css({
              marginLeft: aloneShowH,
              marginTop: 0,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 2) {
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: aloneShowW,
              marginTop: aloneShowH,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 3) {
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: 0,
              marginTop: aloneShowW,
              marginBottom: mb,
            });
          }
        });
      //旋转
      $(node)
        .find("div[id='alone_rotate']")
        .click(function () {
          var cloneRepeatNum = _inbox.attr("data-rotate"); //旋转次数
          cloneRepeatNum++;
          if (cloneRepeatNum == 4) {
            cloneRepeatNum = 0;
          }
          _inbox.attr("data-rotate", cloneRepeatNum);
          if (cloneRepeatNum == 0) {
            _inbox
              .removeClass()
              .addClass("rotate-scale0-0")
              .addClass("alone-in-box");
            _inbox.css({ marginLeft: 0, marginTop: 0 });
          }
          if (cloneRepeatNum == 1) {
            _inbox
              .removeClass()
              .addClass("rotate-scale90-0")
              .addClass("alone-in-box");
            let mb = -Number(aloneShowW);
            _inbox.css({
              marginLeft: aloneShowH,
              marginTop: 0,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 2) {
            _inbox
              .removeClass()
              .addClass("rotate-scale180-0")
              .addClass("alone-in-box");
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: aloneShowW,
              marginTop: aloneShowH,
              marginBottom: mb,
            });
          }
          if (cloneRepeatNum == 3) {
            _inbox
              .removeClass()
              .addClass("rotate-scale270-0")
              .addClass("alone-in-box");
            let mb = -Number(aloneShowH);
            _inbox.css({
              marginLeft: 0,
              marginTop: aloneShowW,
              marginBottom: mb,
            });
          }
        });
      //外部打开
      $(node)
        .find("div[id='alone_out']")
        .click(function () {
          var nowNodeImgIndex = Number(_inbox.attr("data-index"));
          var toOpenImg =
            _this.urlHead + "" + imageAll[nowNodeImgIndex - 1]["path"];
          window.open(toOpenImg, "_blank");
        });
      //拖动
      $(node)
        .find("div[name='moveBlock']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var oEvent = ev || event; //判断浏览器兼容
          var clickX = oEvent.clientX;
          var clickY = oEvent.clientY;
          var nodeLeft = $(node).position().left;
          var nodetTop = $(node).position().top;
          var relX = clickX - nodeLeft;
          var relY = clickY - nodetTop;
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var moveY = oEvent.clientY;
            var showLeft = moveX - relX;
            var showTop = moveY - relY;
            $(node).css({ top: showTop, left: showLeft });
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //上边框方向拉伸
      $(node)
        .find("div[name='resizeTop']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeTop = $(node).position().top;
          var nodeHeight = $(node).height(); //弹出框的高度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveY = oEvent.clientY;
            $(node).css({ top: moveY });
            var moveH = moveY - nodeTop;
            $(node).css({ height: nodeHeight - moveH });
            _outbox.css({ height: nodeHeight - moveH - 45 });
            //_inbox.css({height:nodeHeight-moveH-95});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //下边框方向拉伸
      $(node)
        .find("div[name='resizeBottom']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeTop = $(node).position().top; //上边框距离屏幕上边的宽度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveY = oEvent.clientY;
            var newH = moveY - nodeTop;
            $(node).css({ height: newH });
            _outbox.css({ height: newH - 45 });
            //_inbox.css({height:newH-95});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //左边框方向拉伸
      $(node)
        .find("div[name='resizeLeft']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeLeft = $(node).position().left;
          var nodeWidth = $(node).width(); //弹出框的宽度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            $(node).css({ left: moveX });
            var moveW = moveX - nodeLeft;
            $(node).css({ width: nodeWidth - moveW });
            _outbox.css({ width: nodeWidth - moveW - 8 });
            //_inbox.css({width:nodeWidth-moveW});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //右边框方向拉伸
      $(node)
        .find("div[name='resizeRight']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeLeft = $(node).position().left; //左边框距离屏幕左边的宽度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var newW = moveX - nodeLeft;
            $(node).css({ width: newW });
            _outbox.css({ width: newW - 8 });
            //_inbox.css({width:newW});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //左上角方向拉伸
      $(node)
        .find("div[name='leftTop']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeLeft = $(node).position().left;
          var nodeWidth = $(node).width(); //弹出框的宽度
          var nodeTop = $(node).position().top;
          var nodeHeight = $(node).height(); //弹出框的高度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var moveY = oEvent.clientY;
            $(node).css({ left: moveX, top: moveY });
            var moveW = moveX - nodeLeft;
            var moveH = moveY - nodeTop;
            $(node).css({
              width: nodeWidth - moveW,
              height: nodeHeight - moveH,
            });
            _outbox.css({
              width: nodeWidth - moveW - 8,
              height: nodeHeight - moveH - 45,
            });
            //_inbox.css({width:nodeWidth-moveW,height:nodeHeight-moveH-95});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //左下角方向拉伸
      $(node)
        .find("div[name='leftBottom']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeTop = $(node).position().top; //上边框距离屏幕上边的宽度
          var nodeLeft = $(node).position().left;
          var nodeWidth = $(node).width(); //弹出框的宽度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var moveY = oEvent.clientY;
            var newH = moveY - nodeTop;
            var moveW = moveX - nodeLeft;
            $(node).css({
              height: newH,
              width: nodeWidth - moveW,
              left: moveX,
            });
            _outbox.css({ height: newH - 45, width: nodeWidth - moveW - 8 });
            //_inbox.css({height:newH-95,width:nodeWidth-moveW});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //右上角方向拉伸
      $(node)
        .find("div[name='rightTop']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeTop = $(node).position().top;
          var nodeLeft = $(node).position().left; //左边框距离屏幕左边的宽度
          var nodeHeight = $(node).height(); //弹出框的高度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var moveY = oEvent.clientY;
            $(node).css({ top: moveY });
            var moveH = moveY - nodeTop;
            var newW = moveX - nodeLeft;
            $(node).css({ height: nodeHeight - moveH, width: newW });
            _outbox.css({ height: nodeHeight - moveH - 45, width: newW - 8 });
            //_inbox.css({height:nodeHeight-moveH-95,width:newW});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
      //右下角方向拉伸
      $(node)
        .find("div[name='rightBottom']")
        .mousedown(function (ev) {
          //鼠标按下
          //获取当前点击处相对于弹框的坐标位置
          var nodeLeft = $(node).position().left; //左边框距离屏幕左边的宽度
          var nodeTop = $(node).position().top; //上边框距离屏幕上边的宽度
          document.onmousemove = function (ev) {
            //鼠标移动
            var oEvent = ev || event; //判断浏览器兼容
            var moveX = oEvent.clientX;
            var moveY = oEvent.clientY;
            var newW = moveX - nodeLeft;
            var newH = moveY - nodeTop;
            $(node).css({ width: newW, height: newH });
            _outbox.css({ width: newW - 8, height: newH - 45 });
            //_inbox.css({width:newW,height:newH-95});
          };
          document.onmouseup = function () {
            //当鼠标松开后关闭移动事件和自身事件
            document.onmousemove = null;
            document.onmouseup = null;
          };
          return false;
        });
    },
    //调整关联编号
    changeUnionCode: function (changeType) {
      var _this = this;
      this.isChanging = false;
      this.relationCatgId = "";
      this.changeMsg = "";
      this.relationCatgObj = [];
      //记录当前调整的类型
      this.relationType = changeType;

      // var fileTemp = _this.imgArr[_this.imageIndex-1];
      var fileTemp = "";
      //获取当前文件信息
      if (changeType == "V") {
        fileTemp = _this.videoArr[_this.videoIndex - 1];
      } else if (changeType == "P") {
        fileTemp = _this.pdfArr[_this.pdfIndex - 1];
      } else {
        fileTemp = _this.imgArr[_this.imageIndex - 1];
      }

      this.curChangeObj = ["当前影像所属分类为：" + fileTemp["catgName"]];
      _this.layerLoading = true;
      _this.$http
        .post(
          _this.urlListChangeMedia +
            "?modelCode=" +
            fileTemp["modelCode"] +
            "&catgId=-1"
        )
        .then(
          (data) => {
            _this.layerLoading = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              this.changeFlag = 1; //单独调整分类
              this.isShowChange = true;
              var resData =
                data.data.modelList ||
                (data.data.data ? data.data.data.modelList : []) ||
                [];
              resData = resData.length == 0 ? data.data.data || [] : resData;
              var relaArr = [];
              for (let i = 0; i < resData.length; i++) {
                relaArr.push({
                  dispalyName: resData[i]["catgName"],
                  dictParam: resData[i]["catgId"],
                });
              }
              this.relationCatgObj = relaArr;
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
            }
          },
          (response) => {
            _this.layerLoading = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //关闭调整关联编号
    toCloseChange: function () {
      this.isShowChange = false;
    },
    //确定调整关联编号
    toSureChange: function () {
      if (this.changeFlag == 1) {
        this.changeByOne();
      } else if (this.changeFlag == 2) {
        this.changeByBatch();
      }
    },
    //单独调整分类
    changeByOne: function () {
      var _this = this;
      var relationCatgId = _this.relationCatgId || ""; //目标分类
      if (!relationCatgId) {
        _this.changeMsg = "请选择目标分类";
        return;
      }
      var relationType = this.relationType;
      var fileTemp = "";
      //获取当前文件信息
      if (relationType == "V") {
        fileTemp = _this.videoArr[_this.videoIndex - 1];
      } else if (relationType == "P") {
        fileTemp = _this.pdfArr[_this.pdfIndex - 1];
      } else {
        fileTemp = _this.imgArr[_this.imageIndex - 1];
      }
      _this.isChanging = true;
      _this.$http
        .post(
          _this.urlChangeMedia +
            "?modelCode=" +
            fileTemp["modelCode"] +
            "&fileId=" +
            fileTemp["id"] +
            "&catgId=" +
            fileTemp["catgId"] +
            "&relationCatgId=" +
            relationCatgId
        )
        .then(
          (data) => {
            _this.isChanging = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.isShowChange = false;
              _this.$llsMessage({ text: "调整分类成功" });
              _this.showType = "TREE";
              //重新查询影像树
              _this.listModelTree();
              _this.$emit("operate", "changeSuc");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "changeFail");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            }
          },
          (response) => {
            _this.isChanging = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //批量调整分类
    changeByBatch: function () {
      var _this = this;
      var relationCatgId = _this.relationCatgId || ""; //目标分类
      if (!relationCatgId) {
        _this.changeMsg = "请选择目标分类";
        return;
      }
      var modelCode = "";
      var filesList = _this.filesList;
      for (var i = 0; i < filesList.length; i++) {
        modelCode = filesList[i]["modelCode"];
      }
      _this.isChanging = true;
      _this.$http
        .post(
          _this.urlBatchChangeCatgs +
            "?modelCode=" +
            modelCode +
            "&relationCatgId=" +
            relationCatgId,
          _this.fileIdsList
        )
        .then(
          (data) => {
            _this.isChanging = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.isShowChange = false;
              _this.$llsMessage({ text: "调整分类成功" });
              _this.showType = "TREE";
              //重新查询影像树
              _this.listModelTree();
              _this.$emit("operate", "changeSuc");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "changeFail");
              _this.$emit("changePanel", { type: "TREE", fileId: "" });
            }
          },
          (response) => {
            _this.isChanging = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //删除
    delMedModal: function (delType) {
      var _this = this;
      _this.$llsConfirm({
        text: "确定要删除该文件吗？",
        title: "请确认",
        buttonSure: {
          click: function (callback) {
            //获取当前文件信息
            // var fileTemp = _this.imgArr[_this.imageIndex-1];
            var fileTemp = "";
            //获取当前文件信息
            if (delType == "V") {
              fileTemp = _this.videoArr[_this.videoIndex - 1];
            } else if (delType == "P") {
              fileTemp = _this.pdfArr[_this.pdfIndex - 1];
            } else {
              fileTemp = _this.imgArr[_this.imageIndex - 1];
            }


            console.log('fileTemp', fileTemp)

            //兼容STANDARD模式
            var modelCode = fileTemp["modelCode"] || fileTemp["modeCode"];
            _this.$http
              .post(
                _this.urlDeleteMedia +
                  "?modelCode=" +
                  modelCode +
                  "&fileId=" +
                  fileTemp["id"]
              )
              .then(
                (data) => {
                  callback("suc");
                  let code =
                    data.code ||
                    data.httpCode ||
                    data.body.code ||
                    data.body.httpCode;
                  if (code == 200) {
                    _this.$llsMessage({ text: "删除成功" });
                    _this.showType = "TREE";
                    //重新查询影像树
                    _this.listModelTree();
                    _this.$emit("operate", "deleteSuc");
                    _this.$emit("changePanel", { type: "TREE", fileId: "" });
                  } else {
                    _this.$llsAlert({
                      text: data.body.message || data.body.msg,
                    });
                    _this.$emit("operate", "deleteFail");
                    _this.$emit("changePanel", { type: "TREE", fileId: "" });
                  }
                },
                (response) => {
                  callback("suc");
                  _this.$llsAlert({ text: response.message || response.msg });
                }
              );
          },
        },
      });
    },
    //重命名
    renameImage: function () {
      var _this = this;
      _this.imageIsRename = true;
      var renameInput = document.getElementById("renameInput");
      //监听键盘事件
      $(document)
        .unbind("keyup")
        .bind("keyup", function (event) {
          if (_this.imageIsRename && document.activeElement == renameInput) {
            switch (event.keyCode) {
              case 13:
                _this.setName();
            }
          }
        });
    },
    //设置新的名字
    setName: function () {
      var _this = this;
      if (!_this.mediaNewName) {
        _this.$llsAlert({ text: "文件名不能为空" });
        return;
      }
      //文件名未改变时，不需要保存
      if (_this.mediaNewName == _this.mediaName) {
        return;
      }
      //获取当前文件信息
      var fileTemp = "";
      if (_this.showType == "JPG") {
        fileTemp = _this.imgArr[_this.imageIndex - 1];
      } else if (_this.showType == "PDF") {
        fileTemp = _this.pdfArr[_this.pdfIndex - 1];
      } else if (_this.showType == "VIDEO") {
        fileTemp = _this.videoArr[_this.videoIndex - 1];
      }
      //兼容STANDARD模式
      var modelCode = fileTemp["modelCode"] || fileTemp["modeCode"];
      _this.layerLoading = true;
      _this.renameIng = true;
      _this.$http
        .post(
          _this.urlRenameMedia +
            "?modelCode=" +
            modelCode +
            "&fileId=" +
            fileTemp["id"] +
            "&rename=" +
            _this.mediaNewName
        )
        .then(
          (data) => {
            _this.layerLoading = false;
            _this.renameIng = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.$llsMessage({ text: "重命名成功" });
              _this.imageIsRename = false;
              _this.mediaName = _this.mediaNewName;
              //重新查询影像树
              _this.listModelTree(fileTemp["id"]);
              _this.$emit("operate", "renameSuc");
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
              _this.$emit("operate", "renameFail");
            }
          },
          (response) => {
            _this.layerLoading = false;
            _this.renameIng = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    //审核影像
    checkImage: function (checkResult, checkType, isAuto) {
      console.log(checkResult,checkType,'checkImage');
      // // 只有运营复审节点 才能改变影像树状态
      // if (this.nodeId !== 'usr_ts_compliance_recheck') return
      var _this = this;
      var fileTemp = "";
      //获取当前文件信息
      if (checkType == "V") {
        fileTemp = _this.videoArr[_this.videoIndex - 1];
      } else if (checkType == "P") {
        fileTemp = _this.pdfArr[_this.pdfIndex - 1];
      } else {
        fileTemp = _this.imgArr[_this.imageIndex - 1];
      }
      var busiKey = _this.busiKey; //默认为原子树的
      if (_this.modelType == "GROUP") {
        busiKey = busiKey[fileTemp["modelCode"]];
      }
      if (_this.modelType == "OTHER") {
        busiKey = fileTemp["busiKey"];
      }
      var postParam = {
        relMediaId: fileTemp["id"], //文件ID
        relAssetNo: busiKey,
        checkNodeId: "",
        checkNode: "",
        checkStatus: checkResult, //审核结果
        checkAdvice: "", //审核意见
      };
      var urlParam = "";
      if (_this.modelType == "STANDARD") {
        postParam = {};
        urlParam =
          "?modelCode=" +
          fileTemp["modeCode"] +
          "&busiKey=" +
          fileTemp["busiKey"] +
          "&status=" +
          (checkResult == "PASS" ? "AUDITED" : "AUDITING") +
          "&fileId=" +
          fileTemp["id"];
      }
      _this.layerLoading = true;
      console.log('urlCheckMedia', _this.urlCheckMedia)
      _this.$http.post(_this.urlCheckMedia + urlParam, postParam).then(
        (data) => {
          _this.layerLoading = false;
          let code =
            data.code || data.httpCode || data.body.code || data.body.httpCode;
          if (code == 200) {
            if (!isAuto) {
              _this.$llsMessage({ text: "审核成功" });
            }
            //重新查询影像树
            _this.listModelTree();
            _this.$emit("operate", "checkSuc");
          } else {
            _this.$llsAlert({ text: data.body.message || data.body.msg });
            _this.$emit("operate", "checkFail");
          }
        },
        (response) => {
          _this.layerLoading = false;
          _this.$llsAlert({ text: response.message || response.msg });
        }
      );
    },
    //返回
    goBack: function () {
      var _this = this;
      this.showType = "TREE";
      this.$emit("changePanel", { type: "TREE", fileId: "" });
      setTimeout(function () {
        var medOut = _this.$refs.medOut;
        medOut.parentNode.scrollTop = _this.treeScroll;
      }, 0);
    },
    //文件列表是否允许勾选
    canChoose: function (filesObj) {
      var flag = false;
      if (!this.readonly) {
        //有批量删除或批量调整分类权限
        if (filesObj["BE"] || filesObj["BA"]) {
          flag = true;
        }
        //有审核功能并且配置了批量审核接口
        if (this.isNeedCheck && this.urlBatchCheckMedia) {
          flag = true;
        }
        //有下载权限并且配置了下载指定文件接口
        if (filesObj["D"] && this.urlDownSpecifyMedia) {
          flag = true;
        }
      }
      return flag;
    },
    //影像反馈
    mediaFeedback: function () {
      var _this = this;
      this.isShowFeedback = true;
      this.feedbackObj = [];
      this.feedList = [];
      this.feedremark = "";
      //查询问题类型
      _this.$http.post(_this.urlFeedbackList).then(
        (data) => {
          let code =
            data.code || data.httpCode || data.body.code || data.body.httpCode;
          if (code == 200) {
            var resData = data.body.data || [];
            var feedList = [];
            for (var i = 0; i < resData.length; i++) {
              feedList.push({
                dispalyName: resData[i]["moduleName"],
                dictParam: resData[i]["id"],
              });
            }
            _this.feedbackObj = feedList;
          } else {
            _this.$llsAlert({ text: data.body.message || data.body.msg });
          }
        },
        (response) => {
          _this.$llsAlert({ text: response.message || response.msg });
        }
      );
    },
    //取消影像反馈
    toCloseFeedback: function () {
      this.isShowFeedback = false;
    },
    //提交影像反馈
    toSureFeedback: function () {
      var _this = this;
      var feedList = this.feedList || [];
      var feedremark = this.feedremark || "";
      if (feedList.length == 0) {
        this.$llsMessage({ type: "warn", text: "问题类型未选择，请检查" });
        return;
      }
      _this.isFeedbacking = true;
      var origin = document.location.origin;
      var userName = sessionStorage.getItem("objUser");
      if (userName) {
        userName = JSON.parse(userName)["loginName"];
      }
      //提交反馈
      _this.$http
        .post(_this.urlFeedbackSubmit, {
          mediaUrl: origin + "/" + _this.feedUrl,
          moduleIdList: feedList,
          info: feedremark,
          maintainName: userName,
        })
        .then(
          (data) => {
            _this.isFeedbacking = false;
            let code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              _this.isShowFeedback = false;
              this.$llsMessage({ type: "suc", text: "提交成功" });
            } else {
              _this.$llsAlert({ text: data.body.message || data.body.msg });
            }
          },
          (response) => {
            _this.isFeedbacking = false;
            _this.$llsAlert({ text: response.message || response.msg });
          }
        );
    },
    loadPdfHandler: function () {
      this.currentPage = Number(this.pdfPage) || 1; // 加载的时候先加载第一页
    },
    loadPdfHandlerSon: function (index) {
      var _this = this;
      if (index == this.pdfList.length - 1) {
        setTimeout(function () {
          var domMedPdfBox = _this.$refs.medPdfBox;
          var canPdfs = domMedPdfBox.getElementsByTagName("canvas");
          var totalH = 0;
          var totalW = 0;
          for (var i = 1; i < canPdfs.length; i++) {
            totalW = canPdfs[i]["clientWidth"];
            var curH = canPdfs[i]["clientHeight"];
            totalH += curH;
          }
          _this.ocrHeight = totalH;
          _this.ocrWidth = totalW;
          console.log("3117", totalH);
        }, 3500);
      }
    },
    //打开合同目录
    getContList: function () {
      this.$emit("openContList", { fileId: this.ocrFileId });
    },
    // 展示文件标记状态
    isShowStatus(type) {
       console.log(this.mediaCheckStatus, '===this.mediaCheckStatus===')
       const { dictParam = '' } = this.mediaCheckStatus
        if (type === dictParam) {
          return true
        } else {
          return false
        }

    }
  },
};
</script>


