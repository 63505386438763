export default {
  methods: {
    treeDelete() {
      const fileIds = this.getFileItem.map((item) => item.id);
      if (fileIds.length === 0) {
        this.$message.error('请选择文件');
        return;
      }
      const { modelCode } = this.getFileItem[0];
      if (this.btnType == 'delete') { // 批量删除
        // 如果文件已标记通过且当前节点为融资资料审核不支持删除
        const isMark = this.getFileItem.some((item) => item.mediaCheckStatus && JSON.parse(item.mediaCheckStatus).dictParam === 'PASS');
        if (this.nodeId === 'usr_ts_compliance_check' && isMark) {
          this.$message.error('包含已标记通过的文件，不支持删除');
          return;
        }
        // 接口入参
        const queryData = {
          fileIds,
          modelCode,
        };
        this.$confirm(`共选择${fileIds.length}个文件,是否确认删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.layerLoading = true;
          this.$http.post('/media-web/media/opera/do/deleteFileByFileIds', queryData).then((res) => {
            this.layerLoading = false;
            if (res.code == 200) {
              this.$message.success('删除成功');
              // 重新加载
              this.listModelTree();
            }
          }).catch(() => {
            this.layerLoading = false;
          });
        });
      } else if (this.btnType == 'download') { // 批量下载
        this.layerLoading = true;
        this.$http.download(
          '/media-web/media/scf/batch/downloadFileZip',
          {
            fileIds,
            modelCode,
          },
        ).then((res) => {
          this.layerLoading = false;
          this.$downLoad(res);
          this.listModelTree();
        }).catch(() => {
          this.layerLoading = false;
        });
      }
    },
    getChildList(list, item) {
      const arr = [];
      if (item.isLeaf == 'Y' && item.fileList.length) {
        arr.push(...item.fileList);
      } else {
        filerList(list, item);
        function filerList(list, item) {
          if (item.isLeaf == 'Y' && item.fileList.length) {
            arr.push(...item.fileList);
          } else {
            const itemChildList = list.filter((ele) => ele.parentId == item.id);
            if (itemChildList.length >= 1) {
              arr.push(...itemChildList);
              for (let i = 0; i < itemChildList.length; i++) {
                filerList(list, itemChildList[i]);
              }
            }
          }
        }
      }
      return arr;
    },
    setParentSelected(list, item) {
      const parentItem = list.find((ele) => ele.id == item.parentId);
      if (parentItem) {
        list.forEach((item) => {
          if (parentItem.id == item.id) {
            item.show = parentItem.children.length == parentItem.children.filter((ele) => ele.show).length;
          }
        });
        this.setParentSelected(list, parentItem);
      }
    },
    hanlderCheckFile(val) {
      const { fileItem, item } = val;
      const list1 = this.treeToList(this.mediaTreeObj);
      if (item.parentId == '-1') { // 点击最顶部根节点
        list1.forEach((x) => {
          x.show = item.show;
        });
      } else { // 点击目录包含子集也包含父级
        // 设置所有子节点选中状态
        let list = [];
        if (fileItem) { // 点击文件
          list = [fileItem];
        } else {
          list = [...this.getChildList(list1, item), item];
        }
        list1.forEach((element) => {
          list.forEach((ele) => {
            if (ele.id == element.id) {
              if (fileItem) {
                element.show = fileItem.show;
              } else {
                element.show = item.show;
              }
            }
          });
        });

        // 设置父节点选中状态
        if (fileItem) { // 点击文件
          list1.forEach((element) => {
            if (item.id == element.id) {
              element.show = item.fileList.length == item.fileList.filter((ele) => ele.show).length;
            }
          });
        }
        this.setParentSelected(list1, item);
      }
      this.mediaTreeObj = this.treeData(list1);
    },
    treeToList(datas) {
      const arr = [];
      formateData(datas, 0);
      function formateData(datas, level) {
        var level = level || 0;
        level++;
        for (let i = 0; i < datas.length; i++) {
          datas[i].level = `${level}-${i}`;
          arr.push(datas[i]);
          if (datas[i].children && datas[i].children.length > 0) {
            formateData(datas[i].children, level);
          } else if (datas[i].fileList && datas[i].fileList.length > 0) {
            formateData(datas[i].fileList, level);
          }
        }
      }
      return arr;
    },
    treeData(data) {
      // let cloneData = data
      return data.filter((father) => {
        const branchArr = data.filter((child) => father.id == child.parentId);
        if (branchArr.length > 0) {
          if (father.isLeaf == 'N' || (father.isLeaf == 'Y' && father.fileList.length == 0)) {
            father.children = branchArr;
          } else if (father.isLeaf == 'Y') {
            father.fileList = branchArr;
          }
        }
        return father.parentId == '-1';
      });
    },
  },
};
