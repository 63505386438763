/* eslint-disable indent */
/* eslint-disable no-tabs */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/home/index.vue';
import Login from '../views/login1.vue';
import absRouter from './absRouter';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: '/login',
  //   component: Login,
  //   name: '登录',
  // },
  {
    path: '/homeWebpage',
    name: 'homeWebpage',
    component: () => import('@/views/homePage/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/homePage/index.vue'),
    redirect: '/homeWebpage',
  },
  // {
  //   path: '/home',
  //   component: HomeView,
  //   name: '首页',
  // },
  {
    path: '/loginCenter',
    component: () => import('@/views/loginCenter.vue'),
    name: '单点登录校验token',
  },
  {
    path: '/',
    component: HomeView,
    redirect: '/homeWebpage',
    children: [
      ...absRouter,
      {
        path: '/home',
        name: 'homePage',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/homePage.vue'),
        meta: {
          isFirst: true,
        },
      },
      {
        path: '/companyManage',
        name: 'companyManage',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/companyManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
        },
      },
      {
        path: '/signature',
        name: 'signature',
        component: () => import('../views/signature.vue'),
      },
      {
        path: '/companyDetail',
        name: 'companyDetail',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/companyManage/detail.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
        },
      },
      {
        path: '/companyDetailA',
        name: 'companyDetailA',
        component: () => import(
            /* webpackChunkName: "tenantManager" */ '../views/tenant/A/companyManage/detailA.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/companyManageA',
        name: 'companyManageA',
        component: () => import(
            /* webpackChunkName: "tenantManager" */ '../views/tenant/A/companyManage/indexA.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      // 资金方准入页面
      {
        path: '/fundAccess/index',
        name: 'fundAccess',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/fundAccess/index.vue'),
        meta: {
          isBack: true,
          isFirst: true,
        },
      },
      // 资金方准入详情页面
      {
        path: '/fundAccess/accessDetail',
        name: 'accessDetail',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/fundAccess/accessDetail.vue'),
        meta: {
          parent: ['/fundAccess/index'],
        },
      },
      {
        path: '/contractManage/index',
        name: 'contractManage',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/contractManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        path: '/contractManage/detail',
        name: 'contractManageDetail',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/contractManage/detail.vue'),
        meta: {
          parent: ['/contractManage/index'],
        },
      },
      {
        path: '/financeAuditContract',
        name: 'financeAuditContract',
        component: () => import(/* webpackChunkName: "tenantManager" */ '../views/contractManage/financeAuditContract.vue'),
        meta: {
          // parent: ['/contractManage/index?activeName=ToBeSigned', '/contractManage/index?activeName=Effective'],
        },
      },
      // 融资管理
      {
        path: '/projectManage/index',
        name: 'projectManage',
        component: () => import(/* webpackChunkName: "projectManage" */ '../views/projectManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        path: '/projectManage/detail',
        name: 'projectDetail',
        component: () => import(/* webpackChunkName: "projectDetail" */ '../views/projectManage/detail.vue'),
      },
      {
        path: '/financingLoanManagement',
        name: 'financingLoanManagement',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */ '../views/financingLoanManagement/financingLoanManagement.vue'
          ),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        path: '/financingLoanManagement/detail',
        name: 'financingLoanDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */ '../views/financingLoanManagement/detail.vue'
          ),
      },
      {
        path: '/sceProductManage',
        name: 'sceProductManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */ '../views/scenarioLoanManage/loanProductManage.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/loanApplydetail',
        name: 'loanApplydetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */ '../views/scenarioLoanManage/loanApplydetail.vue'
          ),
      },
      {
        path: '/creditApplydetail',
        name: 'creditApplydetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */ '../views/scenarioLoanManage/creditApplydetail.vue'
          ),
      },
      {
        path: '/sceFinanceApplyManage',
        name: 'sceFinanceApplyManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/financeApplyManage/financeApplyList.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/sceCreditManage',
        name: 'sceCreditManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/creditManage/creditList.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/creditManage/detail',
        name: 'creditManageDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/creditManage/detail.vue'
          ),
      },
      {
        path: '/payManage/detail',
        name: 'payManageDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/payManage/detail.vue'
          ),
      },
      {
        path: '/payManage/payList',
        name: 'payManageDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/payManage/payList.vue'
          ),
meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/repayApplydetail',
        name: 'repayApplydetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/repayApplydetail.vue'
          ),
      },
      {
        path: '/sceRepayManage',
        name: 'sceRepayManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/repayManage/repayList.vue'
          ),
meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/sceOrderManage',
        name: 'sceOrderManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/ordersManage/orderList.vue'
          ),
meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/sceLoanManage',
        name: 'sceOrderManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/scenarioLoanManage/payManage/payList.vue'
          ),
meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/invoiceManage',
        name: 'invoiceManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/invoiceManage/index.vue'
          ),
        meta: {
            keepAlive: false,
            isBack: true,
            isFirst: true,
        },
      },
      {
        path: '/invoicesInfo',
        name: 'invoicesInfo',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/invoicesManage/invoicesInfo.vue'
          ),
          meta: {
            parent: ['/invoiceManage'],
          },
      },
      // 系统设置
      {
        path: '/system/config',
        name: 'systemConfig',
        component: () => import('@/views/system/config.vue'),
        meta: {
          isFirst: true,
          keepAlive: false,
        },
      },
      {
        //
        path: '/system/addUser',
        name: 'sysAddUser',
        component: () => import('@/views/system/addUser.vue'),
        meta: {
          parent: ['/system/config?activeName=first'],
        },
      },
      {
        //
        path: '/system/permissionChange',
        name: 'systemPermissionChange',
        component: () => import('@/views/system/permissionChange.vue'),
        meta: {
          parent: ['/system/config?activeName=second'],
        },
      },
      {
        //
        path: '/system/supplierAdd',
        name: 'supplierAdd',
        component: () => import('@/views/system/supplierAdd.vue'),
        meta: {
          parent: ['/system/config?activeName=third'],
        },
      },
      // 融资签收管理
      {
        path: '/financeSign/index',
        name: 'financeSign',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/financeSign/index.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/financeSign/detail',
        name: 'financeSignDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/financeSign/detail.vue'
          ),
      },
      // 资产管理
      {
        path: '/assetsManage/index',
        name: 'assetsManage',
        component: () => import('@/views/assetsManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        // 资产管理-正常持有转让或者融资详情
        path: '/assetsManage/transferDetail',
        name: 'transferDetail',
        component: () => import('@/views/assetsManage/transferDetail.vue'),
        meta: {
          parent: ['/assetsManage/index'],
        },
      },
      {
        // 资产管理-正常持有转让或者融资详情
        path: '/successTip',
        name: 'successTip',
        component: () => import('@/views/common/successTip.vue'),
        meta: {
          parent: ['/assetsManage/index'],
        },
      },
      {
        path: '/financeSign/handle',
        name: 'financeSignHandle',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/financeSign/handle.vue'
        ),
        meta: {
          parent: ['/voucherTransManage'],
        },
      },
      {
        path: '/financeSign/sign',
        name: 'financeSign',
        component: () => import(/* webpackChunkName: "financingLoanManagement" */ '../views/financeSign/sign.vue'),
      },
      {
        path: '/financeSign/assetsDetail',
        name: 'assetsDetail',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/financeSign/assetsDetail.vue'
        ),
        meta: {
          isFirst: false,
          parent: ['/financeSign/handle', '/transactionManage/transDetail', '/voucherTransManage'],
        },
      },
      // 放款管理
      {
        path: '/payBasicManage/index',
        name: 'payBasicManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/payBasicManage/index.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/payBasicManage/list',
        name: 'payBasicManageList',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/payBasicManage/list.vue'
          ),
      },
      {
        path: '/payBasicManage/financeTransfer',
        name: 'financeTransfer',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/payBasicManage/financeTransfer.vue'
          ),
      },
      // 融资报价
      {
        path: '/financeOffer/index',
        name: 'financeOffer',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/financeOffer/index.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      // 再保理融资管理
      {
        path: '/refactorLoanApplyManage',
        name: 'refactorLoanApplyManage',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/refactoring/refactorLoanMng/index.vue'
          ),
        meta: {
          keepAlive: false,
          isBack: true,
        },
      },
      {
        path: '/refactorLoanDeatil',
        name: 'refactorLoanDeatil',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/refactoring/refactorLoanMng/detail.vue'
          ),
      },
      {
        path: '/refactorAssetQuery',
        name: 'refactorAssetQuery',
        component: () => import(
            /* webpackChunkName: "financingLoanManagement" */
            '../views/refactoring/refactorALL/index.vue'
          ),
          meta: {
            keepAlive: false,
            isBack: true,
          },
      },
      {
        path: '/fundAssetsManage/ticketPreview',
        name: 'ticketPreview',
        component: () => import('@/views/assetsManage/ticketPreview.vue'),
        meta: {
          isFirst: false,
          parent: ['/paymentManage/assetBasicManage', '/voucherTransManage', '/assetsManage/index'],
        },
      },
      {
        // 资产管理-已到期(已结清)详情
        path: '/assetsManage/certificateDetail',
        name: 'certificateDetail',
        component: () => import('@/views/assetsManage/transDetailComponents/certificateDetail.vue'),
        meta: {
          parent: ['/transactionManage', '/assetsManage/index'],
        },
      },
      // 资产管理-(转让/融资)合同签署
      {
        path: '/assetsManage/transferAgreement',
        name: 'transferAgreement',
        component: () => import('@/views/assetsManage/transDetailComponents/transferAgreement.vue'),
      },
      {
        path: '/assetsManage/ticketPreview',
        name: 'ticketPreview',
        component: () => import('@/views/assetsManage/transDetailComponents/ticketPreview.vue'),
        meta: {
          isFirst: false,
          parent: ['/paymentManage/assetBasicManage', '/voucherTransManage', '/assetsManage/index'],
        },
      },
      {
        // 资产管理-上传贸易资料
        path: '/assetsManage/financeTransfer',
        name: 'transferDetail',
        component: () => import('@/views/assetsManage/transDetailComponents/financeTransfer.vue'),
        meta: {
          isFirst: false,
          parent: ['/financeAudit/detail', '/transApplyApprove'],
        },
      },
      {
        // 转让审核
        path: '/transferAudit/index',
        name: 'transferAudit',
        component: () => import('@/views/transferAudit/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        // 转让审核
        path: '/transferAudit/detail',
        name: 'transferDetail',
        component: () => import('@/views/transferAudit/detail.vue'),
        meta: {
          isFirst: false,
          parent: [
              '/transApplyApprove',
          ],
        },
      },
      {
        // 应付管理
        path: '/paymentManage/assetBasicManage',
        name: 'paymentIndex',
        component: () => import('@/views/paymentManage/assetBasicManage.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        path: '/paymentManage/detail',
        name: 'paymentDetail',
        component: () => import('@/views/paymentManage/detail.vue'),
        meta: {
          // keepAlive: false,
          isBack: true,
          parent: [
               '/paymentManage/assetBasicManage',
               '/confirmManage/index',
               '/credentialManage/index',
                '/transactionManage',
                '/voucherTransManage',
                '/buyerVoucherManage',
              ],
        },
      },
      // 持有方明细详情
      {
				path: '/paymentManage/ticketPreview',
				name: 'paymentManageTicketPreview',
				component: () => import('@/views/paymentManage/ticketPreview.vue'),
        meta: {
          parent: ['/credentialManage/index', '/buyerVoucherManage'],
        },
			},
      //	确权管理
      {
        path: '/confirmManage/index',
        name: 'confirmIndex',
        component: () => import('@/views/confirmManage/index.vue'),
        meta: {
          keepAlive: false,
          // isBack: true,
          isFirst: true,
        },
      },
      {
        path: '/selectCompany',
        name: 'selectCompany',
        component: () => import('@/views/selectCompany.vue'),
      },
      {
        path: '/companyInfo/naturalBuild',
        name: 'naturalBuild',
        component: () => import('@/views/companyInfo/naturalBuild.vue'),
      },
      {
        path: '/companyInfo/stepOne',
        name: 'stepOne',
        component: () => import('@/views/companyInfo/stepOne.vue'),
      },
      {
        path: '/companyInfo/stepTwo',
        name: 'stepTwo',
        component: () => import('@/views/companyInfo/stepTwo.vue'),
      },
      {
        path: '/companyInfo/stepThree',
        name: 'stepThree',
        component: () => import('@/views/companyInfo/stepThree.vue'),
      },
      {
        path: '/companyInfo/stepFour',
        name: 'stepFour',
        component: () => import('@/views/companyInfo/stepFour.vue'),
      },
      {
        path: '/companyInfo/stepFive',
        name: 'stepFive',
        component: () => import('@/views/companyInfo/stepFive.vue'),
      },
      {
        path: '/companyInfo/stepSix',
        name: 'stepSix',
        component: () => import('@/views/companyInfo/stepSix.vue'),
      },
      {
        path: '/companyInfo/stepComplete',
        name: 'stepComplete',
        component: () => import('@/views/companyInfo/stepComplete.vue'),
      },
      {
        path: '/companyInfo/stepCompleteNatural',
        name: 'stepCompleteNatural',
        component: () => import('@/views/companyInfo/stepCompleteNatural.vue'),
      },
      // 交易管理
      {
        path: '/voucherTransManage',
        name: 'transactionManage',
        component: () => import('@/views/transactionManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      // 交易管理详情
      {
        path: '/transactionManage/transDetail',
        name: 'transDetail',
        component: () => import('@/views/transactionManage/transDetail.vue'),
        meta: {
          parent: ['/voucherTransManage'],
        },
      },
      // 核心企业-凭证管理
      {
        path: '/buyerVoucherManage',
        name: 'buyerVoucherManage',
        component: () => import('@/views/buyerVoucherManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },

      // 交易申请审核
      {
        path: '/transApplyApprove',
        name: 'transApplyApprove',
        component: () => import('@/views/transactionAudit/index.vue'),
        meta: {
          // keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      // 融资审核
      {
        //
        path: '/financeAudit/index',
        name: 'financeAudit',
        component: () => import('@/views/financeAudit/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        // 融资审核
        path: '/financeAudit/detail',
        name: 'financeDetail',
        component: () => import('@/views/financeAudit/detail.vue'),
        meta: {
          parent: ['/transApplyApprove', '/financeAudit/index'],
        },
      },
      // 到期兑付
      {
        path: '/expiredManage/index',
        name: 'expiredManageIndex',
        component: () => import('@/views/expiredManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      // 建档
      {
        path: '/changeCpmpanyInfo',
        name: 'changeCpmpanyInfo',
        component: () => import('@/views/companyInfo/changeCpmpanyInfo.vue'),
      },
      {
        // 资金市场行情
        path: '/capitalMarket',
        name: 'capitalMarket',
        component: () => import('@/views/capitalMarket/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      // 已签发凭证管理
			{
				path: '/credentialManage/index',
				name: 'credentialManageIndex',
				component: () => import('@/views/credentialManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
			},
			// 资金方资产管理
			{
				path: '/fundAssetsManage/index',
				name: 'fundAssetsManage',
				component: () => import('@/views/fundAssetsManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
        },
			},
      {
				path: '/paymentManage/ticketPreview',
				name: 'paymentManageTicketPreview',
				component: () => import('@/views/paymentManage/ticketPreview.vue'),
      },
      {
				path: '/credentialManage/detail',
				name: 'credentialManageDetail',
				component: () => import('@/views/credentialManage/detail.vue'),
        meta: {
          parent: [
            '/credentialManage/index',
            '/buyerVoucherManage',
          ],
        },
			},
      // 融资管理
      {
        path: '/financingManage/detail',
				name: 'financingManageDetail',
				component: () => import('@/views/financingManage/detail.vue'),
      },
      // 基本信息
			{
				path: '/basicInfo/index',
				name: 'basicInfoIndex',
				component: () => import('@/views/basicInfo/index.vue'),
        meta: {
          isFirst: true,
          keepAlive: false,
        },
			},
      {
				path: '/bankInfo',
				name: 'bankInfo',
				component: () => import('@/views/basicInfo/bankInfo.vue'),
        meta: {
          parent: ['/basicInfo/index'],
        },
			},
      {
				path: '/person/info',
				name: 'basicInfoIndex',
				component: () => import('@/views/basicInfo/index.vue'),
			},
      {
				path: '/absAssetsPoolManage/index',
				component: () => import('@/views/absAssetsPoolManage/index.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
        },
				name: '资产池管理',
			},
			{
				path: '/absAssetsPoolManage/detail',
				component: () => import('@/views/absAssetsPoolManage/detail.vue'),
				name: '资产池详情',
			},
      {
				path: '/absAssetsManage/detail',
				component: () => import('@/views/absAssetsManage/detail.vue'),
				name: '资产详情',
			},
      {
				path: '/system/security',
				component: () => import('@/views/system/security.vue'),
				name: '安全设置',
        meta: {
          isFirst: true,
          keepAlive: false,
        },
			},
      // 还款管理
      {
        path: '/repaymentManagement',
        name: 'repaymentManagement',
        component: () => import('@/views/repaymentManage/repaymentBasicManage.vue'),
        meta: {
          keepAlive: false,
          isBack: true,
          isFirst: true,
        },
      },
      {
        // 还款管理-还款详情
        path: '/repaymentDetail',
        name: 'repaymentDetail',
        component: () => import('@/views/repaymentManage/repaymentDetail.vue'),
        meta: {
          parent: ['/repaymentManagement'],
        },
      },
    ],
  },
  {
    path: '*',
    name: '/404',
    component: () => import(/* webpackChunkName: "tenantManager" */ '../views/error.vue'),
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/AboutView2',
    name: 'AboutView2',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView2.vue'),
  },
  {
    path: '/AboutView3',
    name: 'AboutView3',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView3.vue'),
  },
  {
    path: '/AboutLoginDemo',
    name: 'AboutLoginDemo',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutLoginDemo.vue'),
  },
  {
    path: '/AboutView4',
    name: 'AboutView4',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView4.vue'),
  },
];

const router = new VueRouter({
  routes,
});
export default router;
