import Vue from 'vue';
import LinkUI from 'link-ui-web';
import Media from '@/components/llsMedia/llsMedia.vue';
// import Media from '@linklogis/media';
import Agreement from '@linklogis/agreement';
// import './registerServiceWorker';
// import 'link-ui-web/lib/theme-chalk/index.css';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import globalVariable from '@/common/global_variable';
import '@/common/prototype';
import '@/common/filter';
// import '@/common/minx';
import DetailLayout from '@linklogis/detail-layout';
// import llsTableHeaderDynamic from '@/components/lls-table-header-dynamic/index';
import llsFormV1 from '@/components/lls-form-v1/index.vue';
import { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-unresolved
import llsPrompt from '@/components/prompt/llsPrompt.vue';
// eslint-disable-next-line import/no-unresolved
import llsInput from '@/components/input/src/input.vue';

import App from './App.vue';
import router from './router';
import store from './store';
// eslint-disable-next-line import/extensions
import HTTP from './api/http';
import './icons';

import $Agreement from './components/Agreement/Agreement';
import AgreementVue from './components/Agreement/AgreementVue.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const llsTableHeaderDynamic = require('@/components/lls-table-header-dynamic/index');

console.log('---llsTableHeaderDynamic', llsTableHeaderDynamic.default);

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}

Vue.prototype.GLOBAL = globalVariable;
Vue.prototype.$EventBus = new Vue();
// Vue.use(ElementUI);
Vue.use(LinkUI, { size: 'small' });
Vue.use(llsTableHeaderDynamic.default);
Vue.prototype.$http = HTTP;
Vue.config.productionTip = false;

Vue.component('llsMedia', Media);
// Vue.use(Media);
Vue.use(Agreement);
Vue.component('AgreementVue', AgreementVue);
// Vue.use(llsInput);
// Vue.use(llsFormV1);
// 注册组件
Vue.use(DetailLayout);
Vue.component('llsInput', llsInput);
Vue.component('llsFormV1', llsFormV1);
Vue.component('llsPrompt', llsPrompt);
Vue.prototype.$custAgreement = $Agreement;
Vue.mixin({
  methods: {
    formatJson(val) {
      const obj = JSON.parse(val || '{}');
      return obj.displayName;
    },
    dealNothing(row, colum) {
      return row[colum.property];
    },
    // linkUI
    formatJson3(row, colum) {
      let r = null;
      if (colum.property.indexOf('.') !== -1) {
        const colArr = colum.property.split('.');
        r = row;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < colArr.length; i++) {
          r = r[colArr[i]];
        }
      } else {
        r = row[colum.property];
      }
      if (!r || r === '--') {
        return '--';
      }
      if (typeof r === 'string') {
        return JSON.parse(r).displayName;
      }
      return r.displayName;
    },
    // eslint-disable-next-line no-param-reassign
    formatMoney3(value) {
      let newValue = value;
      if (!newValue) {
        return newValue;
      }
      newValue = newValue.toString();
      const index = newValue.indexOf('.');
      if (index != -1) {
        let decimalPart = newValue.substring(0, index);
        const pointPart = newValue.substring(index + 1);
        decimalPart = decimalPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 整数部分做格式化
        newValue = `${decimalPart}.${pointPart}`;
      } else {
        newValue = newValue.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      }
      return newValue;
    },
  },
});

// eslint-disable-next-line import/first
import '@/styles/index.scss';
// eslint-disable-next-line import/first
import '@/styles/link-variables.scss';

const res = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
/* eslint-disable */
const routeHistory=[];
router.beforeEach((to: any, from: any, next: any) => {
  console.log(to,from,'---to--from---')
  // eslint-disable-next-line no-param-reassign
  if (to.path == '/stepCompleteNatural' || to.path == '/stepComplete' || to.path == '/selectCompany' || to.path == '/companyInfo/stepOne' || to.path == '/companyInfo/naturalBuild' || to.path == '/companyInfo/stepTwo' || to.path == '/companyInfo/stepThree' || to.path == '/companyInfo/stepFour' || to.path == '/companyInfo/stepFive' || to.path == '/companyInfo/stepSix' || to.path == '/signature') {
    Vue.prototype.$EventBus.$emit('enterNoNeddMenuPage');
    Vue.prototype.$EventBus.$emit('noShowHeadMenus');
  } else {
    Vue.prototype.$EventBus.$emit('enterNeddMenuPage')
  }
  if (to.fullPath == '/Home') {
    setTimeout(() => {
      Vue.prototype.$EventBus.$emit('getActivePath', '/home');
    }, 1500);
  } else {
    // 两种情况1、只跳转一次  2、跳转中还有跳转
    // 路由定位，有parent的只能是二级或者二级或者三级 单三级不设parant
    let targetPath = sessionStorage.getItem('targetPath')
    if(to?.meta?.parent && Array.isArray(to.meta?.parent)) {
        //  考虑既有二级还有三级的路由
        if(!from.meta?.isFirst) {
          //  三级路由
          Vue.prototype.$EventBus.$emit('getActivePath', targetPath);
        } else {
          const flag = to.meta.parent.includes(from.fullPath)
          sessionStorage.setItem('targetPath',from.fullPath)
          if(flag) {
            Vue.prototype.$EventBus.$emit('getActivePath', from.fullPath);
          } else {
            Vue.prototype.$EventBus.$emit('getActivePath', to.fullPath);
          }
        }
    } else {
      if(to.meta?.isFirst) {
        // 第二种情况
        Vue.prototype.$EventBus.$emit('getActivePath', to.fullPath);
      } else {
        Vue.prototype.$EventBus.$emit('getActivePath', targetPath);
      }
      sessionStorage.removeItem('targetPath')
    }

  }
  let fromPath=(from.fullPath.indexOf('?')>0)?from.fullPath.split('?')[0]:from.fullPath
  console.log(from, '===this.$routeHistory===')
  routeHistory.push(fromPath)
  next()
});
Vue.prototype.$routeHistory=routeHistory;
