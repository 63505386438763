const absRouter = [
  {
    path: '/uploadInformation',
    name: 'uploadInformation',
    component: () => import('../views/absComponent/uploadInformation.vue'),
    meta: {
    //   keepAlive: true,
    //   isBack: true,
    },
  },
  {
    path: '/absProductManage/index',
    component: () => import('@/views/absComponent/absProductManage/index.vue'),
    meta: {
      isFirst: true,
    },
    name: '产品管理',
  },
  {
    path: '/absProductManage/detail',
    component: () => import('@/views/absComponent/absProductManage/detail.vue'),
    meta: {
      parent: ['/absProductManage/index'],
      // keepAlive: true,
      // isBack: true,
    },
    name: '产品详情',
  },
  {
    path: '/absProductManage/assetDetail',
    component: () => import('@/views/absComponent/absProductManage/assetDetail.vue'),
    meta: {
      parent: ['/absProductManage/index'],
      // keepAlive: true,
      // isBack: true,
    },
    name: '资产详情',
  },
];
export default absRouter;
