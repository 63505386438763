
import { mapState } from 'vuex';
import { JSEncrypt } from '@/common/jsencrypt';
import { PUBLICKEY } from '@/common/config';

export default {
  name: 'resetPwdDialog',
  data() {
    return {
      diaLoading: false,
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        repassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
        newPassword: [
          { required: true, message: '请输入新登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
        repassword: [
          { required: true, message: '请再次输入新登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  props: ['isShow'],
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    submitForm(formName: string) {
      this.$refs[formName].validate((valid: any) => {
        if (valid) {
          this.modifyPassword();
          return true;
        }
        return false;
      });
    },
    resetForm() {
      this.$emit('hiddenResetPwdDialog', false);
    },
    modifyPassword() {
      const jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(PUBLICKEY);
      const { newPassword, oldPassword } = this.ruleForm;

      this.$http.post('/spyPc-web/userLogin/modifyPassword', {
        mobile: this.userInfo.phone || '',
        newPassword: jsencrypt.encryptLong(newPassword),
        oldPassword: jsencrypt.encryptLong(oldPassword),
      }).then(() => {
        this.$alert('重置密码成功。', {
          confirmButtonText: '确定',
          callback: () => {
            this.$emit('hiddenResetPwdDialog', false);
            this.$parent.logout();
          },
        });
      });
    },
  },
};
