<template>
<lls-dialog :title="title" :width="width" :visible.sync="show">
  <lls-transfer
    class="header-options-trans"
    style="text-align: left; display: inline-block"
    v-model="value"
    filterable
    :titles="['隐藏字段', '显示字段']"
    :data="headerOptionsList"
    :props="{
      key: 'prop',
      label: 'label'
    }"
    @change="handleChange"
    @right-check-change="rightCheckChange"
  >
  <div slot="right-footer">
    <div class="right-footer-wrap">
      <span @click="upItem"><svg-icon iconClass="表头上移"></svg-icon></span>
      <span @click="downItem"><svg-icon iconClass="表头下移"></svg-icon></span>
      <span @click="toTop"><svg-icon iconClass="表头置顶"></svg-icon></span>
      <span @click="toBottom"><svg-icon iconClass="表头置底"></svg-icon></span>
    </div>
  </div>
  </lls-transfer>
  <div slot="footer" class="footer">
    <lls-button @click="onRecover" type="primary" plain>恢复默认</lls-button>
    <lls-button @click="onCancel" type="primary" plain>取 消</lls-button>
    <lls-button type="primary" @click="onSuccess(false, false)">确 定</lls-button>
  </div>
</lls-dialog>
</template>

<script>
/* eslint-disable */
import _ from 'lodash' // 导入loadsh插件
export default {
  name: 'lls-table-header-dynamic',
  props: {
    title: {
      type: String,
      default: '设置显示字段',
    },
    width: {
      type: Number | String,
      default: '600px',
    },
    direction: {
      type: String,
      default: 'rtl',
    },
    headerOptions: {
      type: Array,
      default: () => [],
    },
    showOptions: [],
    headerOriginOptions: {
      type: Array,
      default: () => [],
    },
    showOriginOptions: [],
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      form: {},
      result: [],
      value: [],
      isRecover: false,
      rightCheckedKeys: [],
      originShowOptions: [],
    };
  },
  methods: {
    getMoveInfo() {
      if(this.rightCheckedKeys && this.rightCheckedKeys.length > 1) {
        this.$message({
          type: 'error',
          message: '每次只可以调整一个表头字段'
        });
        return;
      }
      const [checkedKeys] = this.rightCheckedKeys;
      const { value: rightValue } = this;
      const checkedIndex = rightValue.indexOf(checkedKeys);
      let headerOptionsChecked = {};
      this.headerOptionsList.map((item, index) => {
        if(item.prop === checkedKeys) {
          headerOptionsChecked =  {
            val: item,
            index: index
          }
        }
      })
      return {
        checkedKeys, rightValue, checkedIndex, headerOptionsChecked
      }
    },
    toTop() {
      const{ checkedKeys, checkedIndex, headerOptionsChecked } = this.getMoveInfo();
      if (checkedIndex > 0) {
        // 先插入
        this.value.splice(0, 0 ,checkedKeys);
        this.headerOptionsList.splice(0, 0 ,headerOptionsChecked.val);
        // 再删除
        this.value.splice(checkedIndex + 1, 1);
        this.headerOptionsList.splice(headerOptionsChecked.index + 1, 1);
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: '已经是第一个了'
        })
      }
    },
    toBottom() {
      const{ checkedKeys, rightValue, checkedIndex, headerOptionsChecked } = this.getMoveInfo();
      if (checkedIndex < rightValue.length - 1) {
        // 先插入
        this.value.splice(rightValue.length, 0 ,checkedKeys);
        this.headerOptionsList.splice(this.headerOptionsList.length, 0 ,headerOptionsChecked.val);
        // 再删除
        this.value.splice(checkedIndex, 1);
        this.headerOptionsList.splice(headerOptionsChecked.index, 1);
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: '已经是最后一个了'
        })
      }
    },
    upItem() {
      const{ checkedKeys, checkedIndex, headerOptionsChecked } = this.getMoveInfo();
      if (checkedIndex > 0) {
        // 先插入
        this.value.splice(checkedIndex - 1, 0 ,checkedKeys);
        this.headerOptionsList.splice(headerOptionsChecked.index - 1, 0 ,headerOptionsChecked.val);
        // 再删除
        this.value.splice(checkedIndex + 1, 1);
        this.headerOptionsList.splice(headerOptionsChecked.index + 1, 1);
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: '已经是第一个了'
        })
      }
    },
    downItem() {
      const{ checkedKeys, checkedIndex, headerOptionsChecked, rightValue } = this.getMoveInfo();
      if (checkedIndex < rightValue.length - 1) {
        // 先插入
        this.value.splice(checkedIndex + 2, 0 ,checkedKeys);
        this.headerOptionsList.splice(headerOptionsChecked.index + 2, 0 ,headerOptionsChecked.val);
        // 再删除
        this.value.splice(checkedIndex, 1);
        this.headerOptionsList.splice(headerOptionsChecked.index, 1);
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: '已经是最后一个了'
        })
      }
    },
    rightCheckChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys)
      this.rightCheckedKeys = value;
    },
    handleChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
      this.result = value;
    },
    handleClose(done) {
      done();
    },
    onSuccess() {
      this.result = this.value;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    onRecover() {
      this.value = _.cloneDeep(this.showOriginOptions);
      this.headerOptionsList = _.cloneDeep(this.headerOriginOptions);
      this.onSuccess();
    },
    init() {
      this.originShowOptions = JSON.parse(JSON.stringify(this.showOptions));
      this.value = JSON.parse(JSON.stringify(this.showOptions));
      this.headerOptionsList = _.cloneDeep(this.headerOptions);
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.lls-table-header-dynamic {
  ::v-deep(.lls-drawer__body){
    overflow: auto;
    .lls-form-item {
      margin-bottom: 0;
      padding: 0 10px;
      .lls-form-item__label {
        text-align: left;
      }
      .lls-form-item__content {
        text-align: right;
      }
    }
  }
  .footer {
    text-align: center
  }
}

.right-footer-wrap {
  display: flex;
  justify-content: flex-end;
  
  span {
    padding-right: 10px;
    cursor: pointer;
    padding-top: 12px;
  }
}
.header-options-trans  ::v-deep .lls-transfer-panel {
    height: 365px;
}
</style>
