/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Message, MessageBox } from 'link-ui-web';
import globalUrl from '@/common/global_variable';

let isHasTips = false;

axios.defaults.timeout = 120000;// 设置超时时间，单位毫秒
axios.interceptors.request.use(
  (config: any) => {
    const requestConfig = config;
    // if (window.location.href.indexOf('front') > -1) {
    if (requestConfig.url.match('/china.json')) return requestConfig;
    requestConfig.url = `spyPc-web${requestConfig.url}`;
    // }
    return requestConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    /* eslint-disable */
    const { url } = response.config;
    const { code } = response.data;
    let isMediaFile = false;
    Object.entries(globalUrl).forEach(([key, value]) => {
        // console.log(response.config.url, value)
        if (url && url.indexOf(value) >= 0) {
            isMediaFile = true;
        }
    })
    response.body = response.data // 为了影像树
    if (isMediaFile) {
        isHasTips = false;
        return Promise.resolve(response);
    }
    if (code === '200') {
      isHasTips = false;
      return Promise.resolve(response.data);
    }

    { // 兼容后端返回体不为json的bug
      if (code === undefined) {
        return null;
      }
      const { message } = response.data;
      if (code !== '203') {
        if (code === '01100' && !isHasTips) {
          // Message.error({
          //   type: 'error',
          //   message,
          // });
          isHasTips = true;
          MessageBox.confirm(message, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
            showCancelButton: false,
          }).then(() => {
            window.location.replace('/#/homeWebpage?isLogin=true');
        }).catch(() => {
          window.location.replace('/#/homeWebpage?isLogin=true');
          });

        } else  {
          if(code === '01100' && isHasTips) {
            // do nothing
          } else {
            // Message.error({ type: 'error', message: message});
            MessageBox.confirm(message, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error',
              showCancelButton: false,
            }).then(() => {
              // window.location.replace('/#/homeWebpage?isLogin=true');
            }).catch(() => {
              // this.$message({
              //   type: 'info',
              //   message: '已取消'
              // });          
            });
          }
        }
      }
      return Promise.reject(response);
    }
  },
  (error) => Promise.reject(error)
  ,
);

const HTTP = {
  get(url: string, param: object | null) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: param,
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  post(url: string, param: object | null) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: param,
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  put(url: string, param: object | null) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url,
        data: param,
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  delete(url: string, param: object | null) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url,
        data: param,
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default HTTP;
