<template>
<!-- eslint-disable  -->
  <div class="pdf-preview">
    <iframe id="iframe" width="100%" height="100%" scrolling="no" :src='pdfPath + "pdf/web/viewer.html?file=" + url'></iframe>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PdfPreview',
  props: {
    pdfPath: {
      type: String,
      default: '/',
    },
    url: {
      type: String,
    },
  },
  // 如果 props 是一个文件信息，可以在下面拼接好资源的URL再加载
  // computed: {
  //   url () {
  //     return resourceUrl + `api/documents/${this.file.id}/preview/${this.file.name}.pdf`
  //   }
  // },
  mounted() {
    const _this = this;
    // 不需要的工具可以在 iframe 加载完成之后隐藏
    this.$nextTick(() => {
      const iframe = document.getElementById('iframe');
      iframe.onload = function () {
        // 隐藏部分工具
        // iframe.contentWindow.document.getElementById('openFile').style.display = 'none'
        // iframe.contentWindow.document.getElementById('print').style.display = 'none'
        // iframe.contentWindow.document.getElementById('download').style.display = 'none'
        iframe.contentWindow.document.getElementById('viewBookmark').style.display = 'none';
        iframe.contentWindow.document.getElementById('secondaryToolbarToggle').style.display = 'none';
        iframe.contentWindow.document.getElementById('toolbarViewer').style.display = 'none';
        iframe.contentWindow.viewerContainer.onscroll = (e) => {
          // console.log(e)
          // console.log(e.target)
          // console.log(e.target.offsetHeight)
          // console.log(e.target.scrollTop)
          // console.log(e.target.scrollHeight)
          const { offsetHeight } = e.target;
          const { scrollTop } = e.target;
          const { scrollHeight } = e.target;
          if (offsetHeight + scrollTop - scrollHeight >= -1) {
            _this.$emit('pdfStatusHandle', true);
          }
          console.log(offsetHeight + scrollTop - scrollHeight >= -1);
        };
      };
    });
  },

};
</script>

<style lang="scss" scoped>
.pdf-preview {
  width: 100%;
  height: 100%;
}
</style>
