<template>
  <div class="lls-agreement-warpper" v-if="show">
    <div class="lls-agreement__mask"></div>
    <div class="lls-agreement" :style="{width, height}">
      <div class="lls-agreement__header">
        <span>{{ contractName }}</span>
        <i v-if="close" class="lls-icon-close" @click="handleclose(buttonCancel.onclick)"></i>
      </div>
      <div :class="['lls-agreement__body', needSign && 'has-checkbox', false && srcList && 'has-tabs']">
        <div class="lls-agreement__body-content">
          <pdf-preview :pdfPath="pdfPath" :url="fileSrc" @pdfStatusHandle="pdfStatusHandle"></pdf-preview>
          <!-- 阅读状态 -->
          <div class="readStatus" v-show="this.srcList[this.active].readStatus === true">
            <span>
              <i class="lls-icon-success" style="color: #1bc65c;margin-right: 3px;"></i>
            </span>
            <span>已完成</span>
          </div>
        </div>

      </div>
      <div class="lls-agreement__footer" style="justify-content: space-between;">
        <div style="margin-top: 10px;position: relative;">
           <i class="lls-icon-info" style="color: #fe9d00;position: absolute;top: 1px;"></i><span style="font-size:12px;margin-left:23px">请下拉完整阅读每一份协议后，点击确定。</span>
        </div>
        <div>
          <lls-button type="primary" plain  @click="handleDownload()">下载</lls-button>
          <lls-button type="primary" plain v-if="threeButton&&(isLastSrc)"  @click="changeHandle()">切换</lls-button>
          <lls-button type="primary" :plain="threeButton&&(!isLastSrc)" @click="handleclose(buttonCancel.onclick)">{{  '确定' }}</lls-button>
          <lls-button v-if="threeButton&&(!isLastSrc)" type="primary" @click="changeHandle()">切换</lls-button>
        </div>
        <!-- <lls-button type="primary" plain @click="handleclose(buttonCancel.onclick)">{{ buttonCancel.text || '确定' }}</lls-button> -->

      </div>
    </div>
  </div>
</template>

<script>
import { Button, Checkbox } from 'link-ui-web';
import PdfPreview from './PdfPreview.vue';
/* eslint-disable */
export default {
  name: 'custAgreement',

  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    PdfPreview,
  },

  props: {
    src: String,
    srcList: Array,
    type: String,
    width: {
      type: String,
      default: '800px',
    },
    height: {
      type: String,
      default: '80vh',
    },
    title: {
      type: String,
      default: '协议',
    },
    buttonCancel: {
      type: Object,
      default() {
        return {};
      },
    },
    // buttonConfirm: {
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // },
    close: {
      type: Boolean,
      default: true,
    },
    needSign: {
      type: Boolean,
      default: true,
    },
    signText: Array,
    agree: {
      type: Boolean,
      default: false,
    },
    visible: Boolean,
    waitTime: Number,
    checkedChange: Function,
    pdfPath: {
      type: String,
      default: '/',
    },
    initActive:Number
  },

  data() {

    return {
      fileSrc: '',
      checked: false,
      loading: false,
      show: false,
      active: 0,
      waiting: false,
      second: 0,
      timeoutID: null,
      readStatus: false,
    };
  },



  watch: {
    show(newVal) {
      if (newVal && this.waitTime) {
        this.setWaitingText('show');
      }
      // if(!newVal){
      //   if(this.srcList.length === 1 && !this.srcList[0].readStatus){
      //     this.$message.info('请完整阅读完成相关合同')
      //   }
      //   if(this.srcList.length > 1){
      //     let status = this.srcList.every(item=>item.readStatus)
      //     if(!status){
      //       this.$message.info('请阅读完所有合同文本')
      //     }
      //   }
      // }
    },
    visible(newVal) {
      this.show = newVal;
    },
    src: {
      handler(newVal) {
        this.fileSrc = newVal;
      },
      immediate: true,
    },
    srcList: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          // newVal = newVal.map(item=>{
          //   return {...item, readStatus: false}
          // })
          this.fileSrc = newVal[this.active].src;
        }
      },
      immediate: true,
    },
    checked(newVal) {
      this.checkedChange && this.checkedChange(newVal);
    },
    initActive(val){
      this.active=val
      this.fileSrc = this.srcList[this.active].src;
    }
  },

  methods: {
    changeHandle() {
      this.active++;
      if (this.active == this.srcList.length) {
        this.active = 0;
      }
      this.srcList = this.srcList.map((item) => {
        if ([undefined, null].includes(item.readStatus)) {
          return { ...item, readStatus: false };
        }
        return { ...item };
      });
      // if([undefined,null].includes(this.srcList[this.active].readStatus)){
      //   this.srcList[this.active].readStatus = false
      // }
      this.fileSrc = this.srcList[this.active].src;
      console.log(this.fileSrc);
    },
    pdfStatusHandle(status) {
      this.$set(this.srcList[this.active], 'readStatus', status);
      this.readStatus = this.srcList.every((item) => item.readStatus);
      console.log(this.srcList[this.active].readStatus, 'status123');
    },
    handleclose(callback) {
      callback && callback(this.readStatus, this.srcList);
      this.show = false;
      if (this.waitTime) this.setWaitingText('hide');
      this.$emit('close');
    },
    handleDownload() {
      console.log(this.active,'===this.srcList.length===')
      const currentSrc = this.srcList[this.active];
      const {src,title} = currentSrc
      const params = {
        //  fileName: title,
         url: src
      }
      // this.$http.post('/file/op/download',params)
      const loading = this.$loading({ lock: true });
      fetch('/spyPc-web/file/op/download', {
        method: 'post',
        body: JSON.stringify(params),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.blob().then((blob) => {
        const dom = document.createElement('a');
        const downUrl = window.URL.createObjectURL(blob);
        dom.href = downUrl;
        let filename = res.headers.get('Content-Disposition');
        if (filename) filename = filename.replace(/.*filename="(.*[^"])".*/, '$1');
        dom.download = decodeURIComponent(filename);
        dom.style.display = 'none';
        document.body.appendChild(dom);
        dom.click();
        dom.parentNode.removeChild(dom);
        window.URL.revokeObjectURL(url);
      })).finally(loading.close());
    },
    handleConfirm(callback, promiseCb) {
      if (promiseCb) {
        const cb = (status) => {
          if (status === 'suc') {
            this.loading = false;
            callback && callback();
            this.handleclose();
          } else {
            this.loading = false;
          }
        };
        promiseCb(cb);
        this.loading = true;
      } else {
        callback && callback();
        this.handleclose();
      }
    },
    // getType(src) {
    //   if (this.srcList && this.srcList[this.active].type) {
    //     return this.srcList[this.active].type
    //   }
    //   if (this.type) {
    //     return this.type
    //   }
    //   if (/.pdf$|.PDF$/.test(src)) {
    //     return 'pdf'
    //   } else {
    //     return 'img'
    //   }
    // },
    handleTabClick(src, index) {
      this.fileSrc = src;
      this.active = index;
    },
    setWaitingText(status) {
      if (status === 'show') {
        this.second = this.waitTime;
        this.countDown();
      } else {
        clearTimeout(this.timeoutID);
        this.waiting = false;
        this.second = 0;
      }
    },
    countDown() {
      this.waiting = true;
      this.timeoutID = setTimeout(() => {
        this.second--;
        if (this.second <= 0) {
          this.waiting = false;
        } else {
          this.countDown();
        }
      }, 1000);
    },
    setSrc(src) {
      this.src = src;
    },
    setSrcList(srcList) {
      this.srcList = srcList;
    },
  },

  created() {
    this.checked = this.agree;
    console.log('?',this.srcList)
  },
  mounted() {
    console.log('initActive',this)
    console.log('?',this.srcList)
  },
  computed: {
    contractName() {
      return !this.title ? this.srcList[this.active].title : this.title;
    },
    threeButton(){
      return this.srcList && this.srcList.length >= 2
    },
    isLastSrc(){
      return this.active == this.srcList.length-1;
    }
  },

};
</script>

<style lang="scss">
.lls-agreement-warpper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  .lls-agreement__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .5;
  }
}
.lls-agreement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 9px rgba(0,0,0,.13);
  color: #333;
  box-sizing: border-box;
  .lls-agreement__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 0 20px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    font-weight: 700;
    &>i {
      font-size: 16px;
      color: #909399;
      cursor: pointer;
    }
  }
  .lls-agreement__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    border-top: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    margin: 0 20px;
  }
  .lls-agreement__body {
    position: relative;
    box-sizing: border-box;
    padding: 10px 20px;
    height: calc(100% - 90px);
    &.has-checkbox {
      padding-bottom: 28px;
    }
    &.has-tabs {
      padding-top: 50px;
    }
    .lls-checkbox {
      position: absolute;
      bottom: 5px;
      left: 20px;
    }
    .lls-agreement__tabs {
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      display: flex;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      border-bottom: 1px solid #f0f0f0;
      overflow-x: auto;
      .lls-agreement__tabs-item {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        height: 100%;
        white-space: nowrap;
        cursor: pointer;
        &+.lls-agreement__tabs-item {
          margin-left: 20px;
        }
        &.active {
          color: #0887FF;
          border-bottom: 2px solid #0887FF;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(50% - 4px);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #0887ff;
          }
        }
      }
    }
    .lls-agreement__body-content {
      width: 100%;
      height: 100%;
      .lls-agreement__body-img {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}
::-webkit-scrollbar-track-piece {
    background-color:#ffffff;
}
::-webkit-scrollbar {
    width:8px;
    height:8px;
}
::-webkit-scrollbar-thumb {
    background-color:#bbbbbb;
    border-radius: 5px;
    min-height:28px;
}
::-webkit-scrollbar-thumb:hover {
    background-color:#999999;
}
.readStatus{
  position: absolute;
  top: 14px;
  left: 30px;
  padding: 5px;
  font-size: 12px;
  background-color: #fff;

}
</style>
