import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

});

// import Vue from 'vue';
// import Vuex from 'vuex';

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {
//     userInfo: {}, // 用户信息
//     roleList: [], // 用户角色
//     // wheels: 2,
//   },
//   getters: {
//     // axles: (state) => {
//     //   state.wheels / 2;
//     // }
//   },
//   mutations: {
//     setUserInfo(state: any, userInfo: any) {
//       state.userInfo = userInfo;
//     },
//     setRoleList(state, roleList) {
//       state.roleList = roleList;
//     },
//   },
//   actions: {
//   },
//   modules: {
//   },
// });
