<!--
    名称：OCR
    描述：AI-OCR识别
    开发者：chenrun
    时间：2020-02-27
 -->

<style scoped>
/* 滚动条美化 */
::-webkit-scrollbar-track-piece {
  /*滚动条凹槽的颜色，还可以设置边框属性*/
  background-color: #ffffff;
}
::-webkit-scrollbar {
  /* 滚动条宽度 */
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  /*外层轨道*/
  /*border-left: 1px solid #d9d9d9;*/
  /*width: 20px;*/
}
::-webkit-scrollbar-thumb {
  /*滚动条的设置*/
  background-color: #bbbbbb;
  /*background-clip:padding-box;*/
  border-radius: 5px;
  min-height: 28px;
}
::-webkit-scrollbar-button {
}
::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

.clipcursor {
  cursor: crosshair;
  background-color: rgba(0, 0, 0, 0.3);
}
.nocursor {
  background-color: transparent;
  opacity: 1;
}
.clipcanvas {
  position: absolute;
  top: 0px;
  z-index: 1;
}

/* 启动&&识别结果 */
.ocr-start-box {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 100px;
  right: 22px;
  z-index: 1;
  background: url(assets/start.svg) no-repeat center center;
  cursor: pointer;
  opacity: 0.2;
  background-size: 100%;
}
.ocr-result-btn {
  width: 50px;
  height: 50px;
  border: 1px solid #00a0e4;
  box-shadow: 0px 2px 6px 1px rgba(151, 225, 253, 0.44);
  color: #00a0e4;
  font-size: 12px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#fff 0, rgba(219, 245, 255, 1) 100%);
  opacity: 0.2;
}
.ocr-start-box:hover {
  opacity: 1;
}
.ocr-result-btn:hover {
  opacity: 1;
}

/* 操作区 */
.ocr-oper-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
/* 遮罩层 */
.oper-mark-row {
  background-color: #9b9b9b;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: crosshair;
}
/* 按钮区 */
.oper-btn-row {
  position: fixed; /*top: 15px;right: 10px;*/
  display: flex;
  right: 30px;
  margin-top: 15px;
  z-index: 2;
}
.btn-result {
  height: 28px;
  font-size: 12px;
  color: #00a0e4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 14px;
  position: relative;
  transition: all 0.3s;
  right: 30px;
  opacity: 0;
}
.btn-result .icon-ocr {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background: url(assets/i-ocr.svg) no-repeat center center;
}
.btn-resultact {
  right: 0px;
  opacity: 1;
}
.btn-icon {
  width: 28px;
  height: 34px;
  cursor: pointer;
  margin-left: 4px;
}
.icon-ques {
  background: url(assets/i-ques.svg) no-repeat center center;
}
.icon-close {
  background: url(assets/i-close.svg) no-repeat center center;
}
.llstips-box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  text-align: left;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  white-space: normal;
  max-width: 250px;
  line-height: normal;
  top: 50px;
  right: -6px;
  width: 200px;
  opacity: 0;
  transition: all 0.3s;
}
.llstips-box-act {
  opacity: 1;
  top: 43px;
}
.llstips-box-acttop {
  opacity: 1;
  right: 68px !important;
}
.llstips-arrow {
  border-right: 10px solid transparent;
  border-bottom: 10px solid #444;
  border-left: 10px solid transparent;
  top: -10px;
  left: calc(100% - 30px);
  position: absolute;
}
.llstips-arrowtop {
  border-right: 10px solid transparent;
  border-top: 10px solid #444;
  border-left: 10px solid transparent;
  bottom: -8px;
  left: calc(50% - 10px);
  position: absolute;
}
.llstips-arrowright {
  border-top: 10px solid transparent;
  border-left: 10px solid #444;
  border-bottom: 10px solid transparent;
  right: -10px;
  top: calc(50% - 10px);
  position: absolute;
}
/* 标签区 */
.oper-tag-row {
  position: fixed; /*top: 15px;left: 10px;*/
  overflow: hidden;
  margin: 15px 0 0 15px;
  z-index: 2;
}
.oper-tab-in {
  width: calc(100% + 20px);
  overflow: auto;
  padding-right: 20px;
}
.tag-title {
  font-size: 12px;
  color: #fff;
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  height: 22px;
}
.tag-title2 {
  cursor: pointer;
  margin-bottom: 0px;
}
.icon-more {
  width: 22px;
  height: 22px;
  background: url(assets/i-more.svg) no-repeat center center;
  cursor: pointer;
  transition: all 0.3s;
}
.im-down {
  transform: rotate(180deg);
}
.more-tag-box {
  height: 0px;
  transition: all 0.3s;
  overflow: hidden;
  padding-top: 4px;
}
.more-show {
  height: 100%;
}
.tag-row {
  display: flex;
  flex-direction: column;
}
.tag-item-out {
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  transition: all 0.3s;
  position: relative;
}
.tag-item-out-act {
  padding: 0px;
}
.tag-item {
  height: 28px;
  background-color: #000;
  opacity: 0.6;
  border: 1px solid #000;
  border-radius: 14px;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  width: 128px;
}
.son-tag {
  cursor: default;
}
.tag-item-act {
  height: 34px;
  border-radius: 17px;
  background-color: #00a0e4;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  opacity: 1;
  width: 138px;
}
.tag-item-add {
  width: 0px;
  transition: all 0.3s;
  overflow: hidden;
  margin-left: 0px;
  display: flex;
  align-items: center;
  height: 14px;
}
.tag-add-line {
  width: 1px;
  height: 10px;
  border-left: 1px solid #fff;
  margin-left: 5px;
}
.tag-add-icon {
  background: url(assets/i-add.svg) no-repeat center center;
  width: 16px;
  height: 16px;
  margin: 0 2px 0 6px;
}
.tag-itemadd-out:hover {
  width: 180px;
}
.tag-itemadd-out:hover .tag-item-add {
  width: 80px;
}
.icon-ok {
  width: 14px;
  height: 14px;
  background: url(assets/i-ok.svg) no-repeat center center;
  position: absolute;
  right: 0px;
  top: -8px;
}
.icon-remove {
  width: 16px;
  height: 16px;
  background: url(assets/i-remove.svg) no-repeat center center;
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}
.icon-must {
  width: 14px;
  height: 14px;
  background: url(assets/i-must.svg) no-repeat center center;
  position: absolute;
  right: 0px;
  top: -5px;
}
.tag-ocrstate {
  color: #fff;
  font-size: 12px;
  height: 20px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  padding-left: 10px;
}
/*.tag-ocrstate-son{position: absolute;top: 32px;left: 0px;}*/
.tag-stateact {
  height: 0px;
  margin-bottom: 0px;
}
.icon-finish {
  background: url(assets/i-finish.svg) no-repeat left center;
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
/* 提示层 */
.oper-msg-row {
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.oper-msg {
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
  color: #00a0e4;
  padding: 0 20px 0 40px;
  background: url(assets/i-msg.svg) no-repeat 20px center;
  background-color: #fff;
  line-height: 32px;
  box-sizing: border-box;
  width: max-content;
  transition: all 0.3s;
  opacity: 0;
  margin-top: -30px;
}
.show-msg {
  opacity: 1;
  margin-top: 13px;
}
.hide-msg {
  opacity: 0;
  margin-top: -30px;
}
/* 图片预览区 */
.ocr-img-box {
  cursor: move;
  position: absolute;
  border: 2px solid #00a0e4;
  z-index: 2;
  box-sizing: content-box;
}
.ocr-img-boxact {
  transition: all 0.3s;
  width: 0px !important;
  height: 0px !important; /*border-radius:0 200% 100% 200%;*/
  overflow: hidden;
  border: 0px solid #00a0e4;
}
.ocr-img-box img {
  width: 100%;
  height: 100%;
}
.ocr-img-box .img-remove {
  width: 14px;
  height: 14px;
  background: url(assets/i-remove.svg) no-repeat center center;
  position: absolute;
  top: -14px;
  right: -14px;
  cursor: pointer;
}

/* 结果区 */
.ocr-result-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 500px;
  position: fixed;
  top: 110px;
  left: 200px;
  z-index: 10;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.04);
  border: 1px solid #d9d9d9;
  flex-direction: column;
}
/* 标题 */
.ocr-result-box .result-row {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  color: #333;
  font-size: 14px;
  padding-left: 8px;
  cursor: move;
}
.result-row .result-close {
  width: 20px;
  height: 20px;
  background: url(assets/close.svg) no-repeat center center;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 8px;
}
.result-row .result-close:hover {
  transform: rotate(180deg);
  background: url(assets/close_act.svg) no-repeat center center;
}
/* 正文 */
/* 基础信息 */
.binfo-box {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.binfo-row {
  display: flex;
  font-size: 12px;
  height: 30px;
  align-items: center;
  width: 50%;
}
.binfo-lab {
  color: #999;
  margin-right: 8px;
  width: 100px;
  text-align: right;
}
.binfo-val {
  color: #333;
  width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* ocr结果 */
.ocr-result-box .result-body {
  height: calc(100% - 80px);
  padding: 6px 12px;
  overflow: auto;
  width: 100%;
  position: relative;
}
.result-body .body-title {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  color: #333;
  position: relative;
}
.title-line {
  width: 4px;
  height: 12px;
  border-radius: 2px;
  background-color: #00a0e4;
  margin-right: 4px;
}
.title-reload {
  position: absolute;
  right: 0px;
  background: url(assets/refu.svg) no-repeat left center;
  color: #00a0e4;
  cursor: pointer;
  padding-left: 18px;
  font-size: 12px;
}
.body-checktag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.checktag-item {
  height: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  background-color: #fff;
  line-height: 28px;
  box-sizing: border-box;
  text-align: center;
  color: #333;
  font-size: 12px;
  padding: 0 12px;
  margin: 0px 4px 4px 0;
  position: relative;
}
.checktag-ok {
  position: absolute;
  top: -7px;
  right: -4px;
  background: url(assets/i-ok.svg) no-repeat center center;
  width: 14px;
  height: 14px;
}
.body-checkres {
  display: flex;
  margin-bottom: 10px;
}
.checkres-empty {
  font-size: 12px;
  text-align: center;
  padding: 10px 0px 20px;
}
.checkres-left {
  width: calc(100% - 90px);
  overflow: auto;
}
.checkres-left table {
  width: 100%;
}
.body-checkres thead tr {
  height: 40px;
  background-color: #f1f2f3;
  color: #333;
  font-size: 12px;
}
.body-checkres tbody tr {
  height: 40px;
  background-color: #ffffff;
  color: #333;
  font-size: 12px;
}
.body-checkres tbody tr th {
  padding: 0 10px;
  font-weight: 200;
  border-bottom: 1px solid #d9d9d9;
}
.checkres-right {
  width: 90px;
  z-index: 1;
}
.right-item-lab {
  height: 40px;
  background-color: #f1f2f3;
  color: #333;
  font-size: 12px;
  box-sizing: border-box;
  line-height: 40px;
  text-align: center;
  padding: 0 10px;
  font-weight: 700;
  box-shadow: -2px 0 3px -1px #ddd;
}
.right-item {
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  padding-left: 30px;
  font-size: 12px;
  box-shadow: -2px 0 3px -1px #ddd;
}
.right-item-loading {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 !important;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
}
.item-ok {
  background: url(assets/i-ok.svg) no-repeat 12px center;
}
.item-err {
  background: url(assets/i-err.svg) no-repeat 12px center;
}
.ocr-item {
  height: 26px;
  line-height: 26px;
  box-sizing: border-box;
  text-align: center;
  padding: 0 10px;
  background-color: #eeeeee;
  color: #333;
  font-size: 12px;
  margin: 0 4px 4px 0;
  border-radius: 4px;
}
.ocrtag-ok {
  background-color: #def5ff;
  color: #00a0e4;
}
.ocr-lab-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ocr-lab-left {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px;
}
.ocr-lab-left div:nth-child(2) {
  height: 18px;
  padding: 0 4px;
  line-height: 18px;
  box-sizing: border-box;
  text-align: center;
  font-size: 10px;
  margin-left: 6px;
  border-radius: 3px;
}
.lab-ok {
  background-color: #f3fcf6;
  color: #34c46b;
  border: 1px solid #34c46b;
}
.lab-err {
  background-color: #fdefee;
  color: #df2d23;
  border: 1px solid #df2d23;
}
.ocr-del {
  color: #666;
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  padding-left: 18px;
  margin-right: 25px;
}
.ocr-del:hover {
  color: #00a0e4;
}
.ocr-res-row {
  height: 30px;
  display: flex;
  margin: 4px 0;
}
.ocr-res-row input {
  width: calc(100% - 100px);
  height: 100%;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ocr-res-row .input-dis {
  width: calc(100% - 30px) !important;
}
.ocr-res-row .ocr-upd {
  width: 56px;
  height: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f6f6f6;
  color: #999999;
  font-size: 12px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  box-sizing: border-box;
  margin-left: 7px;
}
.ocr-res-row .ocr-updact {
  border: 1px solid #00a0e4;
  background-color: #fff;
  color: #00a0e4;
}
.ocr-res-row .ocr-icon {
  width: 30px;
  height: 30px;
  border: 1px solid #cfcfcf;
  background: #e7e7e7 url(assets/i-ocricondef.svg) no-repeat center center;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 7px;
}
.ocr-res-row .ocr-iconact {
  background: #e7e7e7 url(assets/i-ocriconact.svg) no-repeat center center;
}
.ocr-img {
  background-color: #f4f4f4;
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: auto;
  text-align: center;
  height: 0px;
  transition: all 0.3s;
}
.ocr-img-show {
  height: 62px;
  padding: 10px;
}
.check-upd-text {
  background: url(assets/i-checkupd.svg) no-repeat left center;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
  padding-left: 16px;
  width: 110px;
  margin: auto;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
}
.check-upd-text:hover {
  color: #00a0e4;
  background: url(assets/i-checkupd_act.svg) no-repeat left center;
}
/* 核查和更新记录 */
.upd-item {
  display: flex;
}
.upd-left {
  width: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.upd-left div:nth-child(1) {
  width: 2px;
  height: 8px;
  background-color: #e1e1e1;
}
.upd-left div:nth-child(2) {
  width: 8px;
  height: 8px;
  background-color: #e1e1e1;
  border-radius: 4px;
}
.upd-left div:nth-child(3) {
  width: 2px;
  height: calc(100% - 16px);
  background-color: #e1e1e1;
}
.upd-right {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.upd-record {
  display: flex;
  color: #333;
  font-size: 14px;
  height: 24px;
  align-items: center;
}
.upd-record div {
  width: 24px;
  height: 24px;
  background: url(assets/i-change.svg) no-repeat center center;
  margin: 0 5px;
}
.upd-info {
  display: flex;
  color: #999;
  font-size: 12px;
  margin-top: 4px;
  width: 70%;
  justify-content: space-between;
  margin-bottom: 14px;
}
/* 按钮 */
.ocr-result-box .result-oper {
  display: flex;
  height: 40px;
  align-items: center;
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  justify-content: center;
}
.result-oper div {
  margin: 0 4px;
}
/* 遮罩层 */
.ocr-result-box .result-mark {
  position: absolute;
  top: 0px;
  height: 0px;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.ocr-result-box .result-loading {
  position: absolute;
  z-index: 2;
}
</style>

<template>
  <div v-show="!isDisabled" ref="ocrBox">
    <!-- canvas图片缩放操作层 -->
    <!-- <canvas id="scalecanvas" class="clipcanvas" style="opacity:0"></canvas> -->
    <!-- canvas图片加载层 -->
    <canvas
      id="signature"
      class="clipcanvas"
      :class="{ nocursor: isMove }"
      :style="'transform:rotate(' + rotateDeg + 'deg);'"
      :key="timestamp"
      v-if="ocrType == 'image'"
      >您的浏览器还不支持canvas</canvas
    >
    <!-- canvas图片裁剪层 -->
    <canvas
      id="clipcanvas"
      class="clipcanvas"
      :class="{ clipcursor: pulseFlag, nocursor: isMove }"
      >您的浏览器还不支持canvas</canvas
    >
    <!-- 启动按钮 -->
    <div
      class="ocr-start-box"
      @mouseover="tipIndex = 1"
      @mouseleave="tipIndex = -1"
      ref="start"
      @click="pulseOCR($event)"
      v-show="!operFlag && tagList.length > 0 && !readonly"
    >
      <div
        class="llstips-box"
        style="right: 80px; top: -2px; width: 116px"
        :class="{ 'llstips-box-acttop': tipIndex == 1 }"
      >
        <span>OCR识别工具，点击开始框选内容</span>
        <div class="llstips-arrowright"></div>
      </div>
    </div>
    <!-- 识别结果 -->
    <div
      class="ocr-result-btn"
      @click="queryOcrResult"
      v-show="!isMove && tagList.length > 0"
    >
      识别<br />结果
    </div>
    <!-- 操作区 -->
    <div class="ocr-oper-box" v-if="operFlag" ref="operBox">
      <!-- 遮罩层 -->
      <!-- <div class="oper-mark-row" :style="{'height':canvasH+'px'}"></div> -->
      <!-- 按钮区 -->
      <div class="oper-btn-row">
        <!-- <div>
                    <div class="btn-result" @mouseover="inTo(2)" @mouseleave="inTo(-1)" ref="result" @click="queryOcrResult" :class="{'btn-resultact':isHasResult()}">
                        <div class="icon-ocr"></div>OCR识别结果
                    </div>
                    <div class="llstips-box" style="right:-11px;width:186px;" :class="{'llstips-box-act':tipIndex==2&&isHasResult()}" v-show="showEsc">
                        <span>点击此处可查看OCR识别结果</span>
                        <div class="llstips-arrow" style="left:calc(50% - 10px)"></div>
                    </div>
                </div> -->
        <!-- <div class="btn-icon icon-ques" @mouseover="tipIndex=3" @mouseleave="tipIndex=-1" @click="queryQuestion">
                    <div class="llstips-box" :class="{'llstips-box-act':tipIndex==3}">
                        <span>点击此处或使用【Esc】快捷键，可退出截图模式</span>
                        <div class="llstips-arrow" style="left:calc(100% - 60px)"></div>
                    </div>
                </div> -->

        <div>
          <div
            class="btn-icon icon-close"
            @mouseover="inTo(4)"
            @mouseleave="inTo(-1)"
            @click="closeOCR"
          ></div>
          <div
            class="llstips-box"
            :class="{ 'llstips-box-act': tipIndex == 4 }"
            v-show="showEsc"
          >
            <span>点击此处或使用【Esc】快捷键，可退出截图模式</span>
            <div class="llstips-arrow"></div>
          </div>
        </div>
      </div>
      <!-- 标签区 -->
      <div
        class="oper-tag-row"
        :style="{ opacity: isMove ? '0.1' : '1' }"
        v-show="!tagHideFlag"
      >
        <div class="oper-tab-in" :style="{ height: tagHeight }">
          <div class="tag-title">本页需框选内容：</div>
          <template v-for="(item, index) in tagList">
            <div v-if="item.majorKey == 'Y'" :key="index">
              <div class="tag-row">
                <div
                  class="tag-item-out"
                  :class="{ 'tag-item-out-act': actFlag == item.desc }"
                >
                  <div
                    class="tag-item tag-itemadd-out"
                    :class="{ 'tag-item-act': actFlag == item.desc }"
                    name="tagItem"
                    :data-key="item.desc"
                  >
                    {{ item.desc }}
                    <!-- 添加同源标签 -->
                    <div
                      class="tag-item-add"
                      @click="addTag($event, item, index)"
                    >
                      <div class="tag-add-line"></div>
                      <div class="tag-add-icon"></div>
                      添加标签
                    </div>
                    <!-- OCR完成的标记 -->
                    <div class="icon-ok" v-if="item.num > 0"></div>
                  </div>
                </div>
                <!-- 同源标签 -->
                <template v-for="(sonItem, sonIndex) in item.sonTags">
                  <div
                    class="tag-item-out"
                    :class="{ 'tag-item-out-act': actFlag == sonItem.desc }"
                  >
                    <div
                      class="tag-item son-tag"
                      :class="{ 'tag-item-act': actFlag == sonItem.desc }"
                      name="tagItem"
                      :data-key="sonItem.desc"
                    >
                      {{ sonItem.desc }}
                      <!-- 删除 -->
                      <div
                        class="icon-remove"
                        @click="removeTag($event, index, sonIndex)"
                        title="移除"
                      ></div>
                      <!-- OCR完成的标记 -->
                      <div
                        class="icon-ok"
                        v-if="sonItem.num > 0"
                        style="right: 8px"
                      ></div>
                    </div>
                  </div>
                  <!-- 识别状态 -->
                  <div
                    class="tag-ocrstate tag-ocrstate-son"
                    :class="{ 'tag-stateact': sonItem.state == '' }"
                  >
                    <llsLoading
                      radius="6"
                      color="#fff"
                      style="margin-right: 4px"
                      v-if="sonItem.state == 'ing'"
                    ></llsLoading>
                    <div
                      class="icon-finish"
                      v-if="sonItem.state == 'finish'"
                    ></div>
                    {{
                      sonItem.state == "ing"
                        ? "OCR识别中"
                        : sonItem.state == "finish"
                        ? "OCR识别完成"
                        : ""
                    }}
                  </div>
                </template>
              </div>
              <!-- 识别状态 -->
              <div
                class="tag-ocrstate"
                :class="{ 'tag-stateact': item.state == '' }"
              >
                <llsLoading
                  radius="6"
                  color="#fff"
                  style="margin-right: 4px"
                  v-if="item.state == 'ing'"
                ></llsLoading>
                <div class="icon-finish" v-if="item.state == 'finish'"></div>
                {{
                  item.state == "ing"
                    ? "OCR识别中"
                    : item.state == "finish"
                    ? "OCR识别完成"
                    : ""
                }}
              </div>
            </div>
          </template>

          <!-- 更多 -->
          <template>
            <div class="tag-title tag-title2" @click="showMore">
              更多框选内容
              <div class="icon-more" :class="{ 'im-down': moreFlag }"></div>
            </div>
            <div
              class="more-tag-box"
              :style="{ height: moreFlag ? moreHeight : '' }"
            >
              <template v-for="(item, index) in tagList">
                <div :key="index" v-if="item.majorKey == 'N'">
                  <div class="tag-row">
                    <div
                      class="tag-item-out"
                      :class="{ 'tag-item-out-act': actFlag == item.desc }"
                    >
                      <div
                        class="tag-item tag-itemadd-out"
                        :class="{ 'tag-item-act': actFlag == item.desc }"
                        name="tagItem"
                        :data-key="item.desc"
                      >
                        {{ item.desc }}
                        <!-- 添加同源标签 -->
                        <div
                          class="tag-item-add"
                          @click="addTag($event, item, index)"
                        >
                          <div class="tag-add-line"></div>
                          <div class="tag-add-icon"></div>
                          添加标签
                        </div>
                        <!-- OCR完成的标记 -->
                        <div class="icon-ok" v-if="item.num > 0"></div>
                      </div>
                    </div>
                    <!-- 同源标签 -->
                    <template v-for="(sonItem, sonIndex) in item.sonTags">
                      <div :key="sonIndex">
                        <div
                          class="tag-item-out"
                          :class="{
                            'tag-item-out-act': actFlag == sonItem.desc,
                          }"
                        >
                          <div
                            class="tag-item son-tag"
                            :class="{ 'tag-item-act': actFlag == sonItem.desc }"
                            name="tagItem"
                            :data-key="sonItem.desc"
                          >
                            {{ sonItem.desc }}
                            <!-- 删除 -->
                            <div
                              class="icon-remove"
                              @click="removeTag($event, index, sonIndex)"
                              title="移除"
                            ></div>
                            <!-- OCR完成的标记 -->
                            <div
                              class="icon-ok"
                              v-if="sonItem.num > 0"
                              style="right: 8px"
                            ></div>
                          </div>
                        </div>
                        <!-- 识别状态 -->
                        <div
                          class="tag-ocrstate tag-ocrstate-son"
                          :class="{ 'tag-stateact': sonItem.state == '' }"
                        >
                          <llsLoading
                            radius="6"
                            color="#fff"
                            style="margin-right: 4px"
                            v-if="sonItem.state == 'ing'"
                          ></llsLoading>
                          <div
                            class="icon-finish"
                            v-if="sonItem.state == 'finish'"
                          ></div>
                          {{
                            sonItem.state == "ing"
                              ? "OCR识别中"
                              : sonItem.state == "finish"
                              ? "OCR识别完成"
                              : ""
                          }}
                        </div>
                      </div>
                    </template>
                  </div>
                  <!-- 识别状态 -->
                  <div
                    class="tag-ocrstate"
                    :class="{ 'tag-stateact': item.state == '' }"
                  >
                    <llsLoading
                      radius="6"
                      color="#fff"
                      style="margin-right: 4px"
                      v-if="item.state == 'ing'"
                    ></llsLoading>
                    <div
                      class="icon-finish"
                      v-if="item.state == 'finish'"
                    ></div>
                    {{
                      item.state == "ing"
                        ? "OCR识别中"
                        : item.state == "finish"
                        ? "OCR识别完成"
                        : ""
                    }}
                  </div>
                </div>
              </template>
            </div>
          </template>

          <!-- 有更多标签时 -->
          <!-- <template v-if="tagList.length>5">
                        <div class="tag-title tag-title2" @click="showMore">更多框选内容<div class="icon-more" :class="{'im-down':moreFlag}"></div></div>
                        <div class="more-tag-box" :class="{'more-show':moreFlag}">
                            <template v-for="(item,index) in tagList" v-if="index > 4">
                                <div class="tag-item-out" :class="{'tag-item-out-act':actFlag==item.desc}">
                                    <div class="tag-item" :class="{'tag-item-act':actFlag==item.desc}" name="tagItem":data-key='item.desc'>{{item.desc}}
                                        <template v-if="moreFlag">
                                            <div class="icon-ok" v-if="item.num>0"></div>
                                        </template>
                                    </div>
                                </div>
                                <div class="tag-ocrstate" :class="{'tag-stateact':item.state==''}">
                                    <llsLoading radius="6" color="#666" style="margin-right:4px;" v-if="item.state=='ing'"></llsLoading>
                                    <div class="icon-finish" v-if="item.state=='finish'"></div>
                                    {{item.state=='ing' ? 'OCR识别中' : (item.state=='finish' ? 'OCR识别完成' : '')}}
                                </div>
                            </template>
                        </div>
                    </template> -->
        </div>
      </div>
      <!-- 提示层 -->
      <div class="oper-msg-row" :style="{ width: stateMsgWidth + 'px' }">
        <div
          class="oper-msg"
          :class="state == 1 || state == 2 ? 'show-msg' : 'hide-msg'"
        >
          {{ stateMsg }}
        </div>
      </div>
      <!-- 图片预览区 -->
      <div
        class="ocr-img-box"
        :style="{
          width: clipArea.w + 'px',
          height: clipArea.h + 'px',
          top: clipArea.y - 2 + 'px',
          left: clipArea.x - 2 + 'px',
        }"
        v-if="state == 1 || state == 2 || state == 3"
        ref="imgBox"
        :class="{ 'ocr-img-boxact': state == 3 }"
      >
        <img :src="clipImg" />
        <div
          class="img-remove"
          @click="removeClipImg"
          v-show="state != 3"
        ></div>
      </div>
    </div>
    <!-- 结果区 -->
    <div
      class="ocr-result-box"
      ref="resBox"
      v-if="resultFlag"
      :style="{
        width: resultBox.width + 'px',
        height: resultBox.height + 'px',
        left: resultBox.left + 'px',
        top: resultBox.top + 'px',
      }"
    >
      <div class="result-row" ref="resHead">
        <div class="result-title">
          {{
            panel == 1
              ? curOcrObj["desc"]
              : panel == 2
              ? "【" + curOcrObj["desc"] + "】自动化核查"
              : "核查和更新记录"
          }}
        </div>
        <div class="result-close" @click="closeResult"></div>
      </div>
      <div class="result-body" ref="resBody">
        <!-- OCR结果 -->
        <template v-if="panel == 2">
          <div class="body-title">
            <div class="title-line"></div>
            核查要素
          </div>
          <div class="body-checktag">
            <div v-for="item,index in tagList" class="checktag-item" :key="index">
              {{ item.desc }}
              <div class="checktag-ok" v-if="item.num > 0"></div>
            </div>
          </div>
          <div class="body-title">
            <div class="title-line"></div>
            核查结果
            <div
              class="title-reload"
              @click="getOcrSave(true)"
              v-if="!readonly"
            >
              刷新
            </div>
          </div>
          <div class="body-checkres">
            <div class="checkres-left">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>核查要素</th>
                    <th>系统录入值</th>
                    <th>OCR识别值</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(re, index) in checkList" :key="index">
                    <th>{{ re.factor }}</th>
                    <th>{{ re.oriSysValue }}</th>
                    <th>{{ re.ocrValue }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="checkres-right">
              <div class="right-item-lab">自动核查结果</div>
              <div
                v-for="(re, index) in checkList"
                :key="index"
                class="right-item"
                :class="{
                  'item-ok': checkFlag(re) == 'ok',
                  'item-err': checkFlag(re) == 'err',
                }"
              >
                <template v-if="re.result">
                  {{ re.result | filterDic }}
                </template>
                <div class="right-item-loading">
                  <llsLoading
                    radius="6"
                    color="#00A0E4"
                    v-if="!re.result"
                  ></llsLoading>
                </div>
              </div>
            </div>
          </div>
          <div v-if="checkList.length == 0" class="checkres-empty">
            暂无记录
          </div>
          <div class="body-title">
            <div class="title-line"></div>
            OCR识别
          </div>
          <div class="body-checktag">
            <div v-for="item,index in tagList" :key="index">
              <div class="ocr-item" v-if="item.num > 0">{{ item.desc }}</div>
              <div v-for="sonItem, sonIndex in item.sonTags" :key="sonIndex">
                <div class="ocr-item" v-if="sonItem.num > 0">
                  {{ sonItem.desc }}
                </div>
              </div>
            </div>
          </div>
          <div :key="tagIndex" v-for="(item, tagIndex) in tagList">
            <!-- 主标签 -->
            <div v-if="item.num > 0">
              <div class="ocr-lab-row">
                <div class="ocr-lab-left">
                  <div>{{ item.desc }}</div>
                  <!-- <div class="lab-err">不匹配</div> -->
                </div>
                <div
                  class="ocr-del"
                  @click="operOcrItem('del', item, tagIndex, -1)"
                  @mouseover="delIndex = tagIndex + '-1'"
                  @mouseleave="delIndex = -1"
                  :class="
                    delIndex == tagIndex + '-1'
                      ? 'lls-delete-00a'
                      : 'lls-delete'
                  "
                  v-if="!readonly"
                >
                  删除
                </div>
              </div>
              <div class="ocr-res-row">
                <input
                  v-model="item.newtext"
                  :disabled="readonly"
                  :class="{ 'input-dis': readonly }"
                />
                <div
                  class="ocr-upd"
                  :class="{ 'ocr-updact': item.text != item.newtext }"
                  @click="operOcrItem('upd', item)"
                  v-if="!readonly"
                >
                  修改
                </div>
                <div
                  class="ocr-icon"
                  @click="operImg(item)"
                  :class="{ 'ocr-iconact': item.showImg }"
                ></div>
              </div>
              <div class="ocr-img" :class="{ 'ocr-img-show': item.showImg }">
                <img :src="imgItem" v-for="imgItem in item.img" />
              </div>
            </div>
            <!-- 同源标签 -->
            <template
              v-for="(sonItem, sonTagIndex) in item.sonTags"
            >
              <div v-if="sonItem.num > 0" :key="sonTagIndex">
                <div class="ocr-lab-row">
                <div class="ocr-lab-left">
                  <div>{{ sonItem.desc }}</div>
                  <!-- <div class="lab-err">不匹配</div> -->
                </div>
                <div
                  class="ocr-del"
                  @click="operOcrItem('del', sonItem, tagIndex, sonTagIndex)"
                  @mouseover="delIndex = tagIndex + '' + sonTagIndex"
                  @mouseleave="delIndex = -1"
                  :class="
                    delIndex == tagIndex + '' + sonTagIndex
                      ? 'lls-delete-00a'
                      : 'lls-delete'
                  "
                  v-if="!readonly"
                >
                  删除
                </div>
              </div>
              <div class="ocr-res-row">
                <input
                  v-model="sonItem.newtext"
                  :disabled="readonly"
                  :class="{ 'input-dis': readonly }"
                />
                <div
                  class="ocr-upd"
                  :class="{ 'ocr-updact': sonItem.text != sonItem.newtext }"
                  @click="operOcrItem('upd', sonItem)"
                  v-if="!readonly"
                >
                  修改
                </div>
                <div
                  class="ocr-icon"
                  @click="operImg(sonItem)"
                  :class="{ 'ocr-iconact': sonItem.showImg }"
                ></div>
              </div>
              <div class="ocr-img" :class="{ 'ocr-img-show': sonItem.showImg }">
                <img :src="sonImgItem" v-for="sonImgItem in sonItem.img" :key="sonImgItem" />
              </div>
              </div>
            </template>
          </div>
          <!-- 别人的核查记录 -->
          <div v-for="otherItem,otherItemIndex in lastList" :key="otherItemIndex">
            <div class="ocr-lab-row">
              <div class="ocr-lab-left">
                <div>{{ otherItem.labelName }}</div>
              </div>
              <!-- <div class="ocr-del" @click="operOcrItem('del',item,tagIndex,-1)" @mouseover="delIndex=(tagIndex+'-1')" @mouseleave="delIndex=-1" :class="(delIndex==(tagIndex+'-1') ? 'lls-delete-00a' : 'lls-delete')" v-if="!readonly">删除</div> -->
            </div>
            <div class="ocr-res-row">
              <input
                v-model="otherItem.labelValue"
                :disabled="readonly"
                class="input-dis"
              />
              <!-- <div class="ocr-upd" :class="{'ocr-updact':item.text!=item.newtext}" @click="operOcrItem('upd',item)" v-if="!readonly">修改</div> -->
              <div
                class="ocr-icon"
                @click="operImg(otherItem)"
                :class="{ 'ocr-iconact': otherItem.showImg }"
              ></div>
            </div>
            <div class="ocr-img" :class="{ 'ocr-img-show': otherItem.showImg }">
              <img :src="imgItem" v-for="imgItem in otherItem.imgBase64s" />
            </div>
          </div>
          <div class="check-upd-text" @click="queryCheckUpd">
            核查和更新记录
          </div>
        </template>
        <!-- 核查和更新记录 -->
        <template v-if="panel == 3">
          <llsCollapse :title="item.desc" v-for="item in tagList" :key="item.desc">
            <div
              class="upd-item"
              v-for="(re, index) in updList"
              v-if="re.keyFieldId == item.key"
            >
              <div class="upd-left">
                <div
                  :style="{ backgroundColor: index == 0 ? '#fff' : '#E1E1E1' }"
                ></div>
                <div></div>
                <div></div>
              </div>
              <div class="upd-right">
                <div class="upd-record">
                  {{ re.ocrValue
                  }}<!-- <div></div>{{re.sysValue}}-->
                </div>
                <div class="upd-info">
                  <div>{{ re.node }}：{{ re.createBy }}</div>
                  <div>{{ re.updateTime }}</div>
                </div>
              </div>
            </div>
          </llsCollapse>
        </template>
      </div>
      <div class="result-oper" ref="resFoot">
        <llsButton @click="closeResult" v-if="panel == 1" type="hollow"
          >关闭</llsButton
        >
        <llsButton @click="closeResult" v-if="panel == 2" type="hollow"
          >关闭</llsButton
        >
        <llsButton @click="panel = 2" v-if="panel == 3" type="hollow"
          >返回</llsButton
        >
      </div>
      <!-- 遮罩层 -->
      <template v-if="doIng">
        <div
          class="result-mark"
          :style="{
            width: resultBox.width + 'px',
            height: resultBox.height + 'px',
          }"
        ></div>
        <llsLoading color="#ffffff" class="result-loading"></llsLoading>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'llsOcr',
  props: {
    dom: '', // 【必传】，基于该dom结构做OCR识别
    imgUrl: '', // 【必传】，图片路径
    width: '', // 【非必传】,OCR识别区域宽度
    height: '', // 【非必传】，OCR识别区域高度
    rotate: '', // 【非必传】，OCR识别区域旋转度数
    disabled: '', // 【非必传】，是否禁用OCR
    sysCode: '', // 【非必传】，系统编码，默认AMS
    roleKey: '', // 【必传】，岗位编码
    catgId: '', // 【必传】,当前OCR的影像分类编码
    fileId: '', // 【必传】,当前OCR的文件ID
    sysValue: '', // 【必传】，系统原始值
    readonly: '', // 【非必传】，是否只读
    timestamp: '', // 【非必传】，时间戳
    ocrType: '', // 【非必传】,OCR的类型；image-图片；pdf-PDF
    pageCount: '', // 【非必传】，pdf页数
    ocrImgWidth: '', // 【非必传】，OCR原图宽度
    ocrImgHeight: '', // 【非必传】，OCR原图高度
  },
  data() {
    return {
      imgCtx: '',
      pdfCtx: [],
      clipCtx: '',
      pulseFlag: false, // 是否开启ocr
      canvasW: '', // canvas宽度
      canvasH: '', // canvas高度
      start: null, // 裁剪区域起始坐标
      end: null, // 裁剪区域终点坐标
      clipArea: '', // 裁剪区域
      clipImg: '', // 裁剪的图片
      scaleImg: '', // 裁剪的图片（缩放后的）
      isMove: false, // 是否裁剪中
      tagHideFlag: false, // 是否隐藏tag
      // 结果的
      resultW: '',
      resultH: '',
      rotateDeg: this.rotate || 0,
      isDisabled: false, // 是否禁用
      scale: 1, // 缩放比例
      urlAI: this.GLOBAL.aiDetection, // OCR识别接口'http://qa.ai.lls.com/img-detection/api/v1/boxsOcr'
      urlOcrRole: this.GLOBAL.ocrRole, // OCR-获得系统岗位
      urlOcrTag: this.GLOBAL.ocrTags, // OCR-获取待识别的标签
      urlOcrSave: this.GLOBAL.ocrSave, // OCR-识别结果回传
      urlOcrCheckResult: this.GLOBAL.ocrCheckResult, // OCR-核查结果
      urlOcrUpdateText: this.GLOBAL.ocrUpdateText, // OCR-更新OCR识别结果
      urlOcrHistory: this.GLOBAL.ocrHistory, // OCR-查询核查及更新记录
      urlOcrDelItem: this.GLOBAL.ocrDelItem, // OCR-删除
      urlOcrGetFileInfo: this.GLOBAL.ocrGetFileInfo, // OCR-查询文件信息
      urlOcrQueryByOcrNo: this.GLOBAL.ocrQueryByOcrNo, // OCR-修改ocr识别结果（查询识别id）
      urlOcrLast: this.GLOBAL.ocrLast, // OCR-ocrLast
      urlDbAddOcr: this.GLOBAL.dbSaveOcr, // 大数据-保存标签数据
      urlDbDelOcr: this.GLOBAL.dbDelOcr, // 大数据-删除标签数据
      /* 新版 */
      curOcrObj: '', // 当前OCR的文件信息
      /* 操作层 */
      tipIndex: -1, // 显示的气泡下标
      tagList: [],
      moreFlag: false, // 是否显示更多标签
      moreHeight: 0, // 更多标签的高度
      operFlag: false, // 是否显示操作层
      tagHeight: 0, // 操作层左侧标签高度
      actFlag: '', // 当前激活的标签
      state: -1, // 状态,1-截图完，未拖动；2-拖动中,3-吸入标签中
      stateMsg: '', // 对应state的描述
      stateMsgWidth: '', // 提示浮框宽度
      tagPlace: {}, // 标签位置
      tempClip: {}, // 裁剪图片位置信息
      /* 识别结果 */
      resultFlag: false, // 是否展示结果
      resultBox: {}, // 结果弹框位置大小
      checkList: [], // 核查结果
      panel: 1, // 结果面板标记：1-基础信息，2-结果；3-核查和更新记录
      updList: [],
      curCatgId: this.catgId || '', // 当前OCR的影像分类id
      ocrNo: '', // 时间戳
      // 业务系统影像分类与中台OCRkey映射
      catgIdKey: [
        { catgId: 'B0031', key: 'heTongFengMian', desc: '合同封面' },
        { catgId: 'B0032', key: 'heTongShouYe', desc: '合同首页' },
        { catgId: 'B0033', key: 'heTongJiaKuanYe', desc: '合同价款页' },
        { catgId: 'B0034', key: 'heTongGongQiYe', desc: '合同工期页' },
        { catgId: 'B0035', key: 'heTongQianShuYe', desc: '贸易合同签署页' },
        {
          catgId: 'B0036',
          key: 'heTongZhiFuFangShiYe',
          desc: '合同支付方式页',
        },
        { catgId: 'B0037', key: 'heTongShengXiaoYe', desc: '合同生效页' },
        { catgId: 'B0015', key: 'luYueCaiLiao', desc: '履约材料' },
        { catgId: 'B0011', key: 'buChongXieYi', desc: '补充协议' },
        { catgId: 'B0004', key: 'faPiaoBeiZhu', desc: '发票备注' },
        { catgId: 'BA0009', key: 'ziZhiZhengShu', desc: '资质证书' }, // B0018-供应商资质证书，BA0009-业务资质证书
        { catgId: 'C0004', key: 'weiFenLeiFaPiao', desc: '未分类发票' },
        // 以下用来测试
        // {catgId:"WA0011",key: "ziZhiZhengShu",desc:"资质证书"},
        // {catgId:"WA0013",key: "heTongFengMian",desc:"合同封面"},
        // {catgId:"WA0021",key: "heTongShouYe",desc:"合同首页"},
        // {catgId:"WA0023",key: "heTongShouYe",desc:"合同首页"},
      ],
      ocrDetail: [], // OCR识别的详情数据
      delIndex: -1,
      showEsc: false,
      lastList: [], // 别人的核查结果
      doIng: false, // 是否操作中
      curImgBase64: '', // 当前操作的图片的base64字符串
      urlAddMedia: this.GLOBAL.addMedia, // 添加影像树文件接口
      labelList: {}, // 新文件
      filePath: this.imgUrl,
      oldFileWidth: '', // 原图宽度
    };
  },
  watch: {
    width(newVal, oldVal) {
      // console.log("宽度变了：",newVal,oldVal)
    },
    height(newVal, oldVal) {
      // console.log("高度变了：",newVal,oldVal)
      this.canvasW = this.width;
      this.canvasH = newVal;
      if (this.ocrType == 'image') {
        // 初始化图片加载canvas
        this.initImgCanvas();
      } else {
        // 初始化pdf加载canvas
        this.initPdfCanvas();
      }
      // 初始化裁剪canvas
      this.initClipCanvas();
    },
    rotate(newVal, oldVal) {
      this.rotateDeg = newVal;
    },
    disabled(newVal, oldVal) {
      // 是否禁用
      const initDisabled = newVal;
      if (typeof initDisabled == 'boolean') {
        this.isDisabled = initDisabled;
      } else {
        this.isDisabled = initDisabled == 'true';
      }
    },
    state(newVal, oldVal) {
      if (newVal == 1) {
        const _this = this;
        setTimeout(() => {
          _this.moveClipImg();
        }, 10);
      }
    },
    catgId(newVal, oldVal) {
      this.curCatgId = newVal;
    },
    timestamp(newVal, oldVal) {
      // 文件发生变化时，重新查询标签
      this.getOcrTags();
      this.closeOCR();
      // 初始化
      this.initOcr();
      if (this.ocrType == 'image') {
        // 初始化图片加载canvas
        this.initImgCanvas();
      } else {
        // 初始化pdf加载canvas
        this.initPdfCanvas();
      }
    },
  },
  methods: {
    // 检查环境
    checkEnvironmental() {
      if (!this.urlAI) {
        this.$llsMessage({
          type: 'warn',
          text: 'OCR识别请求地址未配置，请检查',
        });
        return;
      }
      if (!this.urlOcrRole) {
        this.$llsMessage({
          type: 'warn',
          text: 'OCR识别-系统岗位接口地址未配置，请检查',
        });
        return;
      }
      // 获取系统岗位信息
      this.getOcrRole();

      if (!this.urlOcrTag) {
        this.$llsMessage({
          type: 'warn',
          text: 'OCR识别-待识别标签地址未配置，请检查',
        });
        return;
      }
      // 获取标签信息
      this.getOcrTags();

      if (!this.urlOcrGetFileInfo) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrGetFileInfo】未配置，请检查',
        });
      }
      if (!this.urlOcrQueryByOcrNo) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrQueryByOcrNo】未配置，请检查',
        });
      }
      if (!this.urlOcrUpdateText) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrUpdateText】未配置，请检查',
        });
      }
      if (!this.urlOcrSave) {
        this.$llsMessage({ type: 'warn', text: '【ocrSave】未配置，请检查' });
      }
      if (!this.urlOcrHistory) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrHistory】未配置，请检查',
        });
      }
      if (!this.urlOcrDelItem) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrDelItem】未配置，请检查',
        });
      }
      if (!this.urlOcrCheckResult) {
        this.$llsMessage({
          type: 'warn',
          text: '【ocrCheckResult】未配置，请检查',
        });
      }
      if (!this.urlOcrLast) {
        this.$llsMessage({
          type: 'warn',
          text: '【urlOcrLast】未配置，请检查',
        });
      }
    },
    // 数据初始化
    getOcrRole() {
      const _this = this;
      _this.$http
        .post(`${_this.urlOcrRole}?sysId=${_this.sysCode || 'AMS'}`)
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              const resData = data.body.data.posts || [];
            } else {
            }
          },
          (response) => {},
        );
    },
    // 获取标签数据
    getOcrTags() {
      const _this = this;
      _this.$http
        .post(
          `${_this.urlOcrTag
          }?sysId=${
            _this.sysCode || 'AMS'
          }&postCode=${
            _this.roleKey}`,
        )
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              const resData = data.body.data.labels || [];
              let curKeyObj = ''; // 当前OCR的影像key
              const { catgIdKey } = _this;
              for (let i = 0; i < catgIdKey.length; i++) {
                if (_this.curCatgId == catgIdKey[i].catgId) {
                  curKeyObj = catgIdKey[i];
                  break;
                }
              }
              _this.curOcrObj = curKeyObj;
              // 当前文件分类需展示的标签
              let showTags = [];
              for (let k = 0; k < resData.length; k++) {
                if (curKeyObj.key == resData[k].key) {
                  showTags = resData[k].lableItems;
                  break;
                }
              }
              for (let m = 0; m < showTags.length; m++) {
                showTags[m].num = 0;
                showTags[m].state = '';
                showTags[m].text = '';
                showTags[m].newtext = '';
                showTags[m].img = [];
                showTags[m].majorKey = JSON.parse(showTags[m].major).dictParam;
              }
              _this.tagList = showTags;
              _this.setMoreHeight();
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {},
        );
    },
    // 查询最新OCR查询数据
    getLastOcr() {
      const _this = this;
      _this.lastList = [];
      _this.$http.post(`${_this.urlOcrLast}?fileNo=${_this.fileId}`).then(
        (data) => {
          const { code } = data.body;
          if (code == 200) {
            const resData = data.body.data || [];
            _this.lastList = resData;
          } else {
            _this.$llsMessage({ type: 'error', text: data.message });
          }
        },
        (response) => {
          _this.$llsMessage({ type: 'error', text: response.message });
        },
      );
    },
    // ocr识别回传
    getOcrSave(isRefer) {
      const _this = this;
      const assetNo = _this.sysValue.assetNo || ''; // 资产编号
      const userName = _this.sysValue.userName || ''; // 用户名
      _this.$http
        .post(_this.urlOcrSave, {
          detail: _this.ocrDetail, // OCR识别的详情数据
          fileNo: _this.fileId, // 文件编号--文件id（业务系统的）
          fileType: 'CONTRACT', // 文件类型--固定值
          node: _this.roleKey, // 节点--岗位key（中台的）
          ocrNo: _this.ocrNo, // ocr识别业务编号--每次启动时生成的时间戳
          sysId: _this.sysCode || 'AMS',
          assetNo,
          createBy: userName,
          updateBy: userName,
          filePath: _this.filePath, // 文件路径
        })
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              if (isRefer) {
                _this.$llsMessage({ type: 'suc', text: '刷新成功' });
              }
              const num = data.body.data;
              _this.getCheckList(true, num);
              _this.ocrDetail = [];
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {},
        );
    },
    // 查询核查结果
    getCheckList(flag, num) {
      const _this = this;
      // 查询核查结果
      _this.$http
        .post(_this.urlOcrCheckResult, {
          page: 1,
          pageSize: 100,
          ocrNo: _this.ocrNo,
          fileNo: _this.fileId,
        })
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              const checkRecords = data.body.data.records || [];
              _this.checkList = checkRecords;
              // 是否需要轮询
              if (flag) {
                const checkRecordNum = checkRecords.length;
                for (let i = 0; i < num - checkRecordNum; i++) {
                  _this.checkList.push({
                    factor: '',
                    ocrValue: '',
                    oriSysValue: '',
                    result: '',
                  });
                }
                if (checkRecordNum < num && _this.resultFlag) {
                  setTimeout(() => {
                    _this.getCheckList(flag, num);
                  }, 500);
                }
              }
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {},
        );
    },
    // 初始化
    initOcr() {
      const _this = this;
      // 获取需要绑定当前OCR的dom结构
      const _dom = this.dom;
      let doNum = 0;
      let _parent = this.$parent;
      while (!_parent.$refs[_dom] && doNum < 10) {
        doNum++;
        _parent = _parent.$parent;
      }
      let goalDom = _parent.$refs[_dom].$el || _parent.$refs[_dom];
      if (goalDom instanceof Array) {
        goalDom = goalDom[0];
      }
      this.resultW = goalDom.clientWidth;
      this.resultH = goalDom.clientHeight;
      const goalPostion = this.getStyle(goalDom, 'position');
      if (goalPostion == 'static') {
        goalDom.style.position = 'relative';
      }

      // 初始化canvas大小
      this.canvasW = _this.width;
      this.canvasH = _this.height;

      // 是否禁用
      const initDisabled = this.disabled;
      if (typeof initDisabled == 'boolean') {
        this.isDisabled = initDisabled;
      } else {
        this.isDisabled = initDisabled == 'true';
      }
    },
    // 初始化图片加载canvas
    initImgCanvas() {
      const _this = this;
      const imgcanvas = document.getElementById('signature'); // OCR裁剪画布
      // 设置高宽
      imgcanvas.setAttribute('width', `${this.canvasW}px`);
      imgcanvas.setAttribute('height', `${this.canvasH}px`);
      if (imgcanvas.getContext) {
        // 获取2D引擎
        const imgCtx = imgcanvas.getContext('2d');
        _this.imgCtx = imgCtx;
        // 画原图
        const clipImg = document.createElement('img');
        clipImg.crossOrigin = 'anonymous';
        clipImg.src = _this.imgUrl;
        const timg = clipImg.cloneNode();
        clipImg.onload = function () {
          _this.oldFileWidth = timg.width;
          const imgH = _this.canvasH;
          const imgW = (timg.width * imgH) / timg.height;
          const x = Math.floor((_this.canvasW - imgW) / 2);
          const y = 0;
          imgCtx.drawImage(
            this,
            0,
            0,
            timg.width,
            timg.height,
            x,
            y,
            imgW,
            imgH,
          );
        };
      }
    },
    // 初始化pdf加载canvas
    initPdfCanvas() {
      const _this = this;
      // var imgcanvas = document.getElementById('signature');//OCR裁剪画布
      const domMedPdfBox = this.$parent.$refs.medPdfBox;
      const canPdfs = domMedPdfBox.getElementsByTagName('canvas');
      _this.pdfCtx = [];
      for (let i = 1; i < canPdfs.length; i++) {
        const imgcanvas = canPdfs[i]; // OCR裁剪画布
        if (imgcanvas.getContext) {
          // 获取2D引擎
          const pdfCtx = imgcanvas.getContext('2d');
          _this.pdfCtx.push(pdfCtx);
        }
      }
    },
    // 初始化裁剪canvas
    initClipCanvas() {
      const _this = this;
      const clipcanvas = document.getElementById('clipcanvas'); // OCR裁剪画布
      // 设置高宽
      clipcanvas.setAttribute('width', `${this.canvasW}px`);
      clipcanvas.setAttribute('height', `${this.canvasH}px`);
      if (clipcanvas.getContext) {
        const clipCtx = clipcanvas.getContext('2d');
        _this.clipCtx = clipCtx;
        // 渲染图片
        const clipImg = document.createElement('img');
        clipImg.crossOrigin = 'anonymous';
        clipImg.src = _this.imgUrl;
        const timg = clipImg.cloneNode();
        clipImg.onload = function () {
          const imgH = _this.canvasH;
          const imgW = (timg.width * imgH) / timg.height;
          const x = Math.floor((_this.canvasW - imgW) / 2);
          const y = 0;
          // clipCtx.drawImage(this,0,0,timg.width,timg.height,x,y,imgW,imgH);
        };

        clipCtx.fillStyle = 'rgba(51,51,51,0.8)';
        clipCtx.strokeStyle = '#00A0E4';
        // 绑定鼠标事件
        clipcanvas.onmousedown = function (e) {
          // 先判断是否开启ocr
          if (!_this.pulseFlag) {
            return;
          }
          // console.log("鼠标按下")
          e.preventDefault();
          _this.state = -1; // 记录状态为初始状态
          // var imageSX = (e.offsetX);
          // _this.isMove = true;
          _this.start = {
            x: e.offsetX,
            y: e.offsetY,
          };
          _this.tempClip = {
            sx: '',
            sy: '',
            ex: '',
            ey: '',
          };
          // console.log("开始位置：",e.offsetX,e.offsetY)
        };
        clipcanvas.onmousemove = function (e) {
          // 先判断是否开启ocr
          if (!_this.pulseFlag) {
            return;
          }
          if (_this.start) {
            // console.log("鼠标移动")
            // 移动的水平垂直距离都超过10px才算有效移动
            const absX = Math.abs(e.offsetX - _this.start.x);
            const absY = Math.abs(e.offsetY - _this.start.y);
            if (absX < 10 && absY < 10) {
              return;
            }
            _this.isMove = true;
            _this.tagHideFlag = true;
            _this.end = {
              x: e.offsetX,
              y: e.offsetY,
            };
            // console.log("鼠标移动：",e.offsetX,e.offsetY)
            // var imageMX = (e.offsetX);
            _this.fillClip(clipCtx, e.offsetX, e.offsetY);
          }
        };
        document.addEventListener('mouseup', (e) => {
          // 先判断是否开启ocr
          if (!_this.pulseFlag) {
            return;
          }
          // console.log("鼠标松开")
          _this.isMove = false;
          _this.tagHideFlag = false;
          // 计算移动距离
          let absX; let
            absY;
          if (!_this.end || !_this.start) {
            absX = 0;
            absY = 0;
          } else {
            absX = Math.abs(_this.end.x - _this.start.x);
            absY = Math.abs(_this.end.y - _this.start.y);
          }
          if (absX < 10 && absY < 10) {
            _this.start = null;
            return;
          }
          if (_this.start) {
            // 计算裁剪区域图片数据，用于渲染页面
            const urlRender = _this.startClipToRender();
            _this.clipImg = urlRender;
            // 显示提示浮框
            _this.showTipMsg(1);
            _this.exitOcr();

            // 保存框选位置
            const minX =
              _this.start.x < _this.end.x
                ? _this.start.x
                : _this.end.x;
            const minY =
              _this.start.y < _this.end.y
                ? _this.start.y
                : _this.end.y;
            const maxX =
              _this.start.x < _this.end.x
                ? _this.end.x
                : _this.start.x;
            const maxY =
              _this.start.y < _this.end.y
                ? _this.end.y
                : _this.start.y;
            _this.tempClip = {
              sx: minX,
              sy: minY,
              ex: maxX,
              ey: maxY,
            };
            /*
                            //判断框选图片离度是否达到最低要求（10mm==28px）
                            if(_this.clipArea["h"] < 30) {
                                // console.log("我小于30，先放大",_this.clipArea["h"])
                                //计算裁剪区域图片数据，用于识别
                                _this.startClipToOcr(function(ocrBase64Data){
                                    //调用接口开始OCR
                                    _this.startOCR(ocrBase64Data,tempClip);
                                });
                            } else {
                                // console.log("我大于30，直接识别")
                                _this.scale = 1;
                                //调用接口开始OCR
                                _this.startOCR(urlRender,tempClip);
                            }
                            */

            _this.start = null;
            _this.end = null;
          }
        });
      }
    },
    // 获取样式--OK
    getStyle(element, attr) {
      return window.getComputedStyle
        ? window.getComputedStyle(element, null)[attr]
        : element.currentStyle[attr];
    },
    // 渲染裁剪框
    fillClip(clipCtx, moveX, moveY) {
      const _this = this;
      const width = _this.canvasW;
      const height = _this.canvasH;
      // 根据坐标位置判断起点和高宽
      const begX = parseInt(_this.start.x);
      const begY = parseInt(_this.start.y);
      const x = begX < moveX ? begX : moveX;
      const y = begY < moveY ? begY : moveY;
      const maxX = begX < moveX ? moveX : begX;
      const maxY = begY < moveY ? moveY : begY;
      const w = parseInt(maxX - x);
      const h = parseInt(maxY - y);

      clipCtx.clearRect(0, 0, width, height);
      clipCtx.beginPath();
      // 遮罩层
      clipCtx.globalCompositeOperation = 'source-over';
      clipCtx.fillRect(0, 0, width, height);
      // 画框
      clipCtx.globalCompositeOperation = 'destination-out';
      clipCtx.fillRect(x, y, w, h);
      // 描边
      clipCtx.globalCompositeOperation = 'source-over';
      // clipCtx.lineWidth = "1px";
      clipCtx.moveTo(x, y);
      clipCtx.lineTo(x + w, y);
      clipCtx.lineTo(x + w, y + h);
      clipCtx.lineTo(x, y + h);
      clipCtx.lineTo(x, y);

      clipCtx.stroke();
      clipCtx.closePath();
      // 保存裁剪区域数据
      _this.clipArea = {
        x,
        y,
        w,
        h,
      };
    },
    // 开始裁剪，并返回裁剪区域图片数据
    startClipToRender() {
      const _this = this;
      const canvas = document.createElement('canvas');
      const {
        x, y, w, h,
      } = _this.clipArea;
      canvas.width = w;
      canvas.height = h;
      let ctx = '';
      let data = '';
      if (this.ocrType == 'image') {
        ctx = _this.imgCtx;
        data = ctx.getImageData(x, y, w, h);
      } else {
        const total = _this.pageCount; // 总页数
        let pageH = _this.canvasH / total; // 每页高度
        pageH = parseInt(pageH);
        let pageNum = 1;
        while (y > pageH * pageNum) {
          ++pageNum;
        }
        console.log('1107', _this.canvasH, pageH, pageNum);
        ctx = _this.pdfCtx[pageNum - 1];
        data = ctx.getImageData(x, y - pageH * (pageNum - 1), w, h);
      }
      const context = canvas.getContext('2d');
      context.putImageData(data, 0, 0);
      return canvas.toDataURL('image/png');
    },
    // 开始裁剪，并返回裁剪区域图片数据
    startClipToOcr(callback) {
      const _this = this;

      // 计算缩放后的框选区域大小，位置
      const {
        x, y, w, h,
      } = _this.clipArea;
      // 对图片做放大缩小预操作
      const scale = 30 / h; // 缩放比例
      _this.scale = scale;
      const scaleX = scale * x;
      const scaleY = scale * y;
      const scaleW = scale * w;
      const scaleH = scale * h;

      // 渲染缩放后的图片
      const scalecanvas = document.getElementById('scalecanvas'); // OCR裁剪画布
      // 设置高宽
      scalecanvas.setAttribute('width', `${this.canvasW * scale}px`);
      scalecanvas.setAttribute('height', `${this.canvasH * scale}px`);
      if (scalecanvas.getContext) {
        // 获取2D引擎
        const scaleCtx = scalecanvas.getContext('2d');
        // 画原图
        const scaleImg = document.createElement('img');
        scaleImg.crossOrigin = 'anonymous';
        scaleImg.src = _this.imgUrl;
        const timg = scaleImg.cloneNode();
        scaleImg.onload = function () {
          const imgH = _this.canvasH * scale;
          const imgW = (timg.width * imgH) / timg.height;
          const x = Math.floor((_this.canvasW * scale - imgW) / 2);
          const y = 0;
          scaleCtx.drawImage(
            this,
            0,
            0,
            timg.width,
            timg.height,
            x,
            y,
            imgW,
            imgH,
          );

          const ctx = scaleCtx;
          const canvasOcr = document.createElement('canvas');
          canvasOcr.width = scaleW;
          canvasOcr.height = scaleH;
          const data = ctx.getImageData(scaleX, scaleY, scaleW, scaleH);
          const context = canvasOcr.getContext('2d');
          context.putImageData(data, 0, 0);
          _this.scaleImg = canvasOcr.toDataURL('image/png');
          callback(canvasOcr.toDataURL('image/png'));
        };
      }
    },
    // 开始OCR
    startOCR(tagIndex, sonIndex) {
      const _this = this;
      const { tempClip } = _this;
      // 截图区域高和宽
      const width = parseInt(
        Math.abs(tempClip.ex - tempClip.sx) * _this.scale,
      );
      const height = parseInt(
        Math.abs(tempClip.ey - tempClip.sy) * _this.scale,
      );
      // 方式一：截图+相对位置
      // 截图的文件流
      const base64Data = _this.clipImg;
      const boxsValOne =
        `0,0,${width},0,${width},${height},0,${height}`;
      // 方式二：全图+相对位置
      const canvas = document.createElement('canvas');
      canvas.width = _this.canvasW;
      canvas.height = _this.canvasH;
      const context = canvas.getContext('2d');
      let data = '';
      let boxsValTwo = '';
      let boxsOldValTwo = '';
      let coord = '';
      let pageNum = 1;
      if (this.ocrType == 'image') {
        data = _this.imgCtx.getImageData(0, 0, _this.canvasW, _this.canvasH);
        // 图片OCR坐标不准的问题，2020-10-10
        const widthScale = 1;
        const heightScale = 1;
        const sx = (tempClip.sx * widthScale).toFixed();
        const sy = (tempClip.sy * heightScale).toFixed();
        const ex = (tempClip.ex * widthScale).toFixed();
        const ey = (tempClip.ey * heightScale).toFixed();
        boxsValTwo =
          `${sx
          },${
            sy
          },${
            ex
          },${
            sy
          },${
            ex
          },${
            ey
          },${
            sx
          },${
            ey}`;

        const oldWScale = _this.ocrImgWidth / _this.canvasW;
        const oldHScale = _this.ocrImgHeight / _this.canvasH;
        const osx = (tempClip.sx * oldWScale).toFixed();
        const osy = (tempClip.sy * oldHScale).toFixed();
        const oex = (tempClip.ex * oldWScale).toFixed();
        const oey = (tempClip.ey * oldHScale).toFixed();
        boxsOldValTwo =
          `${osx
          },${
            osy
          },${
            oex
          },${
            osy
          },${
            oex
          },${
            oey
          },${
            osx
          },${
            oey}`;
        var coordW = ex - sx;
        var coordH = ey - sy;
        coord = `${sx}===${sy}===${coordW}===${coordH}`;
      } else {
        const total = _this.pageCount; // 总页数
        let pageH = _this.canvasH / total; // 每页高度
        pageH = parseInt(pageH);

        while (tempClip.sy > pageH * pageNum) {
          ++pageNum;
        }
        data = _this.pdfCtx[pageNum - 1].getImageData(
          0,
          0,
          _this.canvasW,
          pageH,
        );
        boxsValTwo =
          `${tempClip.sx
          },${
            tempClip.sy - pageH * (pageNum - 1)
          },${
            tempClip.ex
          },${
            tempClip.sy - pageH * (pageNum - 1)
          },${
            tempClip.ex
          },${
            tempClip.ey - pageH * (pageNum - 1)
          },${
            tempClip.sx
          },${
            tempClip.ey - pageH * (pageNum - 1)}`;
        boxsOldValTwo = boxsValTwo;
        const coordX = tempClip.sx;
        const coordY = tempClip.sy - pageH * (pageNum - 1);
        var coordW = tempClip.ex - tempClip.sx;
        var coordH = tempClip.ey - tempClip.sy;
        coord = `${coordX}===${coordY}===${coordW}===${coordH}`;
      }
      context.putImageData(data, 0, 0);
      const base64DataTwo = canvas.toDataURL('image/png');
      _this.curImgBase64 = base64DataTwo;

      // 开始时间
      const startTime = new Date().getTime();
      $.ajax({
        url: _this.urlAI,
        type: 'post',
        // contentType: false,
        processData: false,
        // data:formData,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          boxs: boxsValTwo,
          imgString: base64DataTwo,
        }),
        success(response, xml) {
          if (response.code == '200') {
            const ocrText = response.data;
            const sysValueList = _this.sysValue || {};
            const sysValue = sysValueList[_this.tagList[tagIndex].key] || '';
            // 记录当前OCR识别的详情数据
            if (sonIndex == -1) {
              // 查询是否已有该标签的
              var hasFlag = -1;
              for (let m = 0; m < _this.ocrDetail.length; m++) {
                if (
                  _this.tagList[tagIndex].desc ==
                  _this.ocrDetail[m].keyFieldName
                ) {
                  hasFlag = m;
                  break;
                }
              }
              if (hasFlag == -1) {
                _this.ocrDetail.push({
                  imgBase64: [base64Data], // 图片base64
                  keyFieldId: _this.tagList[tagIndex].key, // 关键字段id
                  keyFieldName: _this.tagList[tagIndex].desc, // 关键字段名称
                  keyFieldType: _this.tagList[tagIndex].type, // 关键字段类型：0：系统原始数据，1：ocr
                  keyPageId: _this.curOcrObj.key, // 关键页
                  ocrValue: ocrText, // ocr值
                  sysValue, // 系统值
                  coord, // 坐标 TODO
                  pageNum, // 页码 TODO
                });
              } else {
                // 追加图片
                var imgBaseList = _this.ocrDetail[hasFlag].imgBase64;
                imgBaseList.push(base64Data);
                Vue.set(_this.ocrDetail[hasFlag], 'imgBase64', imgBaseList);
                // 追加文字
                var preValue = _this.ocrDetail[hasFlag].ocrValue;
                Vue.set(
                  _this.ocrDetail[hasFlag],
                  'ocrValue',
                  `${preValue}${ocrText}`,
                );
              }
            } else {
              // 查询是否已有该标签的
              var hasFlag = -1;
              for (let m = 0; m < _this.ocrDetail.length; m++) {
                if (
                  _this.tagList[tagIndex].sonTags[sonIndex].desc ==
                  _this.ocrDetail[m].keyFieldName
                ) {
                  hasFlag = m;
                  break;
                }
              }
              if (hasFlag == -1) {
                _this.ocrDetail.push({
                  imgBase64: [base64Data], // 图片base64
                  keyFieldId: _this.tagList[tagIndex].key, // 关键字段id
                  keyFieldName:
                    _this.tagList[tagIndex].sonTags[sonIndex].desc, // 关键字段名称
                  keyFieldType: _this.tagList[tagIndex].type, // 关键字段类型：0：系统原始数据，1：ocr
                  keyPageId: _this.curOcrObj.key, // 关键页
                  ocrValue: ocrText, // ocr值
                  sysValue, // 系统值
                  coord, // 坐标 TODO
                  pageNum, // 页码 TODO
                });
              } else {
                // 追加图片
                var imgBaseList = _this.ocrDetail[hasFlag].imgBase64;
                imgBaseList.push(base64Data);
                Vue.set(_this.ocrDetail[hasFlag], 'imgBase64', imgBaseList);
                // 追加文字
                var preValue = _this.ocrDetail[hasFlag].ocrValue;
                Vue.set(
                  _this.ocrDetail[hasFlag],
                  'ocrValue',
                  `${preValue}${ocrText}`,
                );
              }
            }
            // 查询各项OCR的ID
            // _this.queryByOcrNo();
            // 请求成功后执行的代码
            const endTime = new Date().getTime();
            const waitTime =
              endTime - startTime < 600 ? 600 - (endTime - startTime) : 0;
            setTimeout(() => {
              if (sonIndex == -1) {
                // 当前打标签是首标签
                Vue.set(_this.tagList[tagIndex], 'state', 'finish');
                setTimeout(() => {
                  let curNum = _this.tagList[tagIndex].num;
                  Vue.set(_this.tagList[tagIndex], 'state', '');
                  Vue.set(_this.tagList[tagIndex], 'num', ++curNum);
                  // 获取原文字
                  let preText = _this.tagList[tagIndex].text || '';
                  preText = `${preText}${ocrText}`;
                  Vue.set(_this.tagList[tagIndex], 'text', preText);
                  Vue.set(_this.tagList[tagIndex], 'newtext', preText);
                  // 获取之前的图片
                  const preImg = _this.tagList[tagIndex].img || [];
                  preImg.push(_this.clipImg);
                  Vue.set(_this.tagList[tagIndex], 'img', preImg);
                }, 2000);
              } else {
                // 当前打标签是同源标签
                Vue.set(
                  _this.tagList[tagIndex].sonTags[sonIndex],
                  'state',
                  'finish',
                );
                setTimeout(() => {
                  let curNum =
                    _this.tagList[tagIndex].sonTags[sonIndex].num;
                  Vue.set(
                    _this.tagList[tagIndex].sonTags[sonIndex],
                    'state',
                    '',
                  );
                  Vue.set(
                    _this.tagList[tagIndex].sonTags[sonIndex],
                    'num',
                    ++curNum,
                  );
                  // 获取原文字
                  let preText =
                    _this.tagList[tagIndex].sonTags[sonIndex].text || '';
                  preText = `${preText}${ocrText}`;
                  Vue.set(
                    _this.tagList[tagIndex].sonTags[sonIndex],
                    'text',
                    preText,
                  );
                  Vue.set(
                    _this.tagList[tagIndex].sonTags[sonIndex],
                    'newtext',
                    preText,
                  );
                  // 获取之前的图片
                  const preImg =
                    _this.tagList[tagIndex].sonTags[sonIndex].img || [];
                  preImg.push(_this.clipImg);
                  Vue.set(
                    _this.tagList[tagIndex].sonTags[sonIndex],
                    'img',
                    preImg,
                  );
                }, 2000);
              }
            }, waitTime);

            // 需要区分当前是识别的图片还是pdf，如果为pdf还需要先保存截图
            if (_this.ocrType == 'image') {
              // 同步大数据
              _this.saveToDb(boxsOldValTwo, _this.tagList[tagIndex].desc);
            } else {
              // 先上传pdf截图
              _this.uploadPdfImage(
                base64DataTwo,
                boxsOldValTwo,
                _this.tagList[tagIndex].desc,
              );
            }
            // 同步给中台
            _this.getOcrSave(false);
          } else {
            console.log('失败', response);
            // alert("OCR失败："+response.msg);
            _this.$llsAlert({
              type: 'error',
              text: response.msg,
              title: 'OCR失败，请检查接口是否正常',
            });
            // _this.exitOcr();
          }
        },
        error(status, error, e) {
          // 失败后执行的代码
          // alert("OCR异常");
          // 当前打标签是首标签
          Vue.set(_this.tagList[tagIndex], 'state', '');
          _this.$llsMessage({
            type: 'error',
            text: 'OCR异常，请检查本地服务是否正常开启',
          });
          // _this.exitOcr();
        },
        complete(e) {
          // _this.exitOcr();
        },
      });
    },
    // 清除退出
    exitOcr() {
      this.clipCtx.clearRect(0, 0, this.canvasW, this.canvasH);
    },

    /* 新版事件 */
    // 启动按键-启动OCR
    pulseOCR(e) {
      const _this = this;
      e = e || window.event;
      e.stopPropagation();
      // 显示操作层
      _this.operFlag = true;
      // 开启OCR
      _this.pulseFlag = true;
      // 启动监听
      _this.listenerQuink();
      // 计算时间戳
      _this.ocrNo = new Date().getTime();
      // 初始化裁剪canvas
      this.initClipCanvas();
      // 计算操作区高度
      setTimeout(() => {
        const { operBox } = _this.$refs;
        const operH = operBox.clientHeight;
        _this.tagHeight = `${operH - 30}px`;
      }, 10);
      // 监听键盘事件
      $(document)
        .unbind('keyup')
        .bind('keyup', (event) => {
          // console.log(event.keyCode)
          switch (event.keyCode) {
            // Esc
            case 27:
              _this.closeOCR();
            // Ctrl
            case 17:
              _this.tagHideFlag = !_this.tagHideFlag;
            // Enter
            case 13:
              _this.beforeUpd();
          }
        });
    },
    // 操作层-关闭OCR
    closeOCR() {
      const _this = this;
      // 关闭操作层
      _this.operFlag = false;
      // 关闭OCR
      _this.pulseFlag = false;
      // 高亮标签恢复初始状态
      _this.actFlag = '';
      // 标签信息恢复为初始状态
      const { tagList } = _this;
      for (let i = 0; i < tagList.length; i++) {
        Vue.set(_this.tagList[i], 'num', 0);
        Vue.set(_this.tagList[i], 'state', '');
        Vue.set(_this.tagList[i], 'img', '');
        Vue.set(_this.tagList[i], 'text', '');
        Vue.set(_this.tagList[i], 'newtext', '');
      }
    },
    // 气泡展示
    inTo(index) {
      const _this = this;
      if (index == -1) {
        _this.tipIndex = index;
        setTimeout(() => {
          _this.showEsc = false;
        }, 300);
      } else {
        this.showEsc = true;
        setTimeout(() => {
          _this.tipIndex = index;
        }, 0);
      }
    },
    // 判断是否有OCR识别结果了
    isHasResult() {
      const { tagList } = this;
      let flag = false;
      for (let i = 0; i < tagList.length; i++) {
        if (tagList[i].num > 0) {
          flag = true;
          break;
        } else {
          const sonTags = tagList[i].sonTags || [];
          for (let k = 0; k < sonTags.length; k++) {
            if (sonTags[k].num > 0) {
              flag = true;
              break;
            }
          }
          if (flag) {
            break;
          }
        }
      }
      return flag;
    },
    // 查看OCR识别结果
    queryOcrResult() {
      // 先回传
      this.getOcrSave(false);
      // 启动结果弹框
      this.showResult(2);
      // 查询最新OCR列表
      this.getLastOcr();
    },
    // 查看问号
    queryQuestion() {},
    // 更多选项标签
    showMore() {
      this.moreFlag = !this.moreFlag;
      // 计算更多选项标签的高度
      this.setMoreHeight();
    },
    // 计算更多选项标签的高度
    setMoreHeight() {
      const { tagList } = this;
      // 计算有几个在更多里面
      let num = 0;
      for (let i = 0; i < tagList.length; i++) {
        if (tagList[i].majorKey == 'N') {
          ++num;
          // 同源标签
          const sonTags = tagList[i].sonTags || [];
          for (let k = 0; k < sonTags.length; k++) {
            if (sonTags[k].majorKey == 'N') {
              ++num;
            }
          }
        }
      }
      // 计算更多层的高度
      this.moreHeight = `${num * 35}px`;
    },
    // 移除裁剪的图片
    removeClipImg() {
      this.clipArea = {};
      this.state = -1;
      this.actFlag = '';
    },
    // 裁剪图片拖动事件
    moveClipImg() {
      const _this = this;
      const node = this.$refs.imgBox;
      // var parentNode = node.parentNode;
      const parentNode = node;
      $(node).mousedown((ev) => {
        // 鼠标按下
        // 获取当前点击处相对于弹框的坐标位置
        const oEvent = ev || event; // 判断浏览器兼容
        const clickX = oEvent.clientX;
        const clickY = oEvent.clientY;
        const nodeLeft = $(parentNode).position().left;
        const nodetTop = $(parentNode).position().top;
        const relX = clickX - nodeLeft;
        const relY = clickY - nodetTop;
        // 开始移动时，先计算标签的位置
        _this.getTagPlace();
        document.onmousemove = function (ev) {
          // 鼠标移动
          const oEvent = ev || event; // 判断浏览器兼容
          const moveX = oEvent.clientX;
          const moveY = oEvent.clientY;
          const showLeft = moveX - relX;
          const showTop = moveY - relY;
          $(parentNode).css({ top: showTop, left: showLeft });
          const rect = parentNode.getBoundingClientRect();
          // 修改状态为2,表示在移动中
          // 显示提示浮框
          _this.showTipMsg(2);
          // 移动时，计算是否与标签触碰
          // _this.getTagAct(showLeft,showTop);
          _this.getTagAct(rect.x, rect.y);
        };
        document.onmouseup = function () {
          // 当鼠标松开后关闭移动事件和自身事件
          document.onmousemove = null;
          document.onmouseup = null;
          // actFlag有值时，表示当前裁剪图片在高亮标签内，需要做OCR了，否则修改状态为1,表示没有移动了
          if (_this.actFlag) {
            _this.state = 3;
            const { tagList } = _this;
            let tagIndex = -1; // 当前识别的标签下标数
            let sonIndex = -1; // 同源标签下标数
            for (let i = 0; i < tagList.length; i++) {
              if (tagList[i].desc == _this.actFlag) {
                tagIndex = i;
                Vue.set(_this.tagList[i], 'state', 'ing');
              } else {
                const sonTags = tagList[i].sonTags || [];
                for (let k = 0; k < sonTags.length; k++) {
                  if (sonTags[k].desc == _this.actFlag) {
                    tagIndex = i;
                    sonIndex = k;
                    Vue.set(_this.tagList[i].sonTags[k], 'state', 'ing');
                  }
                }
              }
            }
            _this.actFlag = '';
            // 调用接口开始OCR
            _this.startOCR(tagIndex, sonIndex);
          } else {
            // 显示提示浮框
            _this.showTipMsg(1);
          }
        };
        return false;
      });
    },
    // 计算标签位置
    getTagPlace() {
      const tagItems = document.getElementsByName('tagItem');
      const tagPlace = [];
      for (let i = 0; i < tagItems.length; i++) {
        const curTag = tagItems[i];
        const curRect = curTag.getBoundingClientRect();
        const curW = curRect.width;
        const curH = curRect.height;
        const curTop = curRect.top;
        const curLeft = curRect.left;
        const curKey = curTag.getAttribute('data-key');
        tagPlace.push({
          key: curKey,
          startX: curLeft,
          endX: curLeft + curW,
          startY: curTop,
          endY: curTop + curH,
        });
      }
      this.tagPlace = tagPlace;
    },
    // 计算标签是否高亮
    getTagAct(dotLeft, dotTop) {
      const { tagPlace } = this;
      this.actFlag = '';
      for (let i = 0; i < tagPlace.length; i++) {
        const {
          key, startX, endX, startY, endY,
        } = tagPlace[i];
        if (
          dotLeft > startX &&
          dotLeft < endX &&
          dotTop > startY &&
          dotTop < endY
        ) {
          this.actFlag = key;
          break;
        }
      }
    },
    // 添加标签
    addTag(e, item, index) {
      e.stopPropagation();
      // 深拷贝一份
      const sonItem = JSON.parse(JSON.stringify(item));
      // 计算现有同源标签个数
      const sonTags = item.sonTags || [];
      const sonTagNum = sonTags.length;
      // 修改标签名
      sonItem.desc = `${item.desc}${sonTagNum + 1}`;
      sonItem.num = 0;
      sonItem.text = '';
      sonItem.newtext = '';
      sonItem.state = '';
      sonTags.push(sonItem);
      Vue.set(this.tagList[index], 'sonTags', sonTags);
      if (item.majorKey == 'N') {
        // 计算更多标签的高度
        this.setMoreHeight();
      }
    },
    // 移除标签
    removeTag(e, index, sonIndex) {
      e.stopPropagation();
      this.tagList[index].sonTags.splice(sonIndex, 1);
    },

    /* 以下是结果弹框的方法 */
    // 显示结果弹框
    showResult(panel) {
      const _this = this;
      // 设置弹框位置大小
      // 获取需要绑定当前OCR的dom结构
      const _dom = this.dom;
      let doNum = 0;
      let _parent = this.$parent;
      while (!_parent.$refs[_dom] && doNum < 10) {
        doNum++;
        _parent = _parent.$parent;
      }
      let goalDom = _parent.$refs[_dom].$el || _parent.$refs[_dom];
      if (goalDom instanceof Array) {
        goalDom = goalDom[0];
      }
      const ocrRect = goalDom.getBoundingClientRect();

      // var ocrBox = this.$refs.ocrBox;
      // var ocrRect = ocrBox.getBoundingClientRect();
      _this.resultBox = {
        left: 200,
        top: ocrRect.top,
        width: ocrRect.left - 210,
        height: ocrRect.height,
      };
      // 显示弹框
      this.resultFlag = true;
      this.panel = panel;
      // 绑定拖动事件
      setTimeout(() => {
        _this.setMove();
      }, 100);
    },
    // 显示提示浮框
    showTipMsg(num) {
      const _this = this;
      // 设置弹框位置大小
      // 获取需要绑定当前OCR的dom结构
      const _dom = this.dom;
      let doNum = 0;
      let _parent = this.$parent;
      while (!_parent.$refs[_dom] && doNum < 10) {
        doNum++;
        _parent = _parent.$parent;
      }
      let goalDom = _parent.$refs[_dom].$el || _parent.$refs[_dom];
      if (goalDom instanceof Array) {
        goalDom = goalDom[0];
      }
      const ocrRect = goalDom.getBoundingClientRect();
      _this.stateMsgWidth = ocrRect.width;
      _this.state = num; // 记录状态为截图完成，未拖动
      _this.stateMsg =
        num == 1
          ? '将截图拖拽到左侧标签上，可对截图添加标签'
          : '将截图拖拽到标签上，待标签激活后松开鼠标即可完成操作';
    },
    // 修改前判断
    beforeUpd() {
      const actEle = document.activeElement;
      const parNode = actEle.parentNode;
      const childNode = parNode.children;
      let clickNode = '';
      for (let i = 0; i < childNode.length; i++) {
        const childClasss = childNode[i].classList || [];
        for (let k = 0; k < childClasss.length; k++) {
          if (childClasss[k].indexOf('ocr-updact') != -1) {
            clickNode = childNode[i];
            break;
          }
        }
      }
      if (clickNode) {
        clickNode.click();
      }
    },
    // 修改/删除ocr识别选项内容
    operOcrItem(type, item, tagIndex, sonTagIndex) {
      const _this = this;
      // 当前操作的标签名称
      const curDesc = item.desc;
      _this.doIng = true;
      _this.$http
        .post(
          `${_this.urlOcrQueryByOcrNo
          }?fileNo=${
            this.fileId
          }&ocrNo=${
            _this.ocrNo}`,
        )
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              const resData = data.body.data || [];
              let curId = '';
              for (let i = 0; i < resData.length; i++) {
                const { keyFieldName } = resData[i];
                if (keyFieldName == curDesc) {
                  curId = resData[i].id;
                  break;
                }
              }
              if (curId) {
                if (type == 'del') {
                  // 删除接口
                  _this.delOcrItem(curId, tagIndex, sonTagIndex);
                } else {
                  // 更新接口
                  _this.updOcrItem(curId, item);
                }
              } else {
                _this.doIng = false;
                // _this.$llsMessage({type:"warn",text:"未查询到数据"});
                // 未查询到数据则直接移除（因为这种情况可能是未回传）
                const ocrDet = _this.ocrDetail;
                if (type == 'del') {
                  let delNum = -1; // 待删除的下标
                  // 剔除识别项
                  if (sonTagIndex == -1) {
                    Vue.set(_this.tagList[tagIndex], 'num', 0);
                    Vue.set(_this.tagList[tagIndex], 'text', '');
                    Vue.set(_this.tagList[tagIndex], 'newtext', '');
                    Vue.set(_this.tagList[tagIndex], 'img', []);
                  } else {
                    _this.tagList[tagIndex].sonTags.splice(sonTagIndex, 1);
                  }
                  for (let m = 0; m < ocrDet.length; m++) {
                    if (ocrDet[m].keyFieldName == item.desc) {
                      delNum = m;
                    }
                  }
                  _this.ocrDetail.splice(delNum, 1);
                  // 删除
                  _this.$llsMessage({ type: 'suc', text: '删除成功' });
                  console.log(_this.ocrDetail);
                } else {
                  // 更新
                  Vue.set(item, 'text', item.newtext);
                  for (let m = 0; m < ocrDet.length; m++) {
                    if (ocrDet[m].keyFieldName == item.desc) {
                      ocrDet[m].ocrValue = item.newtext;
                    }
                  }
                  _this.ocrDetail = ocrDet;
                  _this.$llsMessage({ type: 'suc', text: '更新成功' });
                }
              }
            } else {
              _this.doIng = false;
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {
            _this.doIng = false;
            _this.$llsMessage({ type: 'error', text: data.message });
          },
        );
    },
    // 删除ocr识别选项
    delOcrItem(id, tagIndex, sonTagIndex) {
      const _this = this;
      _this.$http.post(`${_this.urlOcrDelItem}?id=${id}`).then(
        (data) => {
          _this.doIng = false;
          const { code } = data.body;
          if (code == 200) {
            _this.$llsMessage({ type: 'suc', text: '删除成功' });
            // 剔除识别项
            if (sonTagIndex == -1) {
              // 同步大数据-删除OCR标签
              _this.delToDb(_this.tagList[tagIndex].desc);
              // _this.tagList.splice(tagIndex,1);
              Vue.set(_this.tagList[tagIndex], 'num', 0);
              Vue.set(_this.tagList[tagIndex], 'text', '');
              Vue.set(_this.tagList[tagIndex], 'newtext', '');
              Vue.set(_this.tagList[tagIndex], 'img', []);
            } else {
              // 同步大数据-删除OCR标签
              _this.delToDb(
                _this.tagList[tagIndex].sonTags[sonTagIndex].desc,
              );
              _this.tagList[tagIndex].sonTags.splice(sonTagIndex, 1);
            }
            // 重新查询核查列表
            _this.getCheckList(false, 0);
          } else {
            _this.$llsMessage({ type: 'error', text: data.body.message });
          }
        },
        (response) => {
          _this.doIng = false;
          _this.$llsMessage({ type: 'error', text: data.message });
        },
      );
    },
    // 更新OCR结果
    updOcrItem(id, item) {
      const _this = this;
      _this.$http
        .post(
          `${_this.urlOcrUpdateText}?id=${id}&ocrValue=${item.newtext}`,
        )
        .then(
          (data) => {
            _this.doIng = false;
            const { code } = data.body;
            if (code == 200) {
              _this.$llsMessage({ type: 'suc', text: '更新成功' });
              Vue.set(item, 'text', item.newtext);
              // 重新查询核查列表
              _this.getCheckList(false, 0);
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {
            _this.doIng = false;
            _this.$llsMessage({ type: 'error', text: data.message });
          },
        );
    },
    // 显示/隐藏裁剪的图片
    operImg(item) {
      const curState = item.showImg;
      Vue.set(item, 'showImg', !curState);
    },
    // 关闭结果弹框
    closeResult() {
      this.resultFlag = false;
    },
    // 提交结果
    submitResult() {
      this.$llsMessage({ type: 'success', text: '提交成功' });
    },
    // 核查是否通过
    checkFlag(item) {
      var value = '';
      if (item.result) {
        var value = JSON.parse(item.result).dictParam;
        value = value == 'NON_BIT' ? 'err' : 'ok';
      }
      return value;
    },
    // 核查结果和更新记录
    queryCheckUpd() {
      const _this = this;
      this.panel = 3;
      const { resBody } = this.$refs;
      resBody.scrollTop = 0;
      _this.updList = [];
      _this.$http.post(`${_this.urlOcrHistory}?fileNo=${this.fileId}`).then(
        (data) => {
          const { code } = data.body;
          if (code == 200) {
            const resData = data.body.data || [];
            _this.updList = resData;
          } else {
            _this.$llsMessage({ type: 'error', text: data.body.message });
          }
        },
        (response) => {
          _this.$llsMessage({ type: 'error', text: data.message });
        },
      );
    },
    // 结果弹框拖动事件
    setMove() {
      const node = this.$refs.resHead;
      const { parentNode } = node;
      // var parentNode = node;
      $(node).mousedown((ev) => {
        // 鼠标按下
        // 获取当前点击处相对于弹框的坐标位置
        const oEvent = ev || event; // 判断浏览器兼容
        const clickX = oEvent.clientX;
        const clickY = oEvent.clientY;
        const nodeLeft = $(parentNode).position().left;
        const nodetTop = $(parentNode).position().top;
        const relX = clickX - nodeLeft;
        const relY = clickY - nodetTop;
        document.onmousemove = function (ev) {
          // 鼠标移动
          const oEvent = ev || event; // 判断浏览器兼容
          const moveX = oEvent.clientX;
          const moveY = oEvent.clientY;
          const showLeft = moveX - relX;
          const showTop = moveY - relY;
          $(parentNode).css({ top: showTop, left: showLeft });
        };
        document.onmouseup = function () {
          // 当鼠标松开后关闭移动事件和自身事件
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return false;
      });
    },
    // 快捷键监听
    listenerQuink() {
      // 监听键盘事件
      // $(document).unbind('keyup').bind('keyup',function(event){
      //     if(_this.pulseFlag && document.activeElement == renameInput) {
      //         switch(event.keyCode) {
      //             case 13: _this.setName()
      //         }
      //     }
      // });
    },
    // 上传pdf截图
    uploadPdfImage(imgBase64, boxsValTwo, desc) {
      const _this = this;
      const blob = _this.dataURLtoFile(imgBase64, `${Date.now()}.jpg`);
      // var array = [];
      // for(var i = 0; i < imgBase64.length; i++){
      //     array.push(imgBase64.charCodeAt(i));
      // }
      // var blob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
      const fd = new FormData();
      fd.append('file', blob);
      const assetNo = _this.sysValue.assetNo || ''; // 资产编号
      _this.$http
        .post(
          `${_this.urlAddMedia
          }?modelCode=MZ002&catgId=Z1001&busiKey=${
            assetNo}`,
          fd,
        )
        .then(
          (data) => {
            const code =
              data.code ||
              data.httpCode ||
              data.body.code ||
              data.body.httpCode;
            if (code == 200) {
              // 上传成功，取文件ID和文件路径，同步大数据
              let fileId = '';
              let pdfUrl = '';
              console.log('1922', data, data.body.modelfile);
              if (data.body.modelfile) {
                console.log('1924');
                fileId = data.body.modelfile.id;
                pdfUrl = data.body.modelfile.path;
                // console.log("1927",fileId,pdfUrl)
              } else if (data.data) {
                // console.log("1929");
                fileId = data.data.id;
                pdfUrl = data.data.path;
              }
              _this.filePath = pdfUrl;
              // 记录新文件信息
              _this.labelList[desc] = { fileId, url: pdfUrl };
              // console.log("1935",fileId,pdfUrl);
              _this.saveToDb(boxsValTwo, desc, fileId, pdfUrl);
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.msg });
            }
          },
          (response) => {
            _this.$llsMessage({ type: 'error', text: response.message });
          },
        );
    },
    // base64转为formdate
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为文件
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 大数据-同步OCR标签信息
    saveToDb(location, label, pdfFileId, pdfUrl) {
      console.log(pdfFileId, pdfUrl);
      const _this = this;
      const timestape = new Date().getTime();
      const { origin } = document.location;
      const imgUrl = `${origin}/${_this.imgUrl}`;
      const userName = _this.sysValue.userName || ''; // 用户名
      const other = { name: userName };
      _this.$http
        .post(_this.urlDbAddOcr, {
          docId: pdfFileId || this.fileId,
          // "image": this.curImgBase64,
          image: pdfUrl || imgUrl,
          label,
          labelTime: timestape,
          location,
          other: JSON.stringify(other),
        })
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              // console.log("同步大数据成功");
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {
            _this.$llsMessage({ type: 'error', text: response.message });
          },
        );
    },
    // 大数据-删除OCR标签信息
    delToDb(label) {
      const _this = this;
      const timestape = new Date().getTime();
      const { origin } = document.location;
      const imgUrl = `${origin}/${_this.imgUrl}`;
      _this.$http
        .post(_this.urlDbDelOcr, {
          docId: _this.labelList[label]
            ? _this.labelList[label].fileId
            : this.fileId,
          // "image": this.curImgBase64,
          image: _this.labelList[label]
            ? _this.labelList[label].url
            : imgUrl,
          label,
          labelTime: timestape,
        })
        .then(
          (data) => {
            const { code } = data.body;
            if (code == 200) {
              // console.log("同步大数据成功");
              _this.labelList[label] = {};
            } else {
              _this.$llsMessage({ type: 'error', text: data.body.message });
            }
          },
          (response) => {
            _this.$llsMessage({ type: 'error', text: response.message });
          },
        );
    },
  },
  mounted() {
    // 条件校验
    this.checkEnvironmental();
    // 初始化
    this.initOcr();
    if (this.ocrType == 'image') {
      // 初始化图片加载canvas
      this.initImgCanvas();
    } else {
      // 初始化pdf加载canvas
      this.initPdfCanvas();
    }
  },
  destroyed() {
    $(document).unbind('keyup');
  },
};
</script>
