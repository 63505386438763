
/* eslint-disable */
// import { LoginInfoStoreModule } from '@/store/modules/user_info';
import { EMPTY_FUNCTION } from '@/common/utils';
import resetPwdDialog from './dialog/reset_pwd_dialog.vue';
import quickFourth from '../../../assets/images/logo2.png';

export default {
  name: 'topHeader',
  components: {
    resetPwdDialog,
  },
  data() {
    return {
      qrCodeDialog: false,
      isShowResetPwdDialog: false,
      // userInfo: LoginInfoStoreModule.userInfo,
      // roleList: LoginInfoStoreModule.roleList,
      bglogoArr: [],
      activePath: '',
      objUser: JSON.parse(sessionStorage.getItem("objUser")),
      name: '',
      navs: [],
      tabMenusItem: [],
      allData: [],
      isCollapse: false,
      companyType: sessionStorage.getItem('companyType'),
      allHeadMenus: [],
      isShowHeadMenu: true,
      isShowHeadNOtice: true,
      comapnyNameCur: '',
      imgTopPath: '',
      imgPathData: {},
      quickFourth: quickFourth,
      showNoticeTip: false, //控制站内信显示隐藏
      sysNoticeTotal: 0, //站内信条数
      msgContent: "", //站内信搜索内容
      sysNoticeList: [], //站内信列表
      menuCode: '01',
      currentMenuPath: '',
      isShowAllClickMenu: false,
      chooseCurrentMenu: {},
      navsOrigData: [],
      allHeaderMenu: [],
      layerLoading: false,
      whichUserData: JSON.parse(sessionStorage.getItem('chooseWhichCompanyData')),
      auditStatus: '',
    };
  },
  computed: {
    isNeedSign() {
      return this.whichUserData.isNeedSign
    },
    // ...mapState(['userInfo', 'roleList']),
    // 是否有营销二维码
    hasQrCode() {
      let flag = false;
      this.roleList.forEach((i: object) => {
        const marketing = JSON.parse(i.marketing || '{}').dictParam;
        if (marketing === 'MARKETING') {
          flag = true;
        }
      });
      return flag;
    },
  },
  watch: {
      comapnyNameCur: {
          handler(val) {
            if(val) {
              console.log('监控系统化',val)
              this.querySysNotice()
            }
          },
          immediate: true
      }
  },
  created() {
    let chooseWhichUseData = JSON.parse(sessionStorage.getItem("chooseWhichCompanyData")) || {};
    if (chooseWhichUseData.userType == 'com') {
      this.comapnyNameCur = chooseWhichUseData.companyName || '';
    }
    if (chooseWhichUseData.userType == 'person') {
      this.comapnyNameCur = chooseWhichUseData.companyName || '';
    }
    this.whichUserData = JSON.parse(sessionStorage.getItem('chooseWhichCompanyData'));
    // this.comapnyNameCur = this.comapnyNameCur ? this.comapnyNameCur : (sessionStorage.getItem("companyWhichName") || '');
    this.getImgDetail();
    this.currentMenuPath = this.$route.fullPath;
    this.whiteHeadMBox();
    document.addEventListener("click",() =>{
      if (this.showNoticeTip == true) {
        this.showNoticeTip = false;
      }
    });
    this.getMenu()
  },
  async mounted() {
    // this.initLoginData();
    let chooseWhichUseData = JSON.parse(sessionStorage.getItem("chooseWhichCompanyData")) || {};
    if ((chooseWhichUseData.auditStatus == 'PASS' && chooseWhichUseData.indexPageShow == 'PASSED')) {
      // 已注册已审核完成用户状态
      this.auditStatus = 'PASSED';
      // this.getInitModel();
      // this.getMenu();
    } else {
      this.auditStatus = 'UNPASSED';
    }
    if (chooseWhichUseData.roleValue == 'PLAN_MANAGER' || chooseWhichUseData.roleValue == 'EXTERNAL_AGENCY') {
      this.menuCode = '01';
      // this.getMenu();
    }
    // this.getDictionary();
    this.$EventBus.$on("getMenu", () => {
      this.getMenu();
    })
    this.$EventBus.$on("selectChangeRoles", async() => {
      console.log('调用执行$eventBus')
      this.whichUserData = JSON.parse(sessionStorage.getItem('chooseWhichCompanyData'));
      // await this.querySysNotice();
      let chooseWhichUseData = JSON.parse(sessionStorage.getItem("chooseWhichCompanyData")) || {};
      if (chooseWhichUseData.auditStatus == 'PASS' && chooseWhichUseData.indexPageShow == 'PASSED') {
        // 已注册已审核完成用户状态
        this.auditStatus = 'PASSED';
        // this.getInitModel();
        // this.getMenu();
      } else {
        this.auditStatus = 'UNPASSED';
      }
      if (chooseWhichUseData.userType == 'com') {
        this.comapnyNameCur = chooseWhichUseData.companyName || '';
      }
      if (chooseWhichUseData.userType == 'person') {
        this.comapnyNameCur = chooseWhichUseData.companyName || '';
      }
      // this.$EventBus.$off('selectChangeRoles')
    });
    this.$EventBus.$on("showHeadMenus", () => {
      // this.getInitModel();
      // this.getMenu();
      this.isShowHeadMenu = true;
      this.isShowHeadNOtice = true;
      this.$forceUpdate();
    });
    this.$EventBus.$on("noShowHeadMenus", () => {
      this.isShowHeadMenu = false;
      this.isShowHeadNOtice = false;
      this.$forceUpdate();
    });
    this.$EventBus.$on("getActivePath", (data: any) => {
      if (this.menuCode == '01') {
        this.activePath = data;
        sessionStorage.setItem('curActivePath', data);
        // this.$forceUpdate();
      }
    });
    // this.$EventBus.$on("indexPageShow", (data) => {
    //   this.pageShow = data;
    //   console.log("this.pageShow: ", this.pageShow);
    // });
  },
  methods: {
    download(){
      if(this.companyType === 'CORE' || this.companyType === 'SUBCORE'){
        window.open( '/spyPc-web/files/project/天健保理系统平台操作指引-核心企业端.pdf')
      }
      if(this.companyType === 'SUPPLIER'){
        window.open('/spyPc-web/files/project/天健保理系统平台操作指引-供应商端.pdf')
      }
    },
    whiteHeadMBox() {
      this.allHeaderMenu.forEach((firstLevelItem) => {
        if (firstLevelItem.menuUrl == this.currentMenuPath) {
          this.currentMenuPath = firstLevelItem.name;
          this.$forceUpdate();
        }
        if (firstLevelItem.children.length > 0) {
          firstLevelItem.children.forEach((secondLevelItem) => {
            if (secondLevelItem.menuUrl == this.currentMenuPath) {
              this.currentMenuPath = firstLevelItem.name;
              this.$forceUpdate();
            }
          })
        }
      })
      this.$forceUpdate();
    },
    // 选择一级菜单
    chooseHeadMenu(FItem: any) {
      // 有二级+三级
      if (FItem.children && FItem.children.length > 0) {
        this.isShowAllClickMenu = true;
        this.chooseCurrentMenu = FItem;
        this.navsOrigData = FItem.children;
        this.currentMenuPath = FItem.name;
        this.$forceUpdate();
        // sessionStorage.setItem('navs', JSON.stringify(this.navs));
        // 只有二级
      } else {
        if (FItem.url == '/' || !FItem.url) {
          this.$message({ type:"error", message: "该菜单暂无路径" });
          return;
        }
        this.isShowAllClickMenu = false;
        this.$router.push({
          path: FItem.url,
        });
        this.currentMenuPath = FItem.name;
        this.$forceUpdate();
        // sessionStorage.setItem('navs', JSON.stringify(this.navs));
      }
    },
    // 点击二级下拉菜单
    enterPageUrl(ele: any) {
      // 渲染左侧菜单
      this.isShowAllClickMenu = false;
      // // 二级菜单 (有三级菜单)
      // if (ele.url == '/' || !ele.url) {
      //   this.navs.forEach((item) => {
      //     // 当二级下面有三级菜单
      //     if (item.code === ele.code) {
      //       // 默认展开三级菜单
      //       this.activePath = item.children[0].url
      //     }
      //   })
      //   // 默认跳转当前三级菜单
      //   this.$router.push({ path: this.activePath });
      // // 二级菜单 (无三级菜单)
      // } else {
      //   this.$router.push({ path: ele.url });
      //   this.activeLeftMenu(ele);
      // }
      this.$router.push({ path: ele.url });
      // this.currentMenuPath = ele.url;
      // console.log(this.currentMenuPath, '+1111111111');
      // this.whiteHeadMBox();
      console.log('pointA')
      this.$EventBus.$emit('getSecondChildNavs', JSON.stringify(this.navsOrigData));
      sessionStorage.setItem('curleftMenus', JSON.stringify(this.navsOrigData));
    },
    // 关闭下拉平铺菜单
    closeBotMenu() {
      this.isShowAllClickMenu = false;
    },
    shouldshowNoticeTips() {
      this.showNoticeTip = true;
    },
    getInitModel() {
      this.companyType = sessionStorage.getItem('companyType');
      let submitComapnyType = '';
      if (this.companyType === 'CORE' || this.companyType === 'SUBCORE') {
          submitComapnyType = 'CE';
      } else if (this.companyType === 'SUPPLIER') {
          submitComapnyType = 'SPY';
      } else if (this.companyType === 'FINANCE') {
          submitComapnyType = 'CPT';
      } else if (this.companyType === 'GUARANTOR') {
          submitComapnyType = 'PERSON';
      } else if (this.companyType === 'EXTERNAL_AGENCY') {
          submitComapnyType = 'EXTER';
      } else if (this.companyType === 'PLAN_MANAGER') {
          submitComapnyType = 'PLAN';
      } else {
          submitComapnyType = this.companyType;
      }
      if (!submitComapnyType) {
        return;
      }
      this.$http.post(`/sys/mode/getMenuModeByClass?businessType=${submitComapnyType}`).then((res) => {
        if (res.code == '200') {
          this.menuCode = res.data.menuCode;
        }
      })
    },
    getImgDetail(){
      this.$http
        .post(
          `/projectElement/detail`)
        .then(res =>{
          let myData = this.filterDic(res.data)|| {};
          this.imgPathData=myData
          let thisWindow=window.location.href.split('#')[0]
          let newthisWindow=thisWindow.replace(/zztjbl-sp/g, 'zztjbl-agw');
           if (thisWindow.indexOf('zhl.zzgxct') > -1) {
              newthisWindow = thisWindow.replace(/zhl.zzgxct/g, 'zhltj.zzgxct')
          }
          let imgAllPaths=(newthisWindow+this.imgPathData.spyTopBarPaths).replace(/\/media-web/g, "media-web");
          //imgAllPaths=imgAllPaths.replace(/8850/g, "8002"); //本地测试
          this.imgTopPath=imgAllPaths
        })
    },
    hiddenResetPwdDialog(value: boolean) {
      this.isShowResetPwdDialog = value;
    },
    handleCommand(command: string) {
      switch (command) {
        case 'logout':
          this.$EventBus.$off();
          this.logout();
          break;
        case 'companyInfo':
          this.$router.push('/basicInfo/index');
          break;
        case 'changeRole':
          // let companyList = JSON.parse(sessionStorage.getItem('objUser')).companyList || [];
          // let lastCompanyList = [];
          // companyList.forEach((item: any) => {
          //   item.singleTypeList.map((itemTwo: any) => {
          //     lastCompanyList.push(
          //       {
          //         roleName: JSON.parse(itemTwo).displayName,
          //         roleValue: JSON.parse(itemTwo).dictParam,
          //         custBuildMode: JSON.parse(item.custBuildMode).dictParam,
          //         companyName: item.companyName,
          //         userType: 'com',
          //         id: item.id,
          //       }
          //     )
          //   })
          // });
          // if (lastCompanyList.length > 1) {
          //   this.$router.push("/selectCompany");
          // } else {
          //   this.$message({
          //       type: 'warning',
          //       message: '当前用户不可切换公司及角色',
          //   });
          // }
          // this.$EventBus.$off();
          let allCompanyList = JSON.parse(sessionStorage.getItem('lastCompanyList')) || [];
          let lastCompanyList = allCompanyList;
          if (lastCompanyList.length > 1) {
            this.$router.push("/selectCompany");
          } else {
            this.$message({
                type: 'warning',
                message: '当前用户不可切换公司及角色',
            });
          }
          break;
        default:
          EMPTY_FUNCTION();
      }
    },
    // 退出登录
    logout() {
      this.$http.post('/userLogin/logout').then(() => {
        sessionStorage.removeItem('navs');
        sessionStorage.removeItem('tab_menus_item');
        sessionStorage.removeItem('chooseWhichCompanyData');
        this.$router.push('/homeWebpage?isLogin=true');
      });
    },
    initLoginData() {
      const { objUser } = this;
      this.name = objUser.userInfo.name;
    },
    clickMenu(menu) {
      /* eslint-disable */console.log(...oo_oo(`2946511327_468_6_468_33_4`,'menu: ', menu));
      if (menu === '/processManage') {
        window.open('/workflow-web', '_blank');
      } else if (this.activePath !== menu) {
        this.$router.push(menu);
        this.$EventBus.$emit('getActivePath', this.activePath);

        const menuDetail = this.allData.find((item) => item.menuUrl === menu);
        if (menuDetail) {
          const index = this.tabMenusItem.findIndex(
            (item) => item.url === menuDetail.menuUrl,
          );
          if (index === -1) {
            this.tabMenusItem.push({
              name: menuDetail.name,
              url: menuDetail.menuUrl,
              close: false,
            });
          }
        }
      }
      const clickMenuParentId = this.allData.find((item) => {if(item.menuUrl === menu){ return item}});
      /* eslint-disable */console.log(...oo_oo(`2946511327_490_6_490_36_4`,clickMenuParentId));
      this.navs.map((navItem) => {
        if (navItem.id == clickMenuParentId.parentId) {
          if (navItem.children) {
            console.log('pointB')
            this.$EventBus.$emit('getSecondChildNavs', JSON.stringify(navItem.children));
            sessionStorage.setItem('curleftMenus', JSON.stringify(navItem.children));
          }
        }
      })
    },
    handleClick(b) {
      this.isCollapse = b;
    },
    getMenu() {
      this.companyType = sessionStorage.getItem('companyType');
      console.log('this.companyType',this.companyType)
      let submitComapnyType = '';
      if (this.companyType === 'CORE' || this.companyType === 'SUBCORE') {
          submitComapnyType = 'CE';
      } else if (this.companyType === 'SUPPLIER') {
          submitComapnyType = 'SPY';
      } else if (this.companyType === 'FINANCE') {
          submitComapnyType = 'CPT';
      } else if (this.companyType === 'GUARANTOR') {
          submitComapnyType = 'PERSON';
      } else if (this.companyType === 'EXTERNAL_AGENCY') {
          submitComapnyType = 'EXTER';
      } else if (this.companyType === 'PLAN_MANAGER') {
          submitComapnyType = 'PLAN';
      } else {
          submitComapnyType = this.companyType;
      }
      if (!submitComapnyType) {
        return;
      }
      this.$http.post(`/menu/menuList?businessType=${submitComapnyType}`).then((data) => {
        const allData = data.data;
        this.allData = allData;
        sessionStorage.setItem('allMenus', JSON.stringify(allData));
        const menuUrlToIdJSON = {};
        // 解析菜单信息，组装成树结构
        const menuObj = [];
        // 菜单对应的icon
        const iconJSON = {
          首页: 'home',
          保理业务: 'baoliyewu',
          电子债权凭证: 'duojiliuzhuan',
          资产证券化: 'zichanzhengquanhua',
          合同管理:'hetongguanli',
          贸背资料管理: 'zichanguanli',
          账户中心: 'zhanghuzhongxin',
          资金方准入维护:'hetongguanli'
        };
        for (let i = 0; i < allData.length; i += 1) {
          allData[i].children = [];
          allData[i].detail = false;
          if (!iconJSON[allData[i].name]) {
            allData[i].icon = '';
          } else {
            allData[i].icon =`${iconJSON[allData[i].name]}`;
            // allData[i].icon = 'head-icon-' + `${iconJSON[allData[i].name]}`;
          }
        }
        for (let i = 0; i < allData.length; i += 1) {
          const sParentMenu = allData[i].parentId || '';
          /* eslint no-bitwise: ["error", { "allow": ["~"] }] */
          if (~~sParentMenu) {
            for (let m = 0; m < allData.length; m += 1) {
              if (allData[m].id === sParentMenu) {
                allData[m].children.push(allData[i]);
              }
            }
          } else {
            allData[i].mainTitle = allData[i].name;
            menuObj.push(allData[i]);
          }
        }
        this.navs = menuObj;
        this.allHeadMenus = menuObj;
        this.allHeaderMenu = menuObj;
        console.log('pointC')
        this.$EventBus.$emit('getSecondChildNavs', JSON.stringify(this.navs));
        sessionStorage.setItem('curleftMenus', JSON.stringify(this.navs));
        sessionStorage.setItem('navs', JSON.stringify(menuObj));
        sessionStorage.setItem('allHeadMenusData', JSON.stringify(menuObj));

        if (this.companyType === 'EXTERNAL_AGENCY') {
          // this.$EventBus.$emit('quickyEnterDoorPath', '/absProductManage/index');
          this.$EventBus.$emit('getActivePath', '/absProductManage/index');
          this.$EventBus.$emit('quickyEnterDoorPathWhich', '产品管理');
        } else if (this.companyType === 'PLAN_MANAGER') {
          // this.$EventBus.$emit('quickyEnterDoorPath', '/absProductManage/index');
          this.$EventBus.$emit('getActivePath', '/absProductManage/index');
          this.$EventBus.$emit('quickyEnterDoorPathWhich', '产品管理');
        }
        this.$nextTick((_) => {
          this.activePath = sessionStorage.getItem('curActivePath');
          if (this.activePath !== '/home' && this.activePath !== '/Home') {
            this.$EventBus.$emit('changeBigHomeBg');
          }
          // LoginInfoStoreModule.setHighlight(this.$route?.meta?.parent || this.$route.path);
          // this.$store.commit(
          //   'setHighlight',
          //   this.$route?.meta?.parent || this.$route.path,
          // );
        });
      });
    },
     // 查看站内信
     lookNotice(notice) {
      this.showNoticeTip = false;
      // 菜单的list
      let msgType = this.filterDic(notice.msgBusiType);
      let menuList = [];
      this.navs.forEach((item) => {
        menuList.push(item.name);
        if (item.children) {
          item.children.forEach((item) => {
            menuList.push(item.name);
          });
        }
      });
      if (msgType === "TRANSFER_NOTICE" && !menuList.includes("交易管理")) {
        this.$message({
          type: 'error',
          message: "抱歉，您暂无当前页面访问权限，请联系系统管理员！",
        });
        return;
      }
      this.$http
        .post(`/home/notiMsg/consumed?msgIds=${notice.id}`)
        .then((res) => {
          this.querySysNotice();
          // 此处用于判断不同的通知去不同的页面；

          if (msgType === "TRANSFER_NOTICE") {
            this.$router.push({
              path: "/transactionManage",
            });
          } else if (msgType === "NOTICE_MESSAGES") {
            window.open(notice.msgUrl, "_blank");
          }
        });
    },
    // 显示站内信列表
    showNoticeTips() {
      if (!this.showNoticeTip) {
        this.msgContent = "";
        this.querySysNotice();
      }
      this.showNoticeTip = !this.showNoticeTip;
    },
    // 搜索站内信
    querySysNotice() {
      this.layerLoading = true;
      this.$http
        .post(`/home/msgList?content=${this.msgContent}`)
        .then((res) => {
          this.layerLoading = false;
          if (res.code === "200") {
            this.sysNoticeList = res.data.msgList;
            this.sysNoticeTotal = Number(res.data.unConsumedNum || 0);
          }
        }).catch(() => {
          this.layerLoading = false;
        });
    },
  },
};
