
// import { mapState } from 'vuex';
// import { LoginInfoStoreModule } from '@/store/modules/user_info';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'leftSide',
  components: {
    // llsLogin,
  },
})
export default class LeftSide extends Vue {
  private isCollapse = false;

  private activePath = '';

  private navs = [];

  private tabMenusItem = [];

  private allData = [];

  private companyType = sessionStorage.getItem('companyType');

  public created(): void {
    this.navs = JSON.parse(sessionStorage.getItem('curleftMenus'));
    console.log('this.navs初始化', this.navs);
    this.activePath = sessionStorage.getItem('curActivePath');
  }

  public destroyed():void {
    this.$EventBus.$off('getActivePath');
    this.$EventBus.$off('getSecondChildNavs');
  }

  public mounted(): void {
    this.$EventBus.$on('getActivePath', (data: any) => {
      this.activePath = data;
      if (this.activePath === '/contractManage/index?activeName=Effective') {
        this.activePath = '/contractManage/index?activeName=ToBeSigned';
      }
      sessionStorage.setItem('curActivePath', this.activePath);
      // this.$forceUpdate();
    });
    this.$EventBus.$on('getSecondChildNavs', (data: any) => {
      this.navs = JSON.parse(data);
      sessionStorage.setItem('curleftMenus', JSON.stringify(this.navs));
      // this.$forceUpdate();
    });
    this.$EventBus.$on('quickyEnterDoorPath', (res) => {
      const allHeaderMenu = JSON.parse(sessionStorage.getItem('allHeadMenusData'));
      allHeaderMenu.forEach((firstLevelItem) => {
        if (firstLevelItem.menuUrl == res) {
          this.activePath = res;
          // this.$forceUpdate();
        }
        if (firstLevelItem.children.length > 0) {
          firstLevelItem.children.forEach((secondLevelItem) => {
            if (secondLevelItem.menuUrl == res) {
              this.navs = firstLevelItem.children;
              this.activePath = res;
              sessionStorage.setItem('navs', JSON.stringify(this.navs));
              // this.$forceUpdate();
            }
            if (secondLevelItem.children.length > 0) {
              secondLevelItem.children.forEach((threeLevelItem) => {
                if (threeLevelItem.menuUrl == res) {
                  this.navs = firstLevelItem.children;
                  this.activePath = res;
                  sessionStorage.setItem('navs', JSON.stringify(this.navs));
                  // this.$forceUpdate();
                }
              });
            }
          });
        }
      });
      this.navs = JSON.parse(JSON.stringify(this.navs));
      console.log('navs更新2', JSON.stringify(this.navs));
      sessionStorage.setItem('curleftMenus', JSON.stringify(this.navs));
      sessionStorage.setItem('curActivePath', this.activePath);
    });
  }

  handleOpen(key, keyPath) {
    console.log(this, key, keyPath, '---public---');
  }

  public clickMenu(menu) {
    console.log('menu: ', menu);
    if (menu === '/processManage') {
      window.open('/workflow-web', '_blank');
    } else if (this.activePath !== menu) {
      this.activePath = menu;
      this.$router.push(menu);
      this.$EventBus.$emit('getActivePath', this.activePath);
      const menuDetail = this.allData.find((item) => item.menuUrl === menu);
      if (menuDetail) {
        const index = this.tabMenusItem.findIndex(
          (item) => item.url === menuDetail.menuUrl,
        );
        if (index === -1) {
          this.tabMenusItem.push({
            name: menuDetail.name,
            url: menuDetail.menuUrl,
            close: false,
          });
        }
      }
    }
  }

  public handleClick(b) {
    this.isCollapse = b;
  }

  // 获取菜单权限
  public getMenuAuth() {
    return this.$http.post('/spyPc-web/resourceNew/listCurUser');
  }
}
