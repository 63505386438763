import Vue from 'vue';
import AgreementVue from './AgreementVue.vue';
import merge from './utils/merge';
/* eslint-disable */
const AgreementConstructor = Vue.extend(AgreementVue);

let instance:any;

const initInstance = () => {
  instance = new AgreementConstructor({
    el: document.createElement('div'),
  });
  document.body.appendChild(instance.$el);
};

const showAgreement = function (option:any) {
  initInstance();
  merge(instance, option);
  instance.show = true;
};

const Agreement = function (option = {}) {
  if (Vue.prototype.$isServer) return;
  showAgreement(option);
  return instance;
};

export default Agreement;
