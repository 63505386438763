<!--
    名称：温馨提示信息
    描述：非弹框类的提示信息
 -->

<style scoped>
.llsprompt-main {
  background-color: #E8F2FF !important;
  border: 1px solid  #9AC0FE !important;
  border-radius: 4px;
  color: #333;
  font-size: 12px;
  margin: 10px;
  white-space: normal;
  clear: both;
  max-width: max-content;
  transition: all 0.5s;
}
.llsprompt-main-suc {
  background-color: #f3fcf6;
  border: 1px solid #34c46b;
  color: #333;
}
.llsprompt-main-warn {
  background-color: #fff9e7;
  border: 1px solid #fabc0c;
  color: #333;
}
.llsprompt-main-err {
  background-color: #fff1f1;
  border: 1px solid #ff4548;
  color: #333;
}
.llsprompt-main-top {
  display: flex;
  white-space: normal;
  clear: both;
  transition: all 0.5s;
}
.llsprompt-icon {
  width: 30px;
  height: 37px;
}

.llsprompt-icon-info {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAADDElEQVRYCb1XMWgVQRCdmftJCEFJI2pICiGCGsHKws60FoqFlRaCsRK7FNqEEAsV0lnYRLDQKoXEwk60s7DUKISAReCr2IRIkPz/b8d55+1lk/zcvz3Rhfu7+3dm3rudndk5popt4or2bx3onBeWS8p8iklHTBUPWlOJm6z6ScS9TNYbb5YXufVnqfyXy5eJjk3p4T51MyR0jZQO9pLP1pk2yNGzNsvclwX+XqazL4Hx2zogv9xdU562Z6jMSMnapq3Nu0G5v/qIt7rJdSWAt+6n9IVt67luSrH/mbvetSi53G039hA4eUNPp+xeGchYLFAP+bVE5cLnJ/wxlNtBIPM3ufcR4Ku5sfHQaMl4rU1yNtwJ8cLwObbd5lXffHZlITmOx3RmvZ0e/RgwgOXlCgI4cBE+d62OPPRG8rHz87IeGPnhzsQyAth6m+G0V22SJDTqhfNx8TL+/5J+OsekBoSyOOe4UEvYLZ642b4DfafuAfqINpRhEt1iZLj2sPtROclEoJSKWrLqW5dDgvT638HBzLJqOtyZFOT2Uqb/cNE5uSi4WOph8NLKqCR4lOhxHRvAbuS3Wg193aJZyUKPp9JODQMEbISOv1KjbNhbtwsFploEgB0TuwUeBqZY3PeWz9MdixETEGhGyG+L8jYBdbUJNAWVzLbV6qPQBVzTBcAWlFHVYQNJF+wA19uBrISzNLoUmK0+DFzAWu8Qon6UgZ+NtxYPG9WRc8mAgLkj/hAaJopXyapXKyBjCew6A/EEDBPYWRiiejUCKCArN0tBRRhqvAs2+1K5B7CMQF4izVdGh2Dgghp5YH75KX+DmYwABiidUb1iXKWFLnARUQAMYHmMvyhK+bWSfoAhZj5DqpPeaEm/pyjdQQCK/7ssL1zgWaNuR+kc4w6vu18PW7C5+5sA8nsI4E8cynQwmbSDNmfTqOiAftA2YQO2wm+BYN2We7SJ63qk1XAzlu+vVi7dLMlYaD7v78icP+37wfQk4BVRvKKGQxnFrBNOecRIHcW6gX21S6Wpysuxn+e/AQPcMXUmAFGXAAAAAElFTkSuQmCC) no-repeat 50% !important;
  background-size: 16px 16px !important;
}
.llsprompt-icon-suc {
  background: url(assets/suc.svg) no-repeat center center;
}
.llsprompt-icon-warn {
  background: url(assets/warn.svg) no-repeat center center;
}
.llsprompt-icon-err {
  background: url(assets/err.svg) no-repeat center center;
}
.llsprompt-msg {
  padding: 10px 10px 0px 0;
  width: calc(100% - 30px);
}
.llsprompt-msg-s {
  padding: 13px 0px 0px 0;
  width: calc(100% - 60px);
}
.llsprompt-item {
  margin-bottom: 4px;
}
.llsprompt-close {
  background: url(assets/close.svg) no-repeat center center;
  width: 30px;
  height: 30px;
  margin-top: 4px;
  cursor: pointer;
  box-sizing: border-box;
  background-size: 30%;
}
.llsprompt-close:hover {
  background: url(assets/close_act.svg) no-repeat center center;
  background-size: 30%;
}

.llsprompt-main-body {
  padding: 0px 10px 10px 30px;
  max-height: 90px;
  overflow: auto;
  scrollbar-arrow-color: #fff; /**/ /*三角箭头的颜色*/
  scrollbar-face-color: #ededed; /**/ /*立体滚动条的颜色*/
  scrollbar-3dlight-color: red; /**/ /*立体滚动条亮边的颜色*/
  scrollbar-highlight-color: #fff; /**/ /*滚动条空白部分的颜色*/
  scrollbar-shadow-color: #ededed; /**/ /*立体滚动条阴影的颜色*/
  scrollbar-darkshadow-color: #fff; /**/ /*立体滚动条强阴影的颜色*/
  scrollbar-track-color: #fff; /**/ /*立体滚动条背景颜色*/
  scrollbar-base-color: red; /**/ /*滚动条的基本颜色*/
}
/* 滚动条美化 */
::-webkit-scrollbar-track-piece {
  /*滚动条凹槽的颜色，还可以设置边框属性*/
  background-color: #ffffff;
}
::-webkit-scrollbar {
  /* 滚动条宽度 */
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  /*外层轨道*/
  /*border-left: 1px solid #d9d9d9;*/
  /*width: 20px;*/
}
::-webkit-scrollbar-thumb {
  /*滚动条的设置*/
  background-color: #bbbbbb;
  /*background-clip:padding-box;*/
  border-radius: 5px;
  min-height: 28px;
}
::-webkit-scrollbar-button {
}
::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}
</style>

<template>
<!-- eslint-disable  -->
  <div
    class="llsprompt-main"
    :class="{
      'llsprompt-main-suc': type == 'suc',
      'llsprompt-main-warn': type == 'warn',
      'llsprompt-main-err': type == 'err',
    }"
    v-if="isShow"
  >
    <div class="llsprompt-main-top">
      <div
        class="llsprompt-icon"
        :class="[
          {
            'llsprompt-icon-suc': type == 'suc',
            'llsprompt-icon-warn': type == 'warn',
            'llsprompt-icon-err': type == 'err',
            'llsprompt-icon-info': type == 'info' || (!type && !icon),
          },
          icon + '-00a',
        ]"
      ></div>
      <!-- <div class="llsprompt-msg">
                <div class="llsprompt-item" v-for="item in dataObj" v-dompurify-html="item"></div>
            </div> -->
      <div class="llsprompt-msg" :class="{ 'llsprompt-msg-s': isClose }">
        <div
          class="llsprompt-item"
          v-dompurify-html="dataObj[0]"
          @click="clickText(dataObj[0])"
        ></div>
      </div>
      <div class="llsprompt-close" @click="closePrompt" v-if="isClose"></div>
    </div>

    <div class="llsprompt-main-body" v-if="dataObj.length > 1">
      <div
        class="llsprompt-item"
        v-for="(item, index) in dataObj"
        v-dompurify-html="item"
        v-if="index > 0"
        @click="clickText(item)"
      ></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
Vue.use(VueDOMPurifyHTML);
export default {
  name: 'llsPrompt',
  props: {
    dataObj: '',
    type: '', //类型，可选值有【info|suc|warn|err】，分别代表【普通提示|成功提示|警告提示|错误提示】，默认为普通
    icon: '', //非必传，图标
    close: true, //是否需要关闭按钮
  },
  data() {
    return {
      isShow: true,
      isClose: true, //是否需要关闭
    };
  },
  methods: {
    //关闭提示框
    closePrompt: function () {
      this.isShow = false;
    },
    //点击文本
    clickText: function (item) {
      var index = item.indexOf('@click');
      if (item != -1) {
        var sp = item.substring(index + 7, index + 8);
        var lastStr = item.substring(index + 7, item.length);
        var lastArr = lastStr.split(sp);
        var funName = lastArr[1];
        if (typeof this.$parent[funName] == 'function') {
          this.$parent[funName]();
        } else if (typeof this.$parent.$parent[funName] == 'function') {
          this.$parent.$parent[funName]();
        }
      }
    },
  },
  mounted() {
    //是否要关闭
    var initClose = this.close;
    if (typeof initClose == 'boolean') {
      this.isClose = initClose;
    } else if (typeof initClose == 'undefined') {
      this.isClose = true;
    } else {
      this.isClose = initClose == 'true' ? true : false;
    }
  },
};
</script>
